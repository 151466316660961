import { Tag } from 'antd';
import { OrderStatus, OrderStatusNames } from '../../../reducers/orders/model';

export const statusOrderClass = (status: string) => {
  switch (status) {
    case OrderStatus.NOT_PAID:
      return <Tag color='red'>{OrderStatusNames[status]}</Tag>;
    case OrderStatus.PAID:
      return <Tag color='green'>{OrderStatusNames[status]}</Tag>;
    case OrderStatus.DONE:
      return <Tag color='green'>{OrderStatusNames[status]}</Tag>;
    case OrderStatus.PAID_IN_ADVANCE:
      return <Tag color='gold'>{OrderStatusNames[status]}</Tag>;
    default:
      return <Tag color='blue'>{OrderStatusNames[status]}</Tag>;
  }
};
