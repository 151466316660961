import React, { FC, useState } from 'react';
import { Button, Divider, Dropdown, Flex, List, Space, Tag, Typography } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { formatPrice } from '../../../utils/formatPrice';
import { CurrencyType } from '../../../reducers/orders/model';
import { PartModel } from '../../../reducers/parts/model';
import { DividerSC, ListItemSC } from './styles';
import { StatusProductTag } from '../../common/productsList/StatusProductTag';
import { useGetSettings } from '../../../services/apiClient/settings/settingsQueries';
import { GeneralSettingModel } from '../../../reducers/settings/model';

const { Text } = Typography;

type PartMobileItemProps = {
  part: PartModel;
  clickOpenPartModal: (openPartModal: boolean) => void;
  setSelectedPart: (selectedPart: PartModel) => void;
  setOpenQuickViewModal: (openQuickViewModal: boolean) => void;
  setOpenPartDeleteModal: (openPartDeleteModal: boolean) => void;
};

export const PartMobileItem: FC<PartMobileItemProps> = props => {
  const { part, clickOpenPartModal, setSelectedPart, setOpenQuickViewModal, setOpenPartDeleteModal } = props;
  const [morePrice, showMorePrice] = useState(false);
  const { data: settingsList } = useGetSettings();

  let euroExchange: GeneralSettingModel | undefined;
  if (settingsList) {
    euroExchange = settingsList.find(item => item.key === 'EUR-TO-PLN');
  }

  const getItemsForActionMenu = (part: PartModel) => [
    {
      label: 'Edytuj',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => {
        clickOpenPartModal(true);
        setSelectedPart(part);
      },
    },
    {
      label: 'Podgląd',
      key: '2',
      icon: <EyeOutlined />,
      onClick: () => {
        setOpenQuickViewModal(true);
        setSelectedPart(part);
      },
    },
    {
      label: 'Usuń',
      key: '4',
      icon: <DeleteOutlined />,
      onClick: () => handleDeletePartModal(part),
    },
  ];

  const handleDeletePartModal = (part: PartModel) => {
    setSelectedPart(part);
    setOpenPartDeleteModal(true);
  };

  return (
    <ListItemSC className='listItem'>
      <List.Item.Meta
        className='listItemMeta'
        avatar={
          part.assets.length > 0 && (
            <img
              style={{ height: '55px' }}
              alt={'test'}
              src={part.assets.length > 0 ? `${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${part.assets[0].uri}` : ''}
            />
          )
        }
        title={
          <Flex justify='space-between'>
            <div>
              {part.internalId}
              {part.name && (
                <>
                  <Divider type='vertical' /> {part.name}
                </>
              )}
              <Divider type='vertical' /> {part.brand?.name}{' '}
            </div>
            <Dropdown menu={{ items: getItemsForActionMenu(part) }}>
              <Button
                type='text'
                aria-label='Pokaż więcej opcji'
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
          </Flex>
        }
        description={
          <Flex
            gap='4px 0'
            wrap
          >
            <Tag color='magenta'>{part.category?.name}</Tag>
            <StatusProductTag status={part.status} />
            <Tag color='purple'>{part.type}</Tag>
            <Tag color='geekblue'>{part.productionYear}</Tag>
          </Flex>
        }
      />
      <DividerSC />
      <Flex
        justify='space-between'
        align='center'
      >
        <Text>
          Cena: {formatPrice(part.price, CurrencyType.PLN)}
          <Text
            className='secondaryText'
            type='secondary'
          >
            ({formatPrice(euroExchange?.value ? part.price / +euroExchange.value : 0, CurrencyType.EUR)})
          </Text>
        </Text>
        <Button
          type='text'
          aria-label='Pokaż więcej cen'
          onClick={() => showMorePrice(!morePrice)}
          icon={morePrice ? <UpOutlined /> : <DownOutlined />}
        ></Button>
      </Flex>
      {morePrice && (
        <Space direction='vertical'>
          <Text>
            Cena hurtowa: <Text strong>{formatPrice(part.priceWholesale, CurrencyType.EUR)}</Text>
            <Text
              className='secondaryText'
              type='secondary'
            >
              (
              {formatPrice(
                part.priceWholesale && euroExchange?.value ? part.priceWholesale * +euroExchange.value : 0,
                CurrencyType.PLN,
              )}
              )
            </Text>
          </Text>
        </Space>
      )}
    </ListItemSC>
  );
};
