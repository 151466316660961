import styled from 'styled-components';
import { Layout } from 'antd';
import { breakpoints } from '../../styles/breakpoints';

const { Sider, Content } = Layout;

export const SiderSC = styled(Sider)`
  position: relative;

  &.siderWrapper {
    background: #33558b;
    .menuButton {
      color: #3d69a6;
      background: #fff;
      position: absolute;
      right: -10px;
      top: 16px;
      z-index: 10;

      &:hover {
        color: #3d69a6;
        background: #fff !important;
      }

      @media screen and (min-width: ${breakpoints.lg}) {
        right: -15px;
      }
    }
  }
`;

export const ContentSC = styled(Content)`
  min-height: 280px;
  position: relative;
`;
