import React, { FC } from 'react';
import { Table, Typography } from 'antd';
import {
  ForkliftEngine,
  ForkliftEngineNames,
  ForkliftModel,
  ForkliftTable,
  ForkliftTableNames,
  ProductStatus,
  ProductStatusNames,
} from '../../../../reducers/forklift/model';
import { BrandModel } from '../../../../reducers/brands/model';
import { CategoryModel } from '../../../../reducers/categories/model';
import { AlignType } from 'rc-table/lib/interface';
import { getStatusProductIcon } from '../../../../utils/statusProductIcon';
import { useFilterAntTable } from '../../../../utils/customHooks/useFilterAntTable';
import { formatPrice } from '../../../../utils/formatPrice';
import { CurrencyType, ProductType } from '../../../../reducers/orders/model';

const { Title } = Typography;

export type OrderForklift = ForkliftModel & {
  productType: ProductType;
};

type OrdersForkliftsListProps = {
  forkliftsList: OrderForklift[];
  groupEditForkliftsStatus: string[];
  onSelection: (newSelectedRowKeys: React.Key[]) => void;
};

export const OrdersForkliftsList: FC<OrdersForkliftsListProps> = props => {
  const { forkliftsList, groupEditForkliftsStatus, onSelection } = props;
  const { getColumnSearchProps, getColumnFilterProps } = useFilterAntTable();

  const columns = [
    {
      title: 'Id',
      dataIndex: 'internalId',
      key: 'internalId',
      ...getColumnSearchProps('internalId', 'Id'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.internalId.localeCompare(b.internalId),
    },
    {
      title: ForkliftTableNames[ForkliftTable.BRAND],
      dataIndex: ForkliftTable.BRAND,
      key: ForkliftTable.BRAND,
      ...getColumnSearchProps(ForkliftTable.BRAND, 'marki'),
      render: (brand?: BrandModel) => <>{brand?.name}</>,
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.brand?.name.localeCompare(b.brand?.name ?? '') ?? 0,
    },
    {
      title: ForkliftTableNames[ForkliftTable.TYPE],
      dataIndex: ForkliftTable.TYPE,
      key: ForkliftTable.TYPE,
      ...getColumnSearchProps(ForkliftTable.TYPE, 'typu'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.type.localeCompare(b.type),
    },
    {
      title: ForkliftTableNames[ForkliftTable.SERIAL_NUMBER],
      dataIndex: ForkliftTable.SERIAL_NUMBER,
      key: ForkliftTable.SERIAL_NUMBER,
      ...getColumnSearchProps(ForkliftTable.SERIAL_NUMBER, 'nr seryjnego'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.serialNumber.localeCompare(b.serialNumber),
    },
    {
      title: ForkliftTableNames[ForkliftTable.CATEGORY],
      dataIndex: ForkliftTable.CATEGORY,
      key: ForkliftTable.CATEGORY,
      ...getColumnSearchProps('category', 'kategorii'),
      render: (category?: CategoryModel) => <>{category?.name}</>,
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.category?.name.localeCompare(b.category?.name ?? '') ?? 0,
    },
    {
      title: ForkliftTableNames[ForkliftTable.LIFTING_CAPACITY],
      dataIndex: ForkliftTable.LIFTING_CAPACITY,
      key: ForkliftTable.LIFTING_CAPACITY,
      ...getColumnSearchProps(ForkliftTable.LIFTING_CAPACITY, 'udźwigu'),
      render: (liftingCapacity: number) => <>{liftingCapacity} kg</>,
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.liftingCapacity - b.liftingCapacity,
    },
    {
      title: ForkliftTableNames[ForkliftTable.STATUS],
      dataIndex: ForkliftTable.STATUS,
      key: ForkliftTable.STATUS,
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        ForkliftTable.STATUS,
        Object.values(ProductStatus).map(status => ({
          value: status,
          text: ProductStatusNames[status],
        })),
      ),
      render: (status: ProductStatus) => getStatusProductIcon(status),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.status.localeCompare(b.status),
    },
    {
      title: ForkliftTableNames[ForkliftTable.ENGINE],
      dataIndex: ForkliftTable.ENGINE,
      key: ForkliftTable.ENGINE,
      ...getColumnFilterProps(
        ForkliftTable.ENGINE,
        Object.values(ForkliftEngine).map(engine => ({
          value: engine,
          text: ForkliftEngineNames[engine],
        })),
      ),
      render: (engine: ForkliftEngine) => ForkliftEngineNames[engine],
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.engine.localeCompare(b.engine),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRODUCTION_YEAR],
      dataIndex: ForkliftTable.PRODUCTION_YEAR,
      key: ForkliftTable.PRODUCTION_YEAR,
      ...getColumnSearchProps(ForkliftTable.PRODUCTION_YEAR, 'rok produkcji'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.productionYear - b.productionYear,
    },
    {
      title: ForkliftTableNames[ForkliftTable.HOURS],
      dataIndex: ForkliftTable.HOURS,
      key: ForkliftTable.HOURS,
      ...getColumnSearchProps(ForkliftTable.HOURS, 'liczby godzin'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.hours - b.hours,
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_MIN],
      dataIndex: ForkliftTable.PRICE_MIN,
      key: ForkliftTable.PRICE_MIN,
      render: (priceSuggested: number) => formatPrice(priceSuggested, CurrencyType.PLN),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.priceMin - b.priceMin,
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_WHOLESALE_MIN],
      dataIndex: ForkliftTable.PRICE_WHOLESALE_MIN,
      key: ForkliftTable.PRICE_WHOLESALE_MIN,
      render: (priceWholesaleMin: number) => formatPrice(priceWholesaleMin, CurrencyType.EUR),
      sorter: (a: ForkliftModel, b: ForkliftModel) => (a.priceWholesaleMin ?? 0) - (b.priceWholesaleMin ?? 0),
    },
  ];

  if (forkliftsList.length > 0) {
    return (
      <>
        <Title level={5}>Wózki</Title>
        <Table
          columns={columns}
          dataSource={forkliftsList}
          rowKey={record => record._id || ''}
          size='small'
          style={{ paddingTop: '16px' }}
          rowSelection={{
            selectedRowKeys: groupEditForkliftsStatus,
            onChange: onSelection,
          }}
          pagination={false}
        />
      </>
    );
  }

  return null;
};
