import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { PartBrandModel } from '../../../reducers/partsBrands/model';

class APIClientPartBrands extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/part-brands`;
  }

  public getPartBrands = async (): Promise<ResponseModel<PartBrandModel[]>> => {
    return this.authFetch<ResponseModel<PartBrandModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addPartBrand = async (
    data: Omit<PartBrandModel, '_id' | 'createdAt' | 'updatedAt'>,
  ): Promise<ResponseModel<PartBrandModel>> => {
    return this.authFetch<ResponseModel<PartBrandModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updatePartBrand = async (data: Partial<PartBrandModel>): Promise<ResponseModel<PartBrandModel>> => {
    return this.authFetch<ResponseModel<PartBrandModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deletePartBrand = async (brandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${brandId}`, { method: 'DELETE' });
  };
}

export const apiClientPartBrands = new APIClientPartBrands();
