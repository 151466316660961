import { useQuery } from 'react-query';
import { apiClientEquipments } from './index';

const queryKeys = {
  getEquipments: `equipments`,
  getAvailableEquipments: `equipments/available`,
  getEquipment: (id: string) => `equipments/${id}`,
};

export const useGetEquipments = () => {
  return useQuery(
    queryKeys.getEquipments,
    async () => {
      const response = await apiClientEquipments.getEquipments();
      return response.data;
    },
    { initialData: [] },
  );
};

export const useGetAvailableEquipments = () => {
  return useQuery(
    queryKeys.getAvailableEquipments,
    async () => {
      const response = await apiClientEquipments.getAvailableEquipments();
      return response.data;
    },
    { initialData: [] },
  );
};

export const useGetEquipment = (id: string) => {
  return useQuery(queryKeys.getEquipment(id), async () => {
    const response = await apiClientEquipments.getEquipment(id);
    return response.data;
  });
};
