import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { PartBrandModel } from '../../reducers/partsBrands/model';
import {
  addPartBrandRequest,
  deletePartBrandRequest,
  getPartBrandsRequest,
  updatePartBrandRequest,
} from '../../reducers/partsBrands/actions';
import { RootReducerState } from '../../reducers';
import { PartBrandForm, PartBrandFormValues } from '../../forms/partBrand';

type PartBrandsListProps = {
  openPartBrandModal: boolean;
  clickOpenPartBrandModal: (openBrandModal: boolean) => void;
};

export const PartsBrandsList: FC<PartBrandsListProps> = props => {
  const { openPartBrandModal, clickOpenPartBrandModal } = props;
  const partsBrandsList = useSelector<RootReducerState, PartBrandModel[]>(state => state.partsBrands.partsBrandsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.partsBrands.isFetching);
  const [selectedPartBrand, setSelectedPartBrand] = useState<PartBrandModel | undefined>(undefined);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();

  useEffect(() => {
    dispatch(getPartBrandsRequest());
  }, [dispatch]);

  const handleEditPartBrandModal = (partId: string) => {
    const brand = partsBrandsList.find(item => item._id === partId);
    setSelectedPartBrand(brand);
    clickOpenPartBrandModal(true);
  };

  const handleClosePartBrandModal = () => {
    setSelectedPartBrand(undefined);
    clickOpenPartBrandModal(false);
  };

  const handleDeletePartBrandModal = (brandId: string) => {
    dispatch(deletePartBrandRequest(brandId));
  };

  const handleSubmit = (values: PartBrandFormValues) => {
    if (selectedPartBrand) {
      dispatch(
        updatePartBrandRequest(
          {
            ...selectedPartBrand,
            ...values,
          },
          () => {
            setSelectedPartBrand(undefined);
            clickOpenPartBrandModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addPartBrandRequest(values, () => {
          setSelectedPartBrand(undefined);
          clickOpenPartBrandModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<PartBrandModel> = [
    {
      title: 'Marka',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ...getColumnSearchProps('name', 'marki'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string, brand) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj markę'
              onClick={() => handleEditPartBrandModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie marki'
              description='Czy na pewno chcesz usunąć markę?'
              onConfirm={() => handleDeletePartBrandModal(brand._id)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń markę'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={partsBrandsList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane marki'
        titleAdd='Dodaj markę'
        openModal={openPartBrandModal}
        handleCloseModal={handleClosePartBrandModal}
        selectedItem={selectedPartBrand}
      >
        <PartBrandForm
          partBrand={selectedPartBrand}
          handleSubmit={handleSubmit}
          handleCancelForm={handleClosePartBrandModal}
        />
      </DrawerAddEdit>
    </>
  );
};
