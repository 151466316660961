import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Checkbox, Col, DatePicker, Form, Image, Input, Radio, Row, Select, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pl_PL';

import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';
import {
  AvailableProducts,
  CurrencyType,
  CurrencyTypeNames,
  OrderModel,
  OrderStatus,
  OrderStatusNames,
  ProductInOrder,
  ProductType,
  TransportType,
  TransportTypeNames,
} from '../../reducers/orders/model';
import { ClientModel, ClientType, ClientTypeNames } from '../../reducers/clients/model';
import { addClientRequest, getClientsRequest } from '../../reducers/clients/actions';
import { getOrderPDFRequest } from '../../reducers/orders/actions';

import { ClientForm, ClientFormValues } from '../client';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { formatPrice } from '../../utils/formatPrice';
import { AdvancePaymentsList } from '../../component/advancePayment/AdvancePaymentsList';
import { useGetAvailableForklifts } from '../../services/apiClient/forklifts/forkliftQueries';
import { useGetOrder } from '../../services/apiClient/orders/orderQueries';
import { useGetAvailableEquipments } from '../../services/apiClient/equipments/equipmentQueries';
import { useViewport } from '../../utils/customHooks/useViewport';
import { useGetAvailableParts } from '../../services/apiClient/parts/partQueries';

import './style.scss';

const { Item } = Form;
const { TextArea } = Input;

export type OrderFormValues = {
  orderNr: string;
  clientId: string;
  status: OrderStatus;
  productsInOrder: ProductInOrder[];
  paidAt: string;
  notes?: string;
  clientType: ClientType;
  transport: TransportType;
  transportAddress?: string;
  currency: CurrencyType;
  invoice?: string;
};

export type OrderFormProps = {
  order?: Partial<OrderModel>;
  handleSubmit: (values: OrderFormValues) => void;
  handleCancelForm: () => void;
};

export const OrderForm: FC<OrderFormProps> = props => {
  const { order, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.orders.isFetching);
  const clientsList = useSelector<RootReducerState, ClientModel[]>(state => state.clients.clientsList);
  const [openClientModal, setOpenClientModal] = useState(false);
  const { data: availableForkliftsList = [] } = useGetAvailableForklifts();
  const { data: availableEquipmentsList = [] } = useGetAvailableEquipments();
  const { data: availablePartsList = [] } = useGetAvailableParts();
  const { data: editedOrder, isLoading: isLoadingOrder } = useGetOrder(order?._id);

  const dispatch = useDispatch();
  const formik = useFormik(initFormik(props));
  const { isMobile } = useViewport();

  useEffect(() => {
    dispatch(getClientsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!order?._id) {
      const currency = formik.values.currency as CurrencyType | undefined;
      if (currency) {
        const productsInOrder = formik.values.productsInOrder.map(product => {
          if (product.type === ProductType.EQUIPMENT) {
            const equipment = availableEquipmentsList.find(item => item._id === product.productId);
            if (equipment) {
              return {
                ...product,
                price: currency === CurrencyType.PLN ? equipment.price : equipment.priceWholesale,
              };
            }
          } else if (product.type === ProductType.PART) {
            const part = availablePartsList.find(item => item._id === product.productId);
            if (part) {
              return {
                ...product,
                price: currency === CurrencyType.PLN ? part.price : part.priceWholesale,
              };
            }
          } else {
            const forklift = availableForkliftsList.find(item => item._id === product.productId);
            if (forklift) {
              return {
                ...product,
                price: currency === CurrencyType.PLN ? forklift.priceSuggested : forklift.priceWholesaleSuggested,
              };
            }
          }

          return product;
        });
        void formik.setFieldValue('productsInOrder', productsInOrder);
      }
    }
    // eslint-disable-next-line
  }, [formik.values.currency, availableForkliftsList, availableEquipmentsList, availablePartsList]);

  const availableProducts = useMemo(() => {
    return [
      ...availableForkliftsList,
      ...availableEquipmentsList,
      ...availablePartsList,
      ...(editedOrder?.products ?? []),
    ] as AvailableProducts[];
  }, [availableEquipmentsList, availableForkliftsList, availablePartsList, editedOrder]);

  const handleSubmitClient = (values: ClientFormValues) => {
    dispatch(
      addClientRequest(values, client => {
        setOpenClientModal(false);
        void formik.setFieldValue('clientId', client?._id);
      }),
    );
  };

  const handleChangeClient = (value: string) => {
    void formik.setFieldValue('clientId', value);
    const client = clientsList.find(client => client._id === value);
    void formik.setFieldValue('clientType', client?.clientType);
    if (client?.clientType === ClientType.DEALER) {
      void formik.setFieldValue('currency', CurrencyType.EUR);
    } else {
      void formik.setFieldValue('currency', CurrencyType.PLN);
    }
  };

  const handleChangeClientType = (value: ClientType) => {
    void formik.setFieldValue('clientType', value);
    if (value === ClientType.DEALER) {
      void formik.setFieldValue('currency', CurrencyType.EUR);
    } else {
      void formik.setFieldValue('currency', CurrencyType.PLN);
    }
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        {order?.orderNr && (
          <Item
            label='Nr zamówienia'
            required={true}
            hasFeedback={true}
            validateStatus={formik.errors.orderNr && formik.touched.orderNr ? 'error' : ''}
            help={formik.errors.orderNr && formik.touched.orderNr ? formik.errors.orderNr : null}
          >
            <Input
              name='orderNr'
              value={formik.values.orderNr}
              placeholder='Nr zamówienia'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Item>
        )}
        <Row
          justify='space-between'
          align='middle'
        >
          <Col
            xs={15}
            sm={18}
            md={20}
            xxl={22}
          >
            <Item
              label='Klient'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.clientId && formik.touched.clientId ? 'error' : ''}
              help={formik.errors.clientId && formik.touched.clientId ? formik.errors.clientId : null}
            >
              <Select
                showSearch
                value={formik.values.clientId}
                onChange={value => handleChangeClient(value)}
                onBlur={() => formik.setFieldTouched('clientId')}
                options={clientsList.map(client => ({
                  label: client.companyName,
                  value: client._id,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
          </Col>
          <Col
            xs={9}
            sm={6}
            md={4}
            xxl={2}
            className='right'
          >
            <Button onClick={() => setOpenClientModal(true)}>Dodaj klienta</Button>
          </Col>
        </Row>
        <Item
          label='Typ klienta'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.clientType && formik.touched.clientType ? 'error' : ''}
          help={formik.errors.clientType && formik.touched.clientType ? formik.errors.clientType : null}
        >
          <Radio.Group
            name='clientType'
            onChange={value => handleChangeClientType(value.target.value as ClientType)}
            onBlur={formik.handleBlur}
            value={formik.values.clientType}
            options={Object.values(ClientType).map(client => ({
              label: ClientTypeNames[client],
              value: client,
            }))}
          />
        </Item>
        <Item
          label='Transport'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.transport && formik.touched.transport ? 'error' : ''}
          help={formik.errors.transport && formik.touched.transport ? formik.errors.transport : null}
        >
          <Radio.Group
            name='transport'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.transport}
            options={Object.values(TransportType).map(transport => ({
              label: TransportTypeNames[transport],
              value: transport,
            }))}
          />
        </Item>
        {formik.values.transport === TransportType.SELLER && (
          <Item
            label='Adres dostawy'
            hasFeedback={true}
            validateStatus={formik.errors.transportAddress && formik.touched.transportAddress ? 'error' : ''}
            help={
              formik.errors.transportAddress && formik.touched.transportAddress ? formik.errors.transportAddress : null
            }
          >
            <TextArea
              value={formik.values.transportAddress}
              name='transportAddress'
              showCount
              maxLength={150}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Adres dostawy'
            />
          </Item>
        )}
        <Item
          label='Status'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.status && formik.touched.status ? 'error' : ''}
          help={formik.errors.status && formik.touched.status ? formik.errors.status : null}
        >
          <Select
            value={formik.values.status}
            style={{ width: '100%' }}
            onChange={value => formik.setFieldValue('status', value)}
            onBlur={() => formik.setFieldTouched('status')}
            options={Object.values(OrderStatus).map(status => ({
              label: OrderStatusNames[status],
              value: status,
            }))}
          />
        </Item>
        {order?._id && (
          <Item label='Zaliczki'>
            <AdvancePaymentsList
              currency={formik.values.currency}
              orderId={order._id}
            />
          </Item>
        )}
        <Item
          label='Nr faktury'
          hasFeedback={true}
          validateStatus={formik.errors.invoice && formik.touched.invoice ? 'error' : ''}
          help={formik.errors.invoice && formik.touched.invoice ? formik.errors.invoice : null}
        >
          <Input
            name='invoice'
            value={formik.values.invoice}
            placeholder='Nr faktury'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Item>
        <Item
          label='Uwagi'
          hasFeedback={true}
          validateStatus={formik.errors.notes && formik.touched.notes ? 'error' : ''}
          help={formik.errors.notes && formik.touched.notes ? formik.errors.notes : null}
        >
          <TextArea
            value={formik.values.notes}
            name='notes'
            showCount
            maxLength={1000}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Uwagi'
          />
        </Item>
        <Item
          label='Waluta'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.currency && formik.touched.currency ? 'error' : ''}
          help={formik.errors.currency && formik.touched.currency ? formik.errors.currency : null}
        >
          <Select
            value={formik.values.currency}
            onChange={value => formik.setFieldValue('currency', value)}
            onBlur={() => formik.setFieldTouched('currency')}
            options={Object.values(CurrencyType).map(currency => ({
              label: CurrencyTypeNames[currency],
              value: currency,
            }))}
          />
        </Item>
        <Item
          label='Data płatności'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.paidAt && formik.touched.paidAt ? 'error' : ''}
          help={formik.errors.paidAt && formik.touched.paidAt ? formik.errors.paidAt : null}
        >
          <DatePicker
            value={formik.values.paidAt ? dayjs(formik.values.paidAt) : null}
            locale={locale}
            placeholder='Wybierz'
            name='paidAt'
            format='DD/MM/YYYY'
            onChange={value => formik.setFieldValue('paidAt', value)}
            onBlur={formik.handleBlur}
          />
        </Item>
        <Form.List
          name='productsInOrder'
          initialValue={formik.values.productsInOrder}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => {
                const forkliftId = formik.values.productsInOrder[name]?.productId;
                const productType = formik.values.productsInOrder[name]?.type;
                const productAsset = availableProducts.find(forkliftItem => forkliftItem._id === forkliftId)?.assets[0];
                return (
                  <Row key={key}>
                    <Col span={24}>
                      <Row gutter={12}>
                        <Col
                          xs={24}
                          lg={12}
                        >
                          <Row>
                            <Col
                              xs={7}
                              sm={4}
                              md={3}
                              lg={5}
                            >
                              {productAsset ? (
                                <Image
                                  width={88}
                                  src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${productAsset.uri}`}
                                />
                              ) : (
                                <Image
                                  preview={false}
                                  src='../images/placeholder.png'
                                  width={80}
                                />
                              )}
                            </Col>
                            <Col
                              xs={17}
                              sm={20}
                              md={21}
                              lg={19}
                            >
                              <Item
                                label={`Produkt ${name + 1}`}
                                name={[name, 'productId']}
                                required={true}
                                validateStatus={
                                  // @ts-expect-error
                                  formik.errors.productsInOrder?.[name]?.productId ? 'error' : ''
                                }
                                help={
                                  // @ts-expect-error
                                  formik.errors.productsInOrder?.[name]?.productId
                                    ? // @ts-expect-error
                                      formik.errors.productsInOrder[name].productId
                                    : null
                                }
                              >
                                <Select
                                  showSearch
                                  placeholder='Wybierz produkt'
                                  optionFilterProp='children'
                                  value={formik.values.productsInOrder[name]?.productId}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  onChange={value => {
                                    const forklift = availableForkliftsList.find(element => element._id === value);
                                    const equipment = availableEquipmentsList.find(element => element._id === value);
                                    const part = availablePartsList.find(element => element._id === value);

                                    if (forklift) {
                                      void formik.setFieldValue(
                                        `productsInOrder[${name}].price`,
                                        formik.values.currency === CurrencyType.PLN
                                          ? forklift.priceSuggested
                                          : forklift.priceWholesaleSuggested,
                                        false,
                                      );
                                      void formik.setFieldValue(
                                        `productsInOrder[${name}].type`,
                                        ProductType.FORKLIFT,
                                        false,
                                      );
                                    }
                                    if (part) {
                                      void formik.setFieldValue(
                                        `productsInOrder[${name}].price`,
                                        formik.values.currency === CurrencyType.PLN ? part.price : part.priceWholesale,
                                        false,
                                      );
                                      void formik.setFieldValue(
                                        `productsInOrder[${name}].type`,
                                        ProductType.PART,
                                        false,
                                      );
                                    }
                                    if (equipment) {
                                      void formik.setFieldValue(
                                        `productsInOrder[${name}].price`,
                                        formik.values.currency === CurrencyType.PLN
                                          ? equipment.price
                                          : equipment.priceWholesale,
                                        false,
                                      );
                                      void formik.setFieldValue(
                                        `productsInOrder[${name}].type`,
                                        ProductType.EQUIPMENT,
                                        false,
                                      );
                                    }

                                    return formik.setFieldValue(`productsInOrder[${name}].productId`, value, false);
                                  }}
                                  options={availableProducts.map(product => ({
                                    value: product._id,
                                    label: `${product.internalId} - ${product.brand?.name ?? ''} ${
                                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                      product.serialNumber ? `- ${product.serialNumber}` : ''
                                    }`,
                                  }))}
                                />
                              </Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={24}
                          lg={12}
                        >
                          <Row gutter={8}>
                            <Col span={11}>
                              <Item
                                label='Cena końcowa netto'
                                required={true}
                                validateStatus={
                                  // @ts-expect-error
                                  formik.errors.productsInOrder?.[name]?.price ? 'error' : ''
                                }
                                help={
                                  // @ts-expect-error
                                  formik.errors.productsInOrder?.[name]?.price
                                    ? // @ts-expect-error
                                      formik.errors.productsInOrder[name].price
                                    : null
                                }
                              >
                                <Input
                                  addonAfter={CurrencyTypeNames[formik.values.currency]}
                                  type='number'
                                  placeholder='Cena końcowa netto'
                                  value={formik.values.productsInOrder[name]?.price}
                                  onChange={event =>
                                    formik.setFieldValue(`productsInOrder[${name}].price`, event.target.value)
                                  }
                                  onBlur={() => formik.setFieldTouched(`productsInOrder[${name}].price`)}
                                />
                              </Item>
                            </Col>
                            <Col span={13}>
                              <Row
                                align='middle'
                                gutter={8}
                              >
                                <Col span={23}>
                                  <Item
                                    label='Uwagi'
                                    hasFeedback={true}
                                    validateStatus={
                                      // @ts-expect-error
                                      formik.errors.productsInOrder?.[name]?.notes &&
                                      formik.touched.productsInOrder?.[name]?.notes
                                        ? 'error'
                                        : ''
                                    }
                                    help={
                                      // @ts-expect-error
                                      formik.errors.productsInOrder?.[name]?.notes &&
                                      formik.touched.productsInOrder?.[name]?.notes
                                        ? // @ts-expect-error
                                          formik.errors.productsInOrder[name].notes
                                        : null
                                    }
                                  >
                                    <TextArea
                                      name='notes'
                                      showCount
                                      maxLength={1000}
                                      onChange={event =>
                                        formik.setFieldValue(`productsInOrder[${name}].notes`, event.target.value)
                                      }
                                      placeholder='Uwagi'
                                      value={formik.values.productsInOrder[name]?.notes}
                                    />
                                  </Item>
                                </Col>
                                <Col span={1}>
                                  <DeleteOutlined
                                    onClick={() => {
                                      const products = [...formik.values.productsInOrder];
                                      products.splice(name, 1);
                                      void formik.setFieldValue('productsInOrder', products);
                                      remove(name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {productType === ProductType.FORKLIFT && (
                        <Row className='test'>
                          <Col
                            lg={{ offset: 2 }}
                            className='paddingColumnRight'
                          >
                            <Item
                              validateStatus={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.technicalReview &&
                                formik.touched.productsInOrder?.[name]?.technicalReview
                                  ? 'error'
                                  : ''
                              }
                              help={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.technicalReview &&
                                formik.touched.productsInOrder?.[name]?.technicalReview
                                  ? // @ts-expect-error
                                    formik.errors.productsInOrder[name].technicalReview
                                  : null
                              }
                            >
                              <Checkbox
                                name='technicalReview'
                                onChange={event =>
                                  formik.setFieldValue(`productsInOrder[${name}].technicalReview`, event.target.checked)
                                }
                                checked={formik.values.productsInOrder[name]?.technicalReview}
                              >
                                Przegląd
                              </Checkbox>
                            </Item>
                          </Col>
                          <Col className='borderColumn paddingColumnRight'>
                            <Item
                              validateStatus={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.guarantee &&
                                formik.touched.productsInOrder?.[name]?.guarantee
                                  ? 'error'
                                  : ''
                              }
                              help={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.guarantee &&
                                formik.touched.productsInOrder?.[name]?.guarantee
                                  ? // @ts-expect-error
                                    formik.errors.productsInOrder[name].guarantee
                                  : null
                              }
                            >
                              <Checkbox
                                name='guarantee'
                                onChange={event =>
                                  formik.setFieldValue(`productsInOrder[${name}].guarantee`, event.target.checked)
                                }
                                checked={formik.values.productsInOrder[name]?.guarantee}
                              >
                                Gwarancja
                              </Checkbox>
                            </Item>
                          </Col>
                          <Col className='borderColumn'>
                            <Item
                              validateStatus={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.udt &&
                                formik.touched.productsInOrder?.[name]?.udt
                                  ? 'error'
                                  : ''
                              }
                              help={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.udt &&
                                formik.touched.productsInOrder?.[name]?.udt
                                  ? // @ts-expect-error
                                    formik.errors.productsInOrder[name].udt
                                  : null
                              }
                            >
                              <Checkbox
                                name='udt'
                                onChange={event =>
                                  formik.setFieldValue(`productsInOrder[${name}].udt`, event.target.checked)
                                }
                                checked={formik.values.productsInOrder[name]?.udt}
                              >
                                UDT
                              </Checkbox>
                            </Item>
                          </Col>
                          <Col className='borderColumn'>
                            <Item
                              validateStatus={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.rectifier &&
                                formik.touched.productsInOrder?.[name]?.rectifier
                                  ? 'error'
                                  : ''
                              }
                              help={
                                // @ts-expect-error
                                formik.errors.productsInOrder?.[name]?.rectifier &&
                                formik.touched.productsInOrder?.[name]?.rectifier
                                  ? // @ts-expect-error
                                    formik.errors.productsInOrder[name].rectifier
                                  : null
                              }
                            >
                              <Checkbox
                                name='rectifier'
                                onChange={event =>
                                  formik.setFieldValue(`productsInOrder[${name}].rectifier`, event.target.checked)
                                }
                                checked={formik.values.productsInOrder[name]?.rectifier}
                              >
                                Prostownik
                              </Checkbox>
                            </Item>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Item
                className='addProduct'
                validateStatus={
                  typeof formik.errors.productsInOrder === 'string' && formik.errors.productsInOrder !== ''
                    ? 'error'
                    : ''
                }
                help={typeof formik.errors.productsInOrder === 'string' && formik.errors.productsInOrder}
              >
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Dodaj produkt
                </Button>
              </Item>
              <Row
                className='finalPrice'
                justify='end'
              >
                Wartość zamówienia netto:{' '}
                {formatPrice(
                  formik.values.productsInOrder.reduce((total, item) => {
                    return total + +item.price;
                  }, 0),
                  formik.values.currency,
                )}
              </Row>
            </>
          )}
        </Form.List>
        <Row justify='space-between'>
          <Space>
            <Button onClick={handleCancelForm}>Anuluj</Button>
          </Space>
          <Space>
            {order?._id && (
              <Tooltip title={formik.dirty ? 'Zamówienie jest w trakcie edycji, zapisz aby kontynuować' : undefined}>
                <Button
                  disabled={formik.dirty}
                  onClick={() => dispatch(getOrderPDFRequest(order._id ?? ''))}
                  loading={isFetching || isLoadingOrder}
                >
                  Generuj PDF
                </Button>
              </Tooltip>
            )}
            <Button
              type='primary'
              htmlType='submit'
              loading={isFetching || isLoadingOrder}
              onClick={formik.submitForm}
            >
              {order ? 'Zapisz' : 'Dodaj'}
            </Button>
          </Space>
        </Row>
      </Form>
      <DrawerAddEdit
        titleEdit='Edytuj dane klienta'
        titleAdd='Dodaj klienta'
        openModal={openClientModal}
        handleCloseModal={() => setOpenClientModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <ClientForm
          handleSubmit={handleSubmitClient}
          handleCancelForm={() => setOpenClientModal(false)}
        />
      </DrawerAddEdit>
    </div>
  );
};
