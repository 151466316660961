import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';

import {
  addOrderError,
  addOrderRequest,
  addOrderSuccess,
  deleteOrderError,
  deleteOrderRequest,
  deleteOrderSuccess,
  getOrderPDFError,
  getOrderPDFRequest,
  getOrderPDFSuccess,
  getOrdersError,
  getOrdersSuccess,
  updateOrderError,
  updateOrderRequest,
  updateOrderSuccess,
} from './actions';

import * as CONST from './consts';
import { OrderModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { apiClientOrders } from '../../services/apiClient/orders';
import { getForkliftsRequest } from '../forklift/actions';
import { getEquipmentsRequest } from '../equipment/actions';
import { getPartsRequest } from '../parts/actions';

function* getOrders(): Generator<CallEffect | PutEffect, void, ResponseModel<OrderModel[]>> {
  try {
    const response = yield call(apiClientOrders.getOrders);

    yield put(getOrdersSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get orders',
      description: getErrorMessage(err),
    });
    yield put(getOrdersError(err));
  }
}

function* addOrder(
  action: ActionType<typeof addOrderRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<OrderModel>> {
  try {
    const { order, onSuccess } = action.payload;
    const response = yield call(apiClientOrders.addOrder, order);

    yield put(addOrderSuccess(response.data));
    yield put(getForkliftsRequest());
    yield put(getEquipmentsRequest());
    yield put(getPartsRequest());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding order',
      description: getErrorMessage(err),
    });
    yield put(addOrderError(err));
  }
}

function* updateOrder(
  action: ActionType<typeof updateOrderRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<OrderModel>> {
  try {
    const { order, onSuccess } = action.payload;
    const response = yield call(apiClientOrders.updateOrder, order);

    yield put(updateOrderSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating order',
      description: getErrorMessage(err),
    });
    yield put(updateOrderError(err));
  }
}

function* deleteOrder(action: ActionType<typeof deleteOrderRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { orderId, onSuccess } = action.payload;
    yield call(apiClientOrders.deleteOrder, orderId);

    yield put(deleteOrderSuccess(orderId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting order',
      description: getErrorMessage(err),
    });
    yield put(deleteOrderError(err));
  }
}

function* getOrderPDF(
  action: ActionType<typeof getOrderPDFRequest>,
): Generator<CallEffect | PutEffect, void, ArrayBuffer> {
  try {
    const { orderId, onSuccess } = action.payload;
    const response = yield call(apiClientOrders.getOrderPDF, orderId);
    const blobUrl = URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
    window.open(blobUrl, '_blank');
    yield put(getOrderPDFSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during generating order PDF',
      description: getErrorMessage(err),
    });
    yield put(getOrderPDFError(err));
  }
}

export function* watchOrdersSaga(): Generator {
  yield takeLatest(CONST.GET_ORDERS_REQUEST, getOrders);
  yield takeLatest(CONST.ADD_ORDER_REQUEST, addOrder);
  yield takeLatest(CONST.UPDATE_ORDER_REQUEST, updateOrder);
  yield takeLatest(CONST.DELETE_ORDER_REQUEST, deleteOrder);
  yield takeLatest(CONST.GET_ORDER_PDF_REQUEST, getOrderPDF);
}
