import { OrderModel } from '../../reducers/orders/model';

export type OrderDataModel = OrderModel & {
  companyName: string;
  sellerName: string;
  productsInOrderResult: number;
};

export const getOrderDate = (orders: OrderModel[]): OrderDataModel[] => {
  return orders.map(order => ({
    ...order,
    companyName: order.client?.companyName ?? '',
    sellerName: `${order.owner?.name ?? ''} ${order.owner?.surname ?? ''}`,
    productsInOrderResult: order.productsInOrder.length,
  }));
};
