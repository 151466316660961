import styled from 'styled-components';

export const AdditionalServiceProductSC = styled.div`
  align-items: center;
  display: flex;
  margin-right: 8px;

  svg {
    margin-right: 5px;
  }
`;
