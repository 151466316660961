import styled from 'styled-components';
import { List } from 'antd';

export const ProductsListSC = styled(List)`
  &.productsList li.listItem {
    padding-left: 0;
    padding-right: 0;
    flex-flow: row wrap;
    .quickViewListExtra {
      display: block;
      flex: 1 100%;

      .quickViewIconService {
        display: flex;
      }
    }
    .listItemMeta {
      flex: 1 100%;
      & > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .orderItem {
    flex-direction: column;
  }
`;
