import { useQuery } from 'react-query';
import { apiClientEquipmentCategories } from './index';

const queryKeys = {
  getEquipments: 'equipment-categories',
};

export const useGetEquipmentsCategories = () => {
  return useQuery(
    queryKeys.getEquipments,
    async () => {
      const response = await apiClientEquipmentCategories.getEquipmentCategories();
      return response.data;
    },
    { initialData: [] },
  );
};
