import React, { useEffect, useMemo, useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { OrdersList } from '../../component/orders/OrdersList';
import { OrdersTabs } from './OrdersTabs';
import { OrderModel, OrderStatus } from '../../reducers/orders/model';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { getOrdersRequest } from '../../reducers/orders/actions';
import { OrdersHeader } from './OrdersHeader';
import { useViewport } from '../../utils/customHooks/useViewport';
import { OrderMobileList } from '../../component/orders/mobile/OrderMobileList';
import { FloatingActionButton } from '../../layout/buttons/FloatingActionButton';

export const OrdersPage = () => {
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const ordersList = useSelector<RootReducerState, OrderModel[]>(state => state.orders.ordersList);
  const isFetchingList = useSelector<RootReducerState, boolean>(state => state.orders.isFetching);
  const [status, setStatus] = useState<OrderStatus>(OrderStatus.NOT_PAID);
  const [searchOrder, setSearchOrder] = useState('');
  const { isMobile } = useViewport();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersRequest());
  }, [dispatch]);

  const { ordersPaid, ordersNotPaid, ordersDone } = useMemo(() => {
    const ordersPaid = ordersList.filter(order => order.status === OrderStatus.PAID);
    const ordersNotPaid = ordersList.filter(order =>
      [OrderStatus.NOT_PAID, OrderStatus.CANCEL, OrderStatus.LEASING, OrderStatus.PAID_IN_ADVANCE].includes(
        order.status,
      ),
    );
    const ordersDone = ordersList.filter(order => order.status === OrderStatus.DONE);

    return {
      ordersPaid: ordersPaid,
      ordersNotPaid: ordersNotPaid,
      ordersDone: ordersDone,
    };
  }, [ordersList]);

  const filteredOrderList = useMemo(() => {
    let filteredOrderList: OrderModel[] = [];
    switch (status) {
      case OrderStatus.NOT_PAID:
        filteredOrderList = ordersNotPaid;
        break;
      case OrderStatus.PAID:
        filteredOrderList = ordersPaid;
        break;
      case OrderStatus.DONE:
        filteredOrderList = ordersDone;
        break;
    }

    if (searchOrder) {
      return filteredOrderList.filter(order =>
        order.orderNr.toLocaleLowerCase().includes(searchOrder.toLocaleLowerCase()),
      );
    }

    return filteredOrderList;
  }, [status, ordersNotPaid, ordersPaid, ordersDone, searchOrder]);

  return (
    <LayoutMain>
      <OrdersHeader onSetOpenOrderModal={setOpenOrderModal} />
      <OrdersTabs
        ordersPaidAmount={ordersPaid.length}
        ordersNotPaidAmount={ordersNotPaid.length}
        ordersDoneAmount={ordersDone.length}
        onChange={status => setStatus(status)}
      />
      {isMobile && (
        <OrderMobileList
          ordersList={filteredOrderList}
          isFetchingList={isFetchingList}
          openOrderModal={openOrderModal}
          clickOpenOrderModal={openOrderModal => setOpenOrderModal(openOrderModal)}
        />
      )}
      {!isMobile && (
        <OrdersList
          ordersList={filteredOrderList}
          openOrderModal={openOrderModal}
          clickOpenOrderModal={openOrderModal => setOpenOrderModal(openOrderModal)}
        />
      )}
      <FloatingActionButton onSearch={searchText => setSearchOrder(searchText)} />
    </LayoutMain>
  );
};
