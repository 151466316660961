import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';

import { PartsCategoriesHeader } from './PartsCategoriesHeader';
import { PartsCategoriesList } from '../../component/partsCategories/PartsCategoriesList';

export const PartsCategoriesPage = () => {
  const [openPartCategoryModal, setOpenPartCategoryModal] = useState(false);

  return (
    <LayoutMain>
      <PartsCategoriesHeader onSetOpenPartCategoryModal={setOpenPartCategoryModal} />
      <PartsCategoriesList
        openPartCategoryModal={openPartCategoryModal}
        clickOpenPartCategoryModal={openEquipmentCategoryModal => setOpenPartCategoryModal(openEquipmentCategoryModal)}
      />
    </LayoutMain>
  );
};
