import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

import { Button, Layout, Menu, Typography } from 'antd';

const { Text } = Typography;
const { Header } = Layout;

export const HeaderSC = styled(Header)`
  background-color: #33558b;
  height: 60px;
  padding: 0 10px 0 12px;

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 20px 0 30px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding: 0 20px 0 40px;
  }

  h2 {
    color: #fff;
    font-size: 20px;

    @media screen and (min-width: ${breakpoints.xl}) {
      font-size: 30px;
      margin: 10px 0 0 0;
    }
  }
`;

export const MenuSC = styled(Menu)`
  border-inline-end: none !important;
  cursor: unset;
  text-align: left;

  .ant-menu-item {
    margin: 0;
    padding: 0;
    height: inherit;

    button {
      padding: 0;
    }
  }
  .ant-menu-item:hover,
  .ant-menu-item-selected {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.88);
  }
`;

export const MenuItemUserTextSC = styled(Text)`
  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: 11px;
  }
`;

export const ButtonLogoutSC = styled(Button)`
  justify-content: left;
  text-align: left;
  width: 100%;

  @media screen and (min-width: ${breakpoints.xl}) {
    justify-content: center;
    text-align: center;
  }
`;
