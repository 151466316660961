import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { notification } from 'antd';

import {
  addPartBrandError,
  addPartBrandRequest,
  addPartBrandSuccess,
  deletePartBrandError,
  deletePartBrandRequest,
  deletePartBrandSuccess,
  getPartBrandsError,
  getPartBrandsSuccess,
  updatePartBrandError,
  updatePartBrandRequest,
  updatePartBrandSuccess,
} from './actions';

import * as CONST from './consts';
import { getErrorMessage } from '../../utils/error';
import { apiClientPartBrands } from '../../services/apiClient/partsBrands';
import { ResponseModel } from '../model';
import { PartBrandModel } from './model';

function* getPartBrands(): Generator<CallEffect | PutEffect, void, ResponseModel<PartBrandModel[]>> {
  try {
    const response = yield call(apiClientPartBrands.getPartBrands);

    yield put(getPartBrandsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get part brands',
      description: getErrorMessage(err),
    });
    yield put(getPartBrandsError(err));
  }
}

function* addPartBrand(
  action: ActionType<typeof addPartBrandRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartBrandModel>> {
  try {
    const { partBrand, onSuccess } = action.payload;
    const response = yield call(apiClientPartBrands.addPartBrand, partBrand);

    yield put(addPartBrandSuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding part brand',
      description: getErrorMessage(err),
    });
    yield put(addPartBrandError(err));
  }
}

function* updatePartBrand(
  action: ActionType<typeof updatePartBrandRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartBrandModel>> {
  try {
    const { partBrand, onSuccess } = action.payload;
    const response = yield call(apiClientPartBrands.updatePartBrand, partBrand);

    yield put(updatePartBrandSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating part brand',
      description: getErrorMessage(err),
    });
    yield put(updatePartBrandError(err));
  }
}

function* deletePartBrand(
  action: ActionType<typeof deletePartBrandRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { partBrandId, onSuccess } = action.payload;
    yield call(apiClientPartBrands.deletePartBrand, partBrandId);

    yield put(deletePartBrandSuccess(partBrandId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting part brand',
      description: getErrorMessage(err),
    });
    yield put(deletePartBrandError(err));
  }
}

export function* watchPartBrandsSaga(): Generator {
  yield takeLatest(CONST.GET_PART_BRANDS_REQUEST, getPartBrands);
  yield takeLatest(CONST.ADD_PART_BRAND_REQUEST, addPartBrand);
  yield takeLatest(CONST.UPDATE_PART_BRAND_REQUEST, updatePartBrand);
  yield takeLatest(CONST.DELETE_PART_BRAND_REQUEST, deletePartBrand);
}
