import React, { FC, useState, useEffect } from 'react';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { EquipmentModel } from '../../reducers/equipment/model';
import { ColumnsType } from 'antd/es/table';
import { AlignType, FixedType } from 'rc-table/lib/interface';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { addEquipmentRequest, deleteEquipmentRequest, updateEquipmentRequest } from '../../reducers/equipment/actions';
import { BrandModel } from '../../reducers/brands/model';
import { CategoryModel } from '../../reducers/categories/model';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { EquipmentForm, EquipmentFormValues } from '../../forms/equipment';
import { EquipmentCategoryModel } from '../../reducers/equipmentCategories/model';
import { getEquipmentCategoriesRequest } from '../../reducers/equipmentCategories/actions';
import { getEquipmentBrandsRequest } from '../../reducers/equipmentBrands/actions';
import { EquipmentPreview } from './EquipmentPreview';
import { ProductStatus, ProductStatusListType, ProductStatusNames } from '../../reducers/forklift/model';
import { getStatusProductIcon } from '../../utils/statusProductIcon';
import { formatPrice } from '../../utils/formatPrice';
import { CurrencyType, OrderModel } from '../../reducers/orders/model';
import { getOrdersRequest } from '../../reducers/orders/actions';
import { OrderPreview } from '../orders/OrderPreview';
import { useViewport } from '../../utils/customHooks/useViewport';

type EquipmentListProps = {
  equipmentList: EquipmentModel[];
  openEquipmentModal: boolean;
  clickOpenEquipmentModal: (openEquipmentModal: boolean) => void;
  groupEditStatus: string[];
  setGroupEditStatus: (groupEditStatus: string[]) => void;
  selectedEquipmentTab: ProductStatusListType;
};

export const EquipmentList: FC<EquipmentListProps> = props => {
  const {
    equipmentList,
    clickOpenEquipmentModal,
    openEquipmentModal,
    groupEditStatus,
    setGroupEditStatus,
    selectedEquipmentTab,
  } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.equipment.isFetching);
  const brandsList = useSelector<RootReducerState, BrandModel[]>(state => state.equipmentBrands.equipmentBrandsList);
  const categoriesList = useSelector<RootReducerState, EquipmentCategoryModel[]>(
    state => state.equipmentCategories.categoriesList,
  );
  const ordersList = useSelector<RootReducerState, OrderModel[]>(state => state.orders.ordersList);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentModel | undefined>(undefined);
  const [, setDeleteEquipmentModal] = useState(false);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | undefined>(undefined);
  const [openQuickViewOrderModal, setOpenQuickViewOrderModal] = useState(false);
  const { height } = useViewport();

  const extendedEquipmentList = equipmentList.map(equipment => ({
    ...equipment,
    brandName: equipment.brand?.name,
    orderNr: equipment.order?.orderNr ?? '',
  }));

  const dispatch = useDispatch();
  const { getColumnSearchProps, getColumnFilterProps, getColumnSearchRangeProps } = useFilterAntTable();

  useEffect(() => {
    dispatch(getEquipmentCategoriesRequest());
    dispatch(getEquipmentBrandsRequest());
    dispatch(getOrdersRequest());
  }, [dispatch]);

  const handleEditEquipmentModal = (equipmentId: string) => {
    const equipment = equipmentList.find(item => item._id === equipmentId);
    setSelectedEquipment(equipment);
    clickOpenEquipmentModal(true);
  };

  const handleDeleteEquipmentModal = (equipmentId: string) => {
    dispatch(
      deleteEquipmentRequest(equipmentId, () => {
        setDeleteEquipmentModal(false);
      }),
    );
  };

  const handleCloseEquipmentModal = () => {
    setSelectedEquipment(undefined);
    clickOpenEquipmentModal(false);
  };

  const handleSubmit = (values: EquipmentFormValues) => {
    if (selectedEquipment) {
      dispatch(
        updateEquipmentRequest(
          {
            ...selectedEquipment,
            ...values,
          },
          () => {
            setSelectedEquipment(undefined);
            clickOpenEquipmentModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addEquipmentRequest(values, () => {
          setSelectedEquipment(undefined);
          clickOpenEquipmentModal(false);
        }),
      );
    }
  };

  const handleQuickViewEquipmentModal = (equipmentId: string) => {
    const equipment = equipmentList.find(item => item._id === equipmentId);
    setSelectedEquipment(equipment);
    setOpenQuickViewModal(true);
  };

  const handleCloseQuickViewModal = () => {
    setSelectedEquipment(undefined);
    setOpenQuickViewModal(false);
  };

  const handleQuickViewOrderModal = (orderId: string) => {
    const selectedOrder = ordersList.find(order => order._id === orderId);
    setSelectedOrder(selectedOrder);
    setOpenQuickViewOrderModal(true);
  };

  const handleCloseQuickViewOrderModal = () => {
    setSelectedOrder(undefined);
    setOpenQuickViewOrderModal(false);
  };

  const columns: ColumnsType<EquipmentModel> = [
    {
      title: 'Id',
      dataIndex: 'internalId',
      key: 'internalId',
      ...getColumnSearchProps('internalId', 'Id'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.internalId.localeCompare(b.internalId),
    },
    {
      title: 'Marka',
      dataIndex: 'brandName',
      key: 'brandName',
      ...getColumnFilterProps(
        'brandId',
        brandsList.map(brand => ({
          text: brand.name,
          value: brand._id,
        })),
      ),
      render: (brandName?: string) => <>{brandName}</>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type', 'typu'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.type.localeCompare(b.type),
    },
    {
      title: 'SN',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      ...getColumnSearchProps('serialNumber', 'nr seryjnego'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.serialNumber.localeCompare(b.serialNumber),
    },
    {
      title: 'Kategoria',
      dataIndex: 'category',
      key: 'category',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnFilterProps(
        'categoryId',
        categoriesList.map(category => ({
          text: category.name,
          value: category._id,
        })),
      ),
      render: (category?: CategoryModel) => <>{category?.name}</>,
    },
    {
      title: 'Mocowanie',
      dataIndex: 'mounting',
      key: 'mounting',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchProps('mounting', 'mocowania'),
    },
    {
      title: 'Udźwig',
      dataIndex: 'liftingCapacity',
      key: 'liftingCapacity',
      hidden: false,
      ...getColumnSearchRangeProps('liftingCapacity', 'udźwigu'),
      render: (liftingCapacity: number) => <>{liftingCapacity} kg</>,
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.liftingCapacity - b.liftingCapacity,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'status',
        Object.values(ProductStatus).map(status => ({
          value: status,
          text: ProductStatusNames[status],
        })),
      ),
      hidden: false,
      render: (status: ProductStatus) => getStatusProductIcon(status),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.status.localeCompare(b.status),
    },
    {
      title: 'Rok produkcji',
      dataIndex: 'productionYear',
      key: 'productionYear',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('productionYear', 'rok produkcji'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.productionYear - b.productionYear,
    },
    {
      title: 'Szerokość',
      dataIndex: 'width',
      key: 'width',
      align: 'center' as AlignType,
      ...getColumnSearchRangeProps('width', 'szerokości'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.width - b.width,
    },
    {
      title: 'Min zakres pracy',
      dataIndex: 'minOperatingRange',
      key: 'minOperatingRange',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('minOperatingRange', 'zakresu prac'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.minOperatingRange - b.minOperatingRange,
    },
    {
      title: 'Max zakres pracy',
      dataIndex: 'maxOperatingRange',
      key: 'maxOperatingRange',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('maxOperatingRange', 'zakresu prac'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.maxOperatingRange - b.maxOperatingRange,
    },
    {
      title: 'Widły/łapy',
      dataIndex: 'lengthFork',
      key: 'lengthFork',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('lengthFork', 'wideł/łap'),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.lengthFork - b.lengthFork,
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('price', 'ceny'),
      render: (price: number) => formatPrice(price, CurrencyType.PLN),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.price - b.price,
    },
    {
      title: 'Cena hurt',
      dataIndex: 'priceWholesale',
      key: 'priceWholesale',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('priceWholesale', 'ceny'),
      render: (priceWholesale: number) => formatPrice(priceWholesale, CurrencyType.EUR),
      sorter: (a: EquipmentModel, b: EquipmentModel) => a.priceWholesale - b.priceWholesale,
    },
    {
      title: 'Zamówienie',
      dataIndex: 'orderNr',
      key: 'orderNr',
      width: 150,
      ...getColumnSearchProps('orderNr', 'zamówienia'),
      render: (orderNr: string, equipment: EquipmentModel) =>
        equipment.order && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <a
            onClick={e => {
              e.preventDefault();
              return equipment.order && handleQuickViewOrderModal(equipment.order._id);
            }}
          >
            {orderNr}
          </a>
        ),
      hidden: selectedEquipmentTab === ProductStatusListType.AVAILABLE,
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      fixed: 'right' as FixedType,
      align: 'right' as AlignType,
      hidden: false,
      render: (_id: string, equipment: EquipmentModel) => (
        <>
          <Tooltip
            title='Podgląd'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Zobacz podgląd'
              onClick={() => handleQuickViewEquipmentModal(_id)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj osprzęt'
              onClick={() => handleEditEquipmentModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie osprzętu'
              description='Czy na pewno chcesz usunąć osprzęt?'
              onConfirm={() => handleDeleteEquipmentModal(equipment._id)}
              onCancel={() => setDeleteEquipmentModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń osprzęt'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <Table
        virtual
        columns={columns}
        dataSource={extendedEquipmentList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '0 16px 16px 16px' }}
        sticky
        rowSelection={{
          selectedRowKeys: groupEditStatus,
          onChange: (newSelectedRowKeys: React.Key[]) => setGroupEditStatus(newSelectedRowKeys as string[]),
          columnWidth: 30,
        }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        scroll={{ y: height - 222 }}
      />
      <DrawerAddEdit
        titleEdit='Edytuj osprzęt'
        titleAdd='Dodaj osprzęt'
        openModal={openEquipmentModal}
        handleCloseModal={handleCloseEquipmentModal}
        selectedItem={selectedEquipment}
        width='80%'
      >
        <EquipmentForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseEquipmentModal}
          equipmentId={selectedEquipment?._id}
        />
      </DrawerAddEdit>
      {selectedEquipment && (
        <DrawerAddEdit
          titleEdit={`Podgląd osprzętu: ${selectedEquipment.internalId}`}
          titleAdd={`Podgląd osprzętu: ${selectedEquipment.internalId}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedEquipment}
          width='80%'
        >
          <EquipmentPreview equipmentId={selectedEquipment._id} />
        </DrawerAddEdit>
      )}
      {selectedOrder && (
        <DrawerAddEdit
          titleEdit={`Podgląd zamówienia: ${selectedOrder.orderNr}`}
          titleAdd={`Podgląd zamówienia: ${selectedOrder.orderNr}`}
          openModal={openQuickViewOrderModal}
          handleCloseModal={handleCloseQuickViewOrderModal}
          selectedItem={selectedOrder}
          width='60%'
        >
          <OrderPreview orderId={selectedOrder._id} />
        </DrawerAddEdit>
      )}
    </>
  );
};
