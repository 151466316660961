import { combineReducers } from 'redux';

import { appReducer, AppState } from './app/reducer';
import { usersReducer, UsersState } from './user/reducer';
import { assetReducer, AssetState } from './assets/reducer';
import { brandsReducer, BrandsState } from './brands/reducer';
import { categoriesReducer, CategoriesState } from './categories/reducer';
import { forkliftsReducer, ForkliftsState } from './forklift/reducer';
import { clientsReducer, ClientsState } from './clients/reducer';
import { ordersReducer, OrdersState } from './orders/reducer';
import { settingsReducer, SettingsState } from './settings/reducer';
import { costsReducer, CostsState } from './costs/reducer';
import { mastsReducer, MastsState } from './masts/reducer';
import { equipmentReducer, EquipmentState } from './equipment/reducer';
import { equipmentBrandsReducer, EquipmentBrandsState } from './equipmentBrands/reducer';
import { equipmentCategoriesReducer, EquipmentCategoriesState } from './equipmentCategories/reducer';
import { reportsReducer, ReportsState } from './reports/reducer';
import { reservationsReducer, ReservationsState } from './reservations/reducer';
import { advancePaymentReducer, AdvancePaymentState } from './advancePayments/reducer';
import { offersReducer, OffersState } from './offers/reducer';
import { partsBrandsReducer, PartsBrandsState } from './partsBrands/reducer';
import { partsCategoriesReducer, PartsCategoriesState } from './partsCategories/reducer';
import { partsReducer, PartsState } from './parts/reducer';

export type RootReducerState = {
  app: AppState;
  users: UsersState;
  assets: AssetState;
  brands: BrandsState;
  categories: CategoriesState;
  forklifts: ForkliftsState;
  clients: ClientsState;
  orders: OrdersState;
  settings: SettingsState;
  costs: CostsState;
  masts: MastsState;
  equipment: EquipmentState;
  equipmentBrands: EquipmentBrandsState;
  equipmentCategories: EquipmentCategoriesState;
  reports: ReportsState;
  reservations: ReservationsState;
  advancePayment: AdvancePaymentState;
  offers: OffersState;
  partsBrands: PartsBrandsState;
  partsCategories: PartsCategoriesState;
  parts: PartsState;
};

export const rootReducer = () =>
  combineReducers<RootReducerState>({
    app: appReducer,
    users: usersReducer,
    assets: assetReducer,
    brands: brandsReducer,
    categories: categoriesReducer,
    forklifts: forkliftsReducer,
    clients: clientsReducer,
    orders: ordersReducer,
    settings: settingsReducer,
    costs: costsReducer,
    masts: mastsReducer,
    equipment: equipmentReducer,
    equipmentBrands: equipmentBrandsReducer,
    equipmentCategories: equipmentCategoriesReducer,
    reports: reportsReducer,
    reservations: reservationsReducer,
    advancePayment: advancePaymentReducer,
    offers: offersReducer,
    partsBrands: partsBrandsReducer,
    partsCategories: partsCategoriesReducer,
    parts: partsReducer,
  });
