import React, { FC, useState } from 'react';
import { EquipmentModel } from '../../../reducers/equipment/model';
import { Button, Divider, Dropdown, Flex, List, Space, Tag, Typography } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { DividerSC, ListItemSC } from './styles';
import { formatPrice } from '../../../utils/formatPrice';
import { CurrencyType } from '../../../reducers/orders/model';
import { useGetSettings } from '../../../services/apiClient/settings/settingsQueries';
import { GeneralSettingModel } from '../../../reducers/settings/model';

const { Text } = Typography;

type EquipmentMobileItemProps = {
  equipment: EquipmentModel;
  setSelectedEquipment: (selectedEquipment: EquipmentModel) => void;
  setOpenQuickViewModal: (openQuickViewModal: boolean) => void;
  setOpenEquipmentDeleteModal: (openEquipmentDeleteModal: boolean) => void;
  clickOpenEquipmentModal: (openEquipmentModal: boolean) => void;
};

export const EquipmentMobileItem: FC<EquipmentMobileItemProps> = props => {
  const {
    equipment,
    setSelectedEquipment,
    setOpenQuickViewModal,
    setOpenEquipmentDeleteModal,
    clickOpenEquipmentModal,
  } = props;
  const [morePrice, showMorePrice] = useState(false);
  const { data: settingsList } = useGetSettings();

  let euroExchange: GeneralSettingModel | undefined;
  if (settingsList) {
    euroExchange = settingsList.find(item => item.key === 'EUR-TO-PLN');
  }

  const handleDeleteEquipmentModal = (equipment: EquipmentModel) => {
    setSelectedEquipment(equipment);
    setOpenEquipmentDeleteModal(true);
  };

  const getItemsForActionMenu = (equipment: EquipmentModel) => [
    {
      label: 'Edytuj',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => {
        clickOpenEquipmentModal(true);
        setSelectedEquipment(equipment);
      },
    },
    {
      label: 'Podgląd',
      key: '2',
      icon: <EyeOutlined />,
      onClick: () => {
        setOpenQuickViewModal(true);
        setSelectedEquipment(equipment);
      },
    },
    {
      label: 'Usuń',
      key: '4',
      icon: <DeleteOutlined />,
      onClick: () => handleDeleteEquipmentModal(equipment),
    },
  ];

  return (
    <ListItemSC className='listItem'>
      <List.Item.Meta
        className='listItemMeta'
        avatar={
          equipment.assets.length > 0 && (
            <img
              style={{ height: '55px' }}
              alt={'test'}
              src={
                equipment.assets.length > 0
                  ? `${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${equipment.assets[0].uri}`
                  : ''
              }
            />
          )
        }
        title={
          <Flex justify='space-between'>
            <div>
              {equipment.internalId} <Divider type='vertical' /> {equipment.brand?.name} <Divider type='vertical' />{' '}
              {equipment.serialNumber}
            </div>
            <Dropdown menu={{ items: getItemsForActionMenu(equipment) }}>
              <Button
                type='text'
                aria-label='Pokaż więcej opcji'
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
          </Flex>
        }
        description={
          <Flex
            gap='4px 0'
            wrap
          >
            <Tag color='magenta'>{equipment.category?.name}</Tag>
            <Tag color='red'>{equipment.productionYear}</Tag>
            <Tag color='purple'>{equipment.mounting}</Tag>
            <Tag color='geekblue'>{equipment.liftingCapacity} kg</Tag>
            <Tag color='blue'>{equipment.width} mm</Tag>
            <Tag color='cyan'>{equipment.lengthFork} mm</Tag>
          </Flex>
        }
      />
      <DividerSC />
      <Flex
        justify='space-between'
        align='center'
      >
        <Text>
          Cena minimalna: {formatPrice(equipment.price, CurrencyType.PLN)}
          <Text
            className='secondaryText'
            type='secondary'
          >
            ({formatPrice(euroExchange?.value ? equipment.price / +euroExchange.value : 0, CurrencyType.EUR)})
          </Text>
        </Text>
        <Button
          type='text'
          aria-label='Pokaż więcej'
          onClick={() => showMorePrice(!morePrice)}
          icon={morePrice ? <UpOutlined /> : <DownOutlined />}
        ></Button>
      </Flex>
      {morePrice && (
        <Space direction='vertical'>
          <Text>
            Cena hurtowa: <Text strong>{formatPrice(equipment.priceWholesale, CurrencyType.EUR)}</Text>
            <Text
              className='secondaryText'
              type='secondary'
            >
              (
              {formatPrice(
                equipment.priceWholesale && euroExchange?.value ? equipment.priceWholesale * +euroExchange.value : 0,
                CurrencyType.PLN,
              )}
              )
            </Text>
          </Text>
          <Text>
            Minimalny zakres pracy: <Text strong>{equipment.minOperatingRange} mm</Text>
          </Text>
          <Text>
            Maksymalny zakres pracy: <Text strong>{equipment.maxOperatingRange} mm</Text>
          </Text>
        </Space>
      )}
    </ListItemSC>
  );
};
