import React, { FC, useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { PartCategoryModel } from '../../reducers/partsCategories/model';
import {
  addPartCategoryRequest,
  deletePartCategoryRequest,
  getPartsCategoriesRequest,
  updatePartCategoryRequest,
} from '../../reducers/partsCategories/actions';
import { PartCategoryForm, PartCategoryFormValues } from '../../forms/partCategory';

type PartsCategoriesListProps = {
  openPartCategoryModal: boolean;
  clickOpenPartCategoryModal: (openPartCategoryModal: boolean) => void;
};

export const PartsCategoriesList: FC<PartsCategoriesListProps> = props => {
  const { openPartCategoryModal, clickOpenPartCategoryModal } = props;
  const partsCategoriesList = useSelector<RootReducerState, PartCategoryModel[]>(
    state => state.partsCategories.categoriesList,
  );
  const isFetching = useSelector<RootReducerState, boolean>(state => state.partsCategories.isFetching);
  const dispatch = useDispatch();
  const { getColumnSearchProps } = useFilterAntTable();
  const [selectedPartCategory, setSelectedPartCategory] = useState<PartCategoryModel | undefined>(undefined);
  const [, setDeletePartCategoryModal] = useState(false);

  useEffect(() => {
    dispatch(getPartsCategoriesRequest());
  }, [dispatch]);

  const handleEditPartCategoryModal = (categoryId: string) => {
    const category = partsCategoriesList.find(item => item._id === categoryId);
    setSelectedPartCategory(category);
    clickOpenPartCategoryModal(true);
  };

  const handleClosePartCategoryModal = () => {
    setSelectedPartCategory(undefined);
    clickOpenPartCategoryModal(false);
  };

  const handleDeletePartCategoryModal = (categoryId: string) => {
    dispatch(
      deletePartCategoryRequest(categoryId, () => {
        setDeletePartCategoryModal(false);
      }),
    );
  };

  const handleSubmit = (values: PartCategoryFormValues) => {
    if (selectedPartCategory) {
      dispatch(
        updatePartCategoryRequest(
          {
            ...selectedPartCategory,
            ...values,
          },
          () => {
            setSelectedPartCategory(undefined);
            clickOpenPartCategoryModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addPartCategoryRequest(values, () => {
          setSelectedPartCategory(undefined);
          clickOpenPartCategoryModal(false);
        }),
      );
    }
  };

  const columns: ColumnsType<PartCategoryModel> = [
    {
      title: 'Kategoria',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ...getColumnSearchProps('name', 'kategorii'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => createdAt && dayjs(createdAt).format('DD-MM-YYYY HH:mm'),
      sorter: (a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime(),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      align: 'right' as AlignType,
      render: (_id: string) => (
        <>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj kategorię'
              onClick={() => handleEditPartCategoryModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie kategorii'
              description='Czy na pewno chcesz usunąć kategorię?'
              onConfirm={() => handleDeletePartCategoryModal(_id)}
              onCancel={() => setDeletePartCategoryModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń kategorię'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={partsCategoriesList}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        style={{ padding: '16px' }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        sticky
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane kategorii'
        titleAdd='Dodaj kategorię'
        openModal={openPartCategoryModal}
        handleCloseModal={handleClosePartCategoryModal}
        selectedItem={selectedPartCategory}
      >
        <PartCategoryForm
          partCategory={selectedPartCategory}
          handleSubmit={handleSubmit}
          handleCancelForm={handleClosePartCategoryModal}
        />
      </DrawerAddEdit>
    </>
  );
};
