import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { useViewport } from '../../utils/customHooks/useViewport';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { PartsBrandsAdditionalMenu } from './PartsBrandsAdditionalMenu';

type PartsBrandsHeaderProps = {
  onSetOpenPartBrandModal: (openPartBrandModal: boolean) => void;
};

export const PartsBrandsHeader: FC<PartsBrandsHeaderProps> = props => {
  const { onSetOpenPartBrandModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Marki'
      onAddElementOpenModal={onSetOpenPartBrandModal}
    >
      <PartsBrandsAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Marki'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenPartBrandModal(true)}
          >
            Dodaj markę
          </Button>
        </Col>
        <PartsBrandsAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
