import React, { FC, useState } from 'react';
import { Button, Dropdown, Flex, List, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { OrderModel } from '../../../reducers/orders/model';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { formatPrice } from '../../../utils/formatPrice';
import { OrderDataModel } from '../utils';

import { statusOrderClass } from './statusOrderClass';
import { DividerSC, ListItemSC } from './styles';
import { getStatusOrderClass } from '../../../utils/statusOrderClass';

const { Text } = Typography;

type OrderMobileItemProps = {
  order: OrderDataModel;
  clickOpenOrderModal: (openOrderModal: boolean) => void;
  setSelectedOrder: (selectedOrder: OrderModel) => void;
  setOpenQuickViewModal: (openQuickViewModal: boolean) => void;
  setOpenOrderDeleteModal: (openOrderDeleteModal: boolean) => void;
};

export const OrderMobileItem: FC<OrderMobileItemProps> = props => {
  const { order, clickOpenOrderModal, setSelectedOrder, setOpenQuickViewModal, setOpenOrderDeleteModal } = props;
  const [morePrice, showMorePrice] = useState(false);

  const getItemsForActionMenu = (order: OrderModel) => [
    {
      label: 'Edytuj',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => {
        clickOpenOrderModal(true);
        setSelectedOrder(order);
      },
    },
    {
      label: 'Podgląd',
      key: '2',
      icon: <EyeOutlined />,
      onClick: () => {
        setOpenQuickViewModal(true);
        setSelectedOrder(order);
      },
    },
    {
      label: 'Usuń',
      key: '4',
      icon: <DeleteOutlined />,
      onClick: () => handleDeleteOrderModal(order),
    },
  ];

  const handleDeleteOrderModal = (order: OrderModel) => {
    setSelectedOrder(order);
    setOpenOrderDeleteModal(true);
  };

  const orderClass = `listItem ${getStatusOrderClass(order.status, order.paidAt)}`;

  return (
    <ListItemSC className={orderClass}>
      <List.Item.Meta
        className='listItemMeta'
        title={
          <Flex justify='space-between'>
            <div>{order.orderNr}</div>
            <Dropdown menu={{ items: getItemsForActionMenu(order) }}>
              <Button
                type='text'
                aria-label='Pokaż więcej opcji'
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
          </Flex>
        }
        description={
          <Flex
            gap='4px 0'
            wrap
          >
            {statusOrderClass(order.status)}
            <Tag color='purple'>Produkty: {order.productsInOrderResult}</Tag>
          </Flex>
        }
      />
      <DividerSC />
      <>
        <Flex
          justify='space-between'
          align='center'
        >
          <Text>Wartość zamówienia: {formatPrice(order.price, order.currency)}</Text>
          <Button
            type='text'
            aria-label='Pokaż więcej'
            onClick={() => showMorePrice(!morePrice)}
            icon={morePrice ? <UpOutlined /> : <DownOutlined />}
          ></Button>
        </Flex>
        {morePrice && (
          <Space direction='vertical'>
            <Text>
              Klient: <Text strong>{order.companyName}</Text>
            </Text>
            <Text>
              Sprzedawca: <Text strong>{order.sellerName}</Text>
            </Text>
            <Text>
              Utworzono: <Text strong>{dayjs(order.createdAt).format('DD-MM-YYYY')}</Text>
            </Text>
            <Text>
              Płatne do: <Text strong>{dayjs(order.paidAt).format('DD-MM-YYYY')}</Text>
            </Text>
            {order.invoice && (
              <Text>
                Nr faktury: <Text strong>{order.invoice}</Text>
              </Text>
            )}
          </Space>
        )}
      </>
    </ListItemSC>
  );
};
