import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { PartBrandModel } from './model';

export type PartsBrandsAction = ActionType<typeof actions>;
export type PartsBrandsState = {
  readonly isFetching: boolean;
  readonly partsBrandsList: PartBrandModel[];
};

export const partsBrandsReducer = combineReducers<PartsBrandsState, PartsBrandsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_PART_BRANDS_REQUEST:
      case CONST.ADD_PART_BRAND_REQUEST:
      case CONST.UPDATE_PART_BRAND_REQUEST:
      case CONST.DELETE_PART_BRAND_REQUEST:
        return true;

      case CONST.GET_PART_BRANDS_SUCCESS:
      case CONST.GET_PART_BRANDS_ERROR:
      case CONST.ADD_PART_BRAND_SUCCESS:
      case CONST.ADD_PART_BRAND_ERROR:
      case CONST.UPDATE_PART_BRAND_SUCCESS:
      case CONST.UPDATE_PART_BRAND_ERROR:
      case CONST.DELETE_PART_BRAND_SUCCESS:
      case CONST.DELETE_PART_BRAND_ERROR:
        return false;

      default:
        return state;
    }
  },
  partsBrandsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_PART_BRANDS_SUCCESS:
        return action.payload.partBrands;

      case CONST.ADD_PART_BRAND_SUCCESS:
        return [action.payload.partBrand, ...state];

      case CONST.UPDATE_PART_BRAND_SUCCESS: {
        const brandIdx = state.findIndex(item => item._id === action.payload.partBrand._id);
        state.splice(brandIdx, 1, action.payload.partBrand);

        return [...state];
      }

      case CONST.DELETE_PART_BRAND_SUCCESS:
        return state.filter(brand => brand._id !== action.payload.partBrandId);

      default:
        return state;
    }
  },
});
