import { useQuery } from 'react-query';
import { apiClientPartBrands } from './index';

const queryKeys = {
  getPartBrands: 'part-brands',
  deletePartBrands: (brandId: string) => `part-brands/${brandId}`,
};

export const useGetPartBrands = () => {
  return useQuery(queryKeys.getPartBrands, async () => {
    const response = await apiClientPartBrands.getPartBrands();
    return response.data;
  });
};
