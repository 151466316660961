import styled from 'styled-components';
import { List } from 'antd';

import { OffersListDateProps } from '../OffersList';

export const ListSC = styled(List<OffersListDateProps>)`
  padding: 16px;

  &.mobileOfferList li.listItem .listItemMeta h4 {
    margin-block-end: 0;
  }
`;

export const ListItemSC = styled(List.Item)`
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
`;
