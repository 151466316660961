export const GET_PART_CATEGORIES_REQUEST = '@partsCategories/GET_PART_CATEGORIES_REQUEST';
export const GET_PART_CATEGORIES_SUCCESS = '@partsCategories/GET_PART_CATEGORIES_SUCCESS';
export const GET_PART_CATEGORIES_ERROR = '@partsCategories/GET_PART_CATEGORIES_ERROR';

export const ADD_PART_CATEGORY_REQUEST = '@partsCategories/ADD_PART_CATEGORY_REQUEST';
export const ADD_PART_CATEGORY_SUCCESS = '@partsCategories/ADD_PART_CATEGORY_SUCCESS';
export const ADD_PART_CATEGORY_ERROR = '@partsCategories/ADD_PART_CATEGORY_ERROR';

export const UPDATE_PART_CATEGORY_REQUEST = '@partsCategories/UPDATE_PART_CATEGORY_REQUEST';
export const UPDATE_PART_CATEGORY_SUCCESS = '@partsCategories/UPDATE_PART_CATEGORY_SUCCESS';
export const UPDATE_PART_CATEGORY_ERROR = '@partsCategories/UPDATE_PART_CATEGORY_ERROR';

export const DELETE_PART_CATEGORY_REQUEST = '@partsCategories/DELETE_PART_CATEGORY_REQUEST';
export const DELETE_PART_CATEGORY_SUCCESS = '@partsCategories/DELETE_PART_CATEGORY_SUCCESS';
export const DELETE_PART_CATEGORY_ERROR = '@partsCategories/DELETE_PART_CATEGORY_ERROR';
