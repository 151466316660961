import React, { FC } from 'react';
import { Table, Typography } from 'antd';
import { AlignType, FixedType } from 'rc-table/lib/interface';
import { ColumnsType } from 'antd/es/table';

import { useFilterAntTable } from '../../../../utils/customHooks/useFilterAntTable';
import { CategoryModel } from '../../../../reducers/categories/model';
import { ProductStatus, ProductStatusNames } from '../../../../reducers/forklift/model';
import { getStatusProductIcon } from '../../../../utils/statusProductIcon';
import { formatPrice } from '../../../../utils/formatPrice';
import { CurrencyType, ProductType } from '../../../../reducers/orders/model';
import { PartModel } from '../../../../reducers/parts/model';
import { useGetPartBrands } from '../../../../services/apiClient/partsBrands/partQueries';
import { useGetPartCategories } from '../../../../services/apiClient/partsCategories/partQueries';

const { Title } = Typography;

export type OrderParts = PartModel & {
  productType: ProductType;
};

type OrdersPartsListProps = {
  partsList: OrderParts[];
  groupEditEquipmentsStatus: string[];
  onSelection: (newSelectedRowKeys: React.Key[]) => void;
};

export const OrdersPartsList: FC<OrdersPartsListProps> = props => {
  const { partsList, groupEditEquipmentsStatus, onSelection } = props;
  const { data: brandsList = [] } = useGetPartBrands();
  const { data: categoriesList = [] } = useGetPartCategories();
  const { getColumnSearchProps, getColumnFilterProps, getColumnSearchRangeProps } = useFilterAntTable();

  const filteredPartsByOrder = partsList.map(part => ({
    ...part,
    brandName: part.brand?.name,
  }));

  const columns: ColumnsType<PartModel> = [
    {
      title: 'Id',
      dataIndex: 'internalId',
      key: 'internalId',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('internalId', 'Id'),
      sorter: (a: PartModel, b: PartModel) => a.internalId.localeCompare(b.internalId),
    },
    {
      title: 'Marka',
      dataIndex: 'brandName',
      key: 'brandName',
      fixed: 'left' as FixedType,
      ...getColumnFilterProps(
        'brandId',
        brandsList.map(brand => ({
          text: brand.name,
          value: brand._id,
        })),
      ),
      render: (brandName?: string) => <>{brandName}</>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left' as FixedType,
      ...getColumnSearchProps('type', 'typu'),
      sorter: (a: PartModel, b: PartModel) => a.type.localeCompare(b.type),
    },
    {
      title: 'Kategoria',
      dataIndex: 'category',
      key: 'category',
      width: 100,
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'categoryId',
        categoriesList.map(category => ({
          text: category.name,
          value: category._id,
        })),
      ),
      render: (category?: CategoryModel) => <>{category?.name}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'status',
        Object.values(ProductStatus).map(status => ({
          value: status,
          text: ProductStatusNames[status],
        })),
      ),
      render: (status: ProductStatus) => getStatusProductIcon(status),
      sorter: (a: PartModel, b: PartModel) => a.status.localeCompare(b.status),
    },
    {
      title: 'Rok',
      dataIndex: 'productionYear',
      key: 'productionYear',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('productionYear', 'rok produkcji'),
      sorter: (a: PartModel, b: PartModel) => a.productionYear - b.productionYear,
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('price', 'ceny'),
      render: (price: number) => formatPrice(price, CurrencyType.PLN),
      sorter: (a: PartModel, b: PartModel) => a.price - b.price,
    },
    {
      title: 'Cena hurt',
      dataIndex: 'priceWholesale',
      key: 'priceWholesale',
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps('priceWholesale', 'ceny'),
      render: (priceWholesale: number) => formatPrice(priceWholesale, CurrencyType.EUR),
      sorter: (a: PartModel, b: PartModel) => a.priceWholesale - b.priceWholesale,
    },
  ];

  if (filteredPartsByOrder.length > 0) {
    return (
      <>
        <Title level={5}>Części</Title>
        <Table
          columns={columns}
          dataSource={filteredPartsByOrder}
          rowKey={record => record._id || ''}
          size='small'
          style={{ paddingTop: '16px' }}
          rowSelection={{
            selectedRowKeys: groupEditEquipmentsStatus,
            onChange: onSelection,
          }}
          pagination={false}
        />
      </>
    );
  }

  return null;
};
