import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Col, Form, Input, Row, Select } from 'antd';

import { initFormik } from './formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { EquipmentInternalId, EquipmentModel } from '../../reducers/equipment/model';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { AssetBucket, AssetModel } from '../../reducers/assets/model';
import { UploadImage } from '../../component/uploadImage/UploadImage';
import { getEquipmentInternalIdRequest, getEquipmentRequest } from '../../reducers/equipment/actions';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';
import { EquipmentBrandModel } from '../../reducers/equipmentBrands/model';
import { EquipmentBrandForm, EquipmentBrandFormValues } from '../equipmentBrands';
import { addEquipmentBrandRequest, getEquipmentBrandsRequest } from '../../reducers/equipmentBrands/actions';
import { addEquipmentCategoryRequest, getEquipmentCategoriesRequest } from '../../reducers/equipmentCategories/actions';
import { EquipmentCategoryModel } from '../../reducers/equipmentCategories/model';
import { EquipmentCategoryForm, EquipmentCategoryFormValues } from '../equipmentCategory';
import { ProductStatus, ProductStatusNames } from '../../reducers/forklift/model';
import { AbilityAction, AbilitySubject } from '../../services/authService/ability';
import { Can } from '../../component/can/can';
import { useViewport } from '../../utils/customHooks/useViewport';

const { Item } = Form;
const { TextArea } = Input;

export const gutterMain = 24;
export const gutterInner = 12;

export type EquipmentFormValues = {
  internalId: string;
  brandId: string;
  categoryId: string;
  status: ProductStatus;
  mounting: string;
  liftingCapacity: number;
  productionYear: number;
  width: number;
  minOperatingRange: number;
  maxOperatingRange: number;
  lengthFork: number;
  price: number;
  priceWholesale: number;
  type: string;
  serialNumber: string;
  assets: AssetModel[];
  accessories?: string;
  notes?: string;
  boughtFrom?: string;
};

export type EquipmentFormProps = {
  equipmentId?: string;
  handleSubmit: (values: EquipmentFormValues) => void;
  handleCancelForm: () => void;
};

export const EquipmentForm: FC<EquipmentFormProps> = props => {
  const { equipmentId, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.equipment.isFetching);
  const equipmentBrandsList = useSelector<RootReducerState, EquipmentBrandModel[]>(
    state => state.equipmentBrands.equipmentBrandsList,
  );
  const equipmentCategoriesList = useSelector<RootReducerState, EquipmentCategoryModel[]>(
    state => state.equipmentCategories.categoriesList,
  );
  const equipment = useSelector<RootReducerState, EquipmentModel | undefined>(
    state => state.equipment.equipmentList.find(item => item._id === equipmentId),
    shallowEqual,
  );
  const equipmentInternalId = useSelector<RootReducerState, EquipmentInternalId | null>(
    state => state.equipment.equipmentInternalId,
  );
  const formik = useFormik(initFormik(props, equipment, equipmentInternalId));

  const [openBrandModal, setOpenBrandModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const { isMobile } = useViewport();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEquipmentBrandsRequest());
    dispatch(getEquipmentCategoriesRequest());
    if (equipmentId) {
      dispatch(getEquipmentRequest(equipmentId));
    } else {
      dispatch(getEquipmentInternalIdRequest());
    }
  }, [dispatch, equipmentId]);

  const handleBrandSubmit = (values: EquipmentBrandFormValues) => {
    dispatch(
      addEquipmentBrandRequest(values, brand => {
        setOpenBrandModal(false);
        void formik.setFieldValue('brandId', brand?._id);
      }),
    );
  };

  const handleCategorySubmit = (values: EquipmentCategoryFormValues) => {
    dispatch(
      addEquipmentCategoryRequest(values, category => {
        setOpenCategoryModal(false);
        void formik.setFieldValue('categoryId', category?._id);
      }),
    );
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Row gutter={gutterMain}>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Id wewnętrzne'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.internalId && formik.touched.internalId ? 'error' : ''}
              help={formik.errors.internalId && formik.touched.internalId ? formik.errors.internalId : null}
            >
              <Input
                disabled={isFetching}
                name='internalId'
                value={formik.values.internalId}
                placeholder='Id wewnętrzne'
                onChange={formik.handleChange}
              />
            </Item>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={16}
                lg={17}
                xl={18}
                xxl={19}
              >
                <Item
                  label='Marka'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.brandId && formik.touched.brandId ? 'error' : ''}
                  help={formik.errors.brandId && formik.touched.brandId ? formik.errors.brandId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.brandId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('brandId', value)}
                    options={equipmentBrandsList.map(brand => ({
                      label: brand.name,
                      value: brand._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={8}
                lg={7}
                xl={6}
                xxl={5}
                className='right'
              >
                <Button onClick={() => setOpenBrandModal(true)}>Dodaj markę</Button>
              </Col>
            </Row>
            <Item
              label='Typ'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.type && formik.touched.type ? 'error' : ''}
              help={formik.errors.type && formik.touched.type ? formik.errors.type : null}
            >
              <Input
                name='type'
                value={formik.values.type}
                placeholder='Typ'
                onChange={formik.handleChange}
              />
            </Item>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={15}
                lg={16}
                xl={17}
                xxl={18}
              >
                <Item
                  label='Kategoria'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.categoryId && formik.touched.categoryId ? 'error' : ''}
                  help={formik.errors.categoryId && formik.touched.categoryId ? formik.errors.categoryId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.categoryId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('categoryId', value)}
                    options={equipmentCategoriesList.map(categoryItem => ({
                      label: categoryItem.name,
                      value: categoryItem._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={9}
                lg={8}
                xl={7}
                xxl={6}
                className='right'
              >
                <Button onClick={() => setOpenCategoryModal(true)}>Dodaj kategorię</Button>
              </Col>
            </Row>
            <Item
              label='Status'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.status && formik.touched.status ? 'error' : ''}
              help={formik.errors.status && formik.touched.status ? formik.errors.status : null}
            >
              <Select
                showSearch
                optionFilterProp='children'
                value={formik.values.status}
                style={{ width: '100%' }}
                onChange={value => formik.setFieldValue('status', value)}
                options={Object.values(ProductStatus).map(status => ({
                  label: ProductStatusNames[status],
                  value: status,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
            <Item
              label='Udźwig'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.liftingCapacity && formik.touched.liftingCapacity ? 'error' : ''}
              help={
                formik.errors.liftingCapacity && formik.touched.liftingCapacity ? formik.errors.liftingCapacity : null
              }
            >
              <Input
                name='liftingCapacity'
                value={formik.values.liftingCapacity}
                placeholder='Udźwig'
                onChange={formik.handleChange}
                addonAfter='kg'
              />
            </Item>
            <Item
              label='Rok produkcji'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.productionYear && formik.touched.productionYear ? 'error' : ''}
              help={formik.errors.productionYear && formik.touched.productionYear ? formik.errors.productionYear : null}
            >
              <Input
                name='productionYear'
                value={formik.values.productionYear}
                placeholder='Rok produkcji'
                onChange={formik.handleChange}
              />
            </Item>
            <Item
              label='Mocowanie'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.mounting && formik.touched.mounting ? 'error' : ''}
              help={formik.errors.mounting && formik.touched.mounting ? formik.errors.mounting : null}
            >
              <Input
                name='mounting'
                value={formik.values.mounting}
                placeholder='Mocowanie'
                onChange={formik.handleChange}
              />
            </Item>
            <Item
              label='Cena'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.price && formik.touched.price ? 'error' : ''}
              help={formik.errors.price && formik.touched.price ? formik.errors.price : null}
            >
              <Input
                name='price'
                value={formik.values.price}
                placeholder='Cena'
                onChange={formik.handleChange}
                addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
              />
            </Item>
            <Item
              label='Cena hurtowa'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.priceWholesale && formik.touched.priceWholesale ? 'error' : ''}
              help={formik.errors.priceWholesale && formik.touched.priceWholesale ? formik.errors.priceWholesale : null}
            >
              <Input
                name='priceWholesale'
                value={formik.values.priceWholesale}
                placeholder='Cena'
                onChange={formik.handleChange}
                addonAfter={CurrencyTypeNames[CurrencyType.EUR]}
              />
            </Item>
          </Col>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Numer seryjny'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.serialNumber && formik.touched.serialNumber ? 'error' : ''}
              help={formik.errors.serialNumber && formik.touched.serialNumber ? formik.errors.serialNumber : null}
            >
              <Input
                name='serialNumber'
                value={formik.values.serialNumber}
                placeholder='Numer seryjny'
                onChange={formik.handleChange}
              />
            </Item>
            <Can
              do={AbilityAction.READ}
              on={AbilitySubject.EQUIPMENT}
            >
              <Item
                label='Kupiony od'
                hasFeedback={true}
                validateStatus={formik.errors.boughtFrom && formik.touched.boughtFrom ? 'error' : ''}
                help={formik.errors.boughtFrom && formik.touched.boughtFrom ? formik.errors.boughtFrom : null}
              >
                <Input
                  name='boughtFrom'
                  value={formik.values.boughtFrom}
                  placeholder='Kupiony od'
                  onChange={formik.handleChange}
                />
              </Item>
            </Can>
            <Item
              label='Szerokość'
              hasFeedback={true}
              validateStatus={formik.errors.width && formik.touched.width ? 'error' : ''}
              help={formik.errors.width && formik.touched.width ? formik.errors.width : null}
            >
              <Input
                name='width'
                value={formik.values.width}
                placeholder='Szerokość'
                onChange={formik.handleChange}
                addonAfter='mm'
              />
            </Item>
            <Row
              justify='space-between'
              align='top'
              gutter={gutterInner}
            >
              <Col span={12}>
                <Item
                  label='Minimalny zakres pracy'
                  hasFeedback={true}
                  validateStatus={formik.errors.minOperatingRange && formik.touched.minOperatingRange ? 'error' : ''}
                  help={
                    formik.errors.minOperatingRange && formik.touched.minOperatingRange
                      ? formik.errors.minOperatingRange
                      : null
                  }
                >
                  <Input
                    name='minOperatingRange'
                    value={formik.values.minOperatingRange}
                    placeholder='Minimalny zakres pracy'
                    onChange={formik.handleChange}
                    addonAfter='mm'
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label='Maksymalny zakres pracy'
                  hasFeedback={true}
                  validateStatus={formik.errors.maxOperatingRange && formik.touched.maxOperatingRange ? 'error' : ''}
                  help={
                    formik.errors.maxOperatingRange && formik.touched.maxOperatingRange
                      ? formik.errors.maxOperatingRange
                      : null
                  }
                >
                  <Input
                    name='maxOperatingRange'
                    value={formik.values.maxOperatingRange}
                    placeholder='Maksymalny zakres pracy'
                    onChange={formik.handleChange}
                    addonAfter='mm'
                  />
                </Item>
              </Col>
            </Row>

            <Item
              label='Widły/łapy'
              hasFeedback={true}
              validateStatus={formik.errors.lengthFork && formik.touched.lengthFork ? 'error' : ''}
              help={formik.errors.lengthFork && formik.touched.lengthFork ? formik.errors.lengthFork : null}
            >
              <Input
                name='lengthFork'
                value={formik.values.lengthFork}
                placeholder='Widły/łapy'
                onChange={formik.handleChange}
                addonAfter='mm'
              />
            </Item>

            <Item
              label='Uwagi'
              hasFeedback={true}
              validateStatus={formik.errors.notes && formik.touched.notes ? 'error' : ''}
              help={formik.errors.notes && formik.touched.notes ? formik.errors.notes : null}
            >
              <TextArea
                name='notes'
                showCount
                maxLength={1000}
                onChange={formik.handleChange}
                placeholder='Uwagi'
                value={formik.values.notes}
                rows={6}
              />
            </Item>
            <Item
              label='Zdjęcia'
              hasFeedback={true}
              validateStatus={formik.errors.assets && formik.touched.assets ? 'error' : ''}
              help={formik.errors.assets && formik.touched.assets ? formik.errors.assets : null}
            >
              <UploadImage
                assets={formik.values.assets}
                bucket={AssetBucket.EQUIPMENT}
                multiple={true}
                onChange={assets => formik.setFieldValue('assets', assets)}
              />
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Button onClick={handleCancelForm}>Anuluj</Button>
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching}
            onClick={formik.submitForm}
          >
            {equipment ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Row>
      </Form>
      <DrawerAddEdit
        titleEdit='Edytuj markę'
        titleAdd='Dodaj markę'
        openModal={openBrandModal}
        handleCloseModal={() => setOpenBrandModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <EquipmentBrandForm
          handleSubmit={handleBrandSubmit}
          handleCancelForm={() => setOpenBrandModal(false)}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Edytuj kategorię'
        titleAdd='Dodaj kategorię'
        openModal={openCategoryModal}
        handleCloseModal={() => setOpenCategoryModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <EquipmentCategoryForm
          handleSubmit={handleCategorySubmit}
          handleCancelForm={() => setOpenCategoryModal(false)}
        />
      </DrawerAddEdit>
    </div>
  );
};
