import React, { useState } from 'react';

import { LayoutMain } from '../../layout/main/Main';
import { OffersList } from '../../component/offers/OffersList';
import { OffersHeader } from './OffersHeader';
import { useViewport } from '../../utils/customHooks/useViewport';
import { OfferMobileList } from '../../component/offers/mobile/OfferMobileList';

export const OffersPage = () => {
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const { isMobile } = useViewport();

  return (
    <LayoutMain>
      <OffersHeader onSetOpenOfferModal={openOfferModal => setOpenOfferModal(openOfferModal)} />
      {isMobile ? (
        <OfferMobileList
          openOfferModal={openOfferModal}
          clickOpenOfferModal={openOfferModal => setOpenOfferModal(openOfferModal)}
        />
      ) : (
        <OffersList
          openOfferModal={openOfferModal}
          clickOpenOfferModal={openOfferModal => setOpenOfferModal(openOfferModal)}
        />
      )}
    </LayoutMain>
  );
};
