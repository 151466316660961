import React, { useState, FC } from 'react';
import { Button, Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Logo } from '../../component/logo/Logo';
import { MenuComponent } from '../../component/menu/Menu';
import { useViewport } from '../../utils/customHooks/useViewport';
import { ContentSC, SiderSC } from './styles';

export const LayoutMain: FC = ({ children }) => {
  const { isMobile } = useViewport();
  const [collapsed, setCollapsed] = useState(isMobile);

  return (
    <Layout hasSider>
      <SiderSC
        trigger={null}
        collapsible
        collapsed={collapsed}
        width='300px'
        className='siderWrapper'
        collapsedWidth={isMobile ? 50 : 80}
      >
        <Logo collapsed={collapsed} />
        <Button
          className='menuButton'
          type='primary'
          shape='circle'
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <MenuComponent collapsed={collapsed} />
      </SiderSC>
      <ContentSC>{children}</ContentSC>
    </Layout>
  );
};
