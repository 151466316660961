import { useQuery } from 'react-query';
import { apiClientOffers } from './index';

const queryKeys = {
  getOffer: (id: string) => `offers/${id}`,
  getOffers: 'offers',
};

export const useGetOffer = (id?: string) => {
  return useQuery(queryKeys.getOffer(id as string), async () => {
    const response = await apiClientOffers.getOffer(id as string);
    return response.data;
  });
};

export const useGetOffers = () => {
  return useQuery(queryKeys.getOffers, async () => {
    const response = await apiClientOffers.getOffers();
    return response.data;
  });
};
