import { useQuery } from 'react-query';
import { apiClientOrders } from './index';

const queryKeys = {
  getOrder: (id: string) => `orders/${id}`,
  getOrders: 'orders',
};

export const useGetOrder = (id?: string) => {
  return useQuery(
    queryKeys.getOrder(id as string),
    async () => {
      const response = await apiClientOrders.getOrder(id as string);
      return response.data;
    },
    { enabled: !!id },
  );
};

export const useGetOrders = () => {
  return useQuery(queryKeys.getOrders, async () => {
    const response = await apiClientOrders.getOrders();
    return response.data;
  });
};
