import { action } from 'typesafe-actions';
import * as CONST from './consts';
import { PartBrandModel } from './model';

export const getPartBrandsRequest = () => action(CONST.GET_PART_BRANDS_REQUEST);
export const getPartBrandsSuccess = (partBrands: PartBrandModel[]) =>
  action(CONST.GET_PART_BRANDS_SUCCESS, { partBrands });
export const getPartBrandsError = (error: unknown) => action(CONST.GET_PART_BRANDS_ERROR, { error });

export const addPartBrandRequest = (
  partBrand: Omit<PartBrandModel, '_id' | 'createdAt' | 'updatedAt'>,
  onSuccess?: (brand?: PartBrandModel) => void,
) => action(CONST.ADD_PART_BRAND_REQUEST, { partBrand, onSuccess });
export const addPartBrandSuccess = (partBrand: PartBrandModel) => action(CONST.ADD_PART_BRAND_SUCCESS, { partBrand });
export const addPartBrandError = (error: unknown) => action(CONST.ADD_PART_BRAND_ERROR, { error });

export const updatePartBrandRequest = (partBrand: PartBrandModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_PART_BRAND_REQUEST, { partBrand, onSuccess });
export const updatePartBrandSuccess = (partBrand: PartBrandModel) =>
  action(CONST.UPDATE_PART_BRAND_SUCCESS, { partBrand });
export const updatePartBrandError = (error: unknown) => action(CONST.UPDATE_PART_BRAND_ERROR, { error });

export const deletePartBrandRequest = (partBrandId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_PART_BRAND_REQUEST, { partBrandId, onSuccess });
export const deletePartBrandSuccess = (partBrandId: string) => action(CONST.DELETE_PART_BRAND_SUCCESS, { partBrandId });
export const deletePartBrandError = (error: unknown) => action(CONST.DELETE_PART_BRAND_ERROR, { error });
