import React, { FC } from 'react';
import { Col, Image, List, Row } from 'antd';

import { ProductStatusNames } from '../../reducers/forklift/model';
import { gutterInner } from '../../forms/forklift';
import { displayPrice } from '../../utils/displayPrice';
import { GeneralSettingModel } from '../../reducers/settings/model';
import { AssetTextSC } from '../common/preview/AssetTextSC';
import { PreviewListSC } from '../common/preview/PreviewListSC';
import { RowGallerySC } from '../common/preview/RowGallerySC';
import { useGetSettings } from '../../services/apiClient/settings/settingsQueries';
import { useGetEquipment } from '../../services/apiClient/equipments/equipmentQueries';

type EquipmentPreviewProps = {
  equipmentId: string;
};

export const EquipmentPreview: FC<EquipmentPreviewProps> = props => {
  const { equipmentId } = props;

  const { data: equipment, isFetching } = useGetEquipment(equipmentId);
  const { data: settingsList } = useGetSettings();

  let euroExchange: GeneralSettingModel | undefined;
  if (settingsList) {
    euroExchange = settingsList.find(item => item.key === 'EUR-TO-PLN');
  }

  return (
    <>
      <Row gutter={gutterInner}>
        <Col
          xs={24}
          md={12}
        >
          <PreviewListSC
            className='previewList quickViewList'
            size='small'
            loading={isFetching}
          >
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Marka:'
                description={equipment?.brand?.name}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Model:'
                description={equipment?.type}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Numer seryjny:'
                description={equipment?.serialNumber}
              />
            </List.Item>
            {equipment?.boughtFrom && (
              <List.Item className='quickViewListElement'>
                <List.Item.Meta
                  className='listItemMeta'
                  title='Kupione od:'
                  description={equipment.boughtFrom}
                />
              </List.Item>
            )}
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Kategoria:'
                description={equipment?.category?.name}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Status:'
                description={equipment && ProductStatusNames[equipment.status]}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Udźwig:'
                description={`${equipment?.liftingCapacity ?? ''} kg`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Rok produkcji:'
                description={equipment?.productionYear}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Cena:'
                description={`${equipment?.price ? displayPrice(equipment.price) : 0} PLN`}
              />
              <div>{`(${displayPrice(
                equipment?.price && euroExchange?.value ? equipment.price / +euroExchange.value : 0,
              )} EUR)`}</div>
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Cena hurtowa:'
                description={`${equipment?.priceWholesale ? displayPrice(equipment.priceWholesale) : 0} EUR`}
              />
              <div>{`(${displayPrice(
                equipment?.priceWholesale && euroExchange?.value ? equipment.priceWholesale * +euroExchange.value : 0,
              )} PLN)`}</div>
            </List.Item>
          </PreviewListSC>
        </Col>
        <Col
          xs={24}
          md={12}
        >
          <PreviewListSC
            className='previewList quickViewList'
            size='small'
            loading={isFetching}
          >
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Mocowanie:'
                description={equipment?.mounting}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Szerokość:'
                description={`${equipment?.width ?? ''} mm`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Minimalny zakres pracy:'
                description={`${equipment?.minOperatingRange ?? ''} mm`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Maksymalny zakres pracy:'
                description={`${equipment?.maxOperatingRange ?? ''} mm`}
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Widły/Łapy:'
                description={equipment?.lengthFork}
                className='listItemMeta breakLine'
              />
            </List.Item>
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                title='Uwagi:'
                description={equipment?.notes}
                className='listItemMeta breakLine'
              />
            </List.Item>
          </PreviewListSC>
          {equipment?.assets && (
            <Row gutter={gutterInner}>
              <Col span={24}>
                <Row>
                  <AssetTextSC>Zdjęcia:</AssetTextSC>
                </Row>
                <RowGallerySC>
                  <Image.PreviewGroup>
                    {equipment.assets.map(image => (
                      <div
                        key={image._id}
                        className='imageWrapper'
                      >
                        <Image
                          className='imageGalleryElement'
                          src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${image.uri}`}
                        />
                      </div>
                    ))}
                  </Image.PreviewGroup>
                </RowGallerySC>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};
