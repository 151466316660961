import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Col, Form, Input, Row, Select } from 'antd';

import { initFormik } from './formik';
import { RootReducerState } from '../../reducers';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { getProductCostRequest } from '../../reducers/costs/actions';
import { AssetBucket, AssetModel } from '../../reducers/assets/model';
import { UploadImage } from '../../component/uploadImage/UploadImage';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';
import { useViewport } from '../../utils/customHooks/useViewport';
import { PartBrandModel } from '../../reducers/partsBrands/model';
import { PartCategoryModel } from '../../reducers/partsCategories/model';
import { PartInternalId } from '../../reducers/parts/model';
import { PartCategoryForm, PartCategoryFormValues } from '../partCategory';
import { PartBrandForm, PartBrandFormValues } from '../partBrand';
import { addPartBrandRequest, getPartBrandsRequest } from '../../reducers/partsBrands/actions';
import { addPartCategoryRequest, getPartsCategoriesRequest } from '../../reducers/partsCategories/actions';
import { useGetPart } from '../../services/apiClient/parts/partQueries';
import { getPartInternalIdRequest } from '../../reducers/parts/actions';
import { ProductStatus, ProductStatusNames } from '../../reducers/forklift/model';

const { Item } = Form;
const { TextArea } = Input;

export const gutterMain = 24;
export const gutterInner = 12;

export type PartFormValues = {
  internalId: string;
  name?: string;
  brandId: string;
  categoryId: string;
  status: ProductStatus;
  productionYear: number;
  price: number;
  type: string;
  assets: AssetModel[];
  notes: string;
  priceWholesale: number;
};

export type PartFormProps = {
  partId?: string;
  handleSubmit: (values: PartFormValues, onAddSimilar: () => void) => void;
  handleCancelForm: () => void;
};

export const PartForm: FC<PartFormProps> = props => {
  const { partId, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.parts.isFetching);
  const brandList = useSelector<RootReducerState, PartBrandModel[]>(state => state.partsBrands.partsBrandsList);
  const categoriesList = useSelector<RootReducerState, PartCategoryModel[]>(
    state => state.partsCategories.categoriesList,
  );
  const { data: part } = useGetPart(partId);
  const partInternalId = useSelector<RootReducerState, PartInternalId | null>(state => state.parts.partInternalId);
  const formik = useFormik(initFormik(props, part));

  const [openBrandModal, setOpenBrandModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const { isMobile } = useViewport();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartBrandsRequest());
    dispatch(getPartsCategoriesRequest());
    if (partId) {
      dispatch(getProductCostRequest(partId));
    } else {
      dispatch(getPartInternalIdRequest());
    }
  }, [dispatch, partId]);

  useEffect(() => {
    if (!part?.internalId && partInternalId?.nextId) {
      void formik.setFieldValue('internalId', partInternalId.nextId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partInternalId]);

  const handleBrandSubmit = (values: PartBrandFormValues) => {
    dispatch(
      addPartBrandRequest(values, brand => {
        setOpenBrandModal(false);
        void formik.setFieldValue('brandId', brand?._id);
      }),
    );
  };

  const handleCategorySubmit = (values: PartCategoryFormValues) => {
    dispatch(
      addPartCategoryRequest(values, category => {
        setOpenCategoryModal(false);
        void formik.setFieldValue('categoryId', category?._id);
      }),
    );
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Row gutter={gutterMain}>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Id wewnętrzne'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.internalId && formik.touched.internalId ? 'error' : ''}
              help={formik.errors.internalId && formik.touched.internalId ? formik.errors.internalId : null}
            >
              <Input
                disabled={isFetching}
                name='internalId'
                value={formik.values.internalId}
                placeholder='Id wewnętrzne'
                onChange={formik.handleChange}
              />
            </Item>
            <Item
              label='Nazwa'
              required={false}
              hasFeedback={true}
              validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
              help={formik.errors.name && formik.touched.name ? formik.errors.name : null}
            >
              <Input
                disabled={isFetching}
                name='name'
                value={formik.values.name}
                placeholder='Nazwa'
                onChange={formik.handleChange}
              />
            </Item>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={16}
                lg={17}
                xl={18}
                xxl={19}
              >
                <Item
                  label='Marka'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.brandId && formik.touched.brandId ? 'error' : ''}
                  help={formik.errors.brandId && formik.touched.brandId ? formik.errors.brandId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.brandId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('brandId', value)}
                    options={brandList.map(brand => ({
                      label: brand.name,
                      value: brand._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={8}
                lg={7}
                xl={6}
                xxl={5}
                className='right'
              >
                <Button onClick={() => setOpenBrandModal(true)}>Dodaj markę</Button>
              </Col>
            </Row>
            <Item
              label='Typ'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.type && formik.touched.type ? 'error' : ''}
              help={formik.errors.type && formik.touched.type ? formik.errors.type : null}
            >
              <Input
                name='type'
                value={formik.values.type}
                placeholder='Typ'
                onChange={formik.handleChange}
              />
            </Item>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={15}
                lg={16}
                xl={17}
                xxl={18}
              >
                <Item
                  label='Kategoria'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.categoryId && formik.touched.categoryId ? 'error' : ''}
                  help={formik.errors.categoryId && formik.touched.categoryId ? formik.errors.categoryId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.categoryId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('categoryId', value)}
                    options={categoriesList.map(categoryItem => ({
                      label: categoryItem.name,
                      value: categoryItem._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={9}
                lg={8}
                xl={7}
                xxl={6}
                className='right'
              >
                <Button onClick={() => setOpenCategoryModal(true)}>Dodaj kategorię</Button>
              </Col>
            </Row>
            <Item
              label='Status'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.status && formik.touched.status ? 'error' : ''}
              help={formik.errors.status && formik.touched.status ? formik.errors.status : null}
            >
              <Select
                showSearch
                optionFilterProp='children'
                value={formik.values.status}
                style={{ width: '100%' }}
                onChange={value => formik.setFieldValue('status', value)}
                options={Object.values(ProductStatus).map(status => ({
                  label: ProductStatusNames[status],
                  value: status,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
            <Item
              label='Rok produkcji'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.productionYear && formik.touched.productionYear ? 'error' : ''}
              help={formik.errors.productionYear && formik.touched.productionYear ? formik.errors.productionYear : null}
            >
              <Input
                name='productionYear'
                value={formik.values.productionYear}
                placeholder='Rok produkcji'
                onChange={formik.handleChange}
              />
            </Item>
            <Item
              label='Cena'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.price && formik.touched.price ? 'error' : ''}
              help={formik.errors.price && formik.touched.price ? formik.errors.price : null}
            >
              <Input
                name='price'
                value={formik.values.price}
                placeholder='Cena'
                onChange={formik.handleChange}
                addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
              />
            </Item>
            <Item
              label='Cena hurtowa'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.priceWholesale && formik.touched.priceWholesale ? 'error' : ''}
              help={formik.errors.priceWholesale && formik.touched.priceWholesale ? formik.errors.priceWholesale : null}
            >
              <Input
                name='priceWholesale'
                value={formik.values.priceWholesale}
                placeholder='Cena hurtowa'
                onChange={formik.handleChange}
                addonAfter={CurrencyTypeNames[CurrencyType.EUR]}
              />
            </Item>
          </Col>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Uwagi'
              hasFeedback={true}
              validateStatus={formik.errors.notes && formik.touched.notes ? 'error' : ''}
              help={formik.errors.notes && formik.touched.notes ? formik.errors.notes : null}
            >
              <TextArea
                name='notes'
                showCount
                maxLength={1000}
                onChange={formik.handleChange}
                placeholder='Uwagi'
                value={formik.values.notes}
                rows={6}
              />
            </Item>
            <Item
              label='Zdjęcia'
              hasFeedback={true}
              validateStatus={formik.errors.assets && formik.touched.assets ? 'error' : ''}
              help={formik.errors.assets && formik.touched.assets ? formik.errors.assets : null}
            >
              <UploadImage
                assets={formik.values.assets}
                bucket={AssetBucket.PART}
                multiple={true}
                onChange={assets => formik.setFieldValue('assets', assets)}
              />
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Button onClick={handleCancelForm}>Anuluj</Button>
          </Col>
          <Col>
            <Row justify='space-between'>
              <Col>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isFetching}
                  onClick={formik.submitForm}
                >
                  {part ? 'Zapisz' : 'Dodaj'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <DrawerAddEdit
        titleEdit='Edytuj markę'
        titleAdd='Dodaj markę'
        openModal={openBrandModal}
        handleCloseModal={() => setOpenBrandModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <PartBrandForm
          handleSubmit={handleBrandSubmit}
          handleCancelForm={() => setOpenBrandModal(false)}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Edytuj kategorię'
        titleAdd='Dodaj kategorię'
        openModal={openCategoryModal}
        handleCloseModal={() => setOpenCategoryModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <PartCategoryForm
          handleSubmit={handleCategorySubmit}
          handleCancelForm={() => setOpenCategoryModal(false)}
        />
      </DrawerAddEdit>
    </div>
  );
};
