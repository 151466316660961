import { FormikConfig } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { PartFormProps, PartFormValues } from '.';
import { PartModel } from '../../reducers/parts/model';
import { ProductStatus } from '../../reducers/forklift/model';

export const initFormik = (props: PartFormProps, part: PartModel | undefined): FormikConfig<PartFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    internalId: part?.internalId ?? '',
    name: part?.name ?? '',
    brandId: part?.brandId ?? '',
    categoryId: part?.categoryId ?? '',
    productionYear: part?.productionYear ?? dayjs().get('year'),
    price: part?.price ?? 0,
    type: part?.type ?? '',
    status: part?.status ?? ProductStatus.MAGAZINE,
    assets: part?.assets ?? [],
    notes: part?.notes ?? '',
    priceWholesale: part?.priceWholesale ?? 0,
  },
  validationSchema: () =>
    yup.object({
      brandId: yup.string().required('Marka jest wymagana'),
      categoryId: yup.string().required('Kategoria jest wymagana'),
      productionYear: yup.string().required('Rok produkcji jest wymagany'),
      type: yup.string().required('Typ jest wymagany'),
      price: yup.number().positive('Cena musi być większa od zera').required('Cena jest wymagana'),
      priceWholesale: yup.number().positive('Cena musi być większa od zera').required('Cena jest wymagana'),
      status: yup.string().required('Status jest wymagany'),
    }),
  onSubmit: (values, { setFieldValue }) => {
    props.handleSubmit(values, () => {
      void setFieldValue('assets', []);
    });
  },
});
