import { useQuery } from 'react-query';
import { apiClientParts } from './index';

const queryKeys = {
  getParts: 'parts',
  getPart: (id: string) => `parts/${id}`,
  getAvailableParts: 'parts/available',
};

export const useGetParts = () => {
  return useQuery(queryKeys.getParts, async () => {
    const response = await apiClientParts.getParts();
    return response.data;
  });
};

export const useGetPart = (id?: string) => {
  return useQuery(
    queryKeys.getPart(id as string),
    async () => {
      const response = await apiClientParts.getPart(id as string);
      return response.data;
    },
    { enabled: !!id },
  );
};

export const useGetAvailableParts = () => {
  return useQuery(
    queryKeys.getAvailableParts,
    async () => {
      const response = await apiClientParts.getAvailableParts();
      return response.data;
    },
    { initialData: [] },
  );
};
