import { Dispatch } from 'react';
import { ProductStatus, ProductStatusNames } from '../reducers/forklift/model';
import { updateForkliftsStatusesRequest } from '../reducers/forklift/actions';
import { updateEquipmentsStatusesRequest } from '../reducers/equipment/actions';
import { updatePartsStatusesRequest } from '../reducers/parts/actions';

export type ItemStatus = {
  label: string;
  key: ProductStatus | string;
  onClick: () => void;
  disabled?: boolean;
};

export const itemsStatuses = (
  dispatch: Dispatch<any>,
  groupEditForklifts?: string[],
  groupEditEquipments?: string[],
  groupEditParts?: string[],
): ItemStatus[] => {
  return Object.values(ProductStatus).map(status => ({
    label: ProductStatusNames[status],
    key: status,
    onClick: () => {
      if (groupEditForklifts?.length) {
        dispatch(
          updateForkliftsStatusesRequest({
            ids: groupEditForklifts,
            status: status,
          }),
        );
      }
      if (groupEditEquipments?.length) {
        dispatch(
          updateEquipmentsStatusesRequest({
            ids: groupEditEquipments,
            status: status,
          }),
        );
      }
      if (groupEditParts?.length) {
        dispatch(
          updatePartsStatusesRequest({
            ids: groupEditParts,
            status: status,
          }),
        );
      }
    },
  }));
};
