import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const ProductGrossPriceSC = styled(Text)`
  display: block;
  font-size: 12px;
  margin-top: -3px;
  text-align: right;
`;
