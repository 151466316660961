import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { OffersListDateProps } from '../OffersList';
import { OfferMobileItem } from './OfferMobileItem';
import { OfferModel } from '../../../reducers/offers/model';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import { OfferForm, OfferFormValues } from '../../../forms/offers';
import { deleteOrderRequest } from '../../../reducers/orders/actions';
import {
  addOfferRequest,
  getOfferPDFRequest,
  getOffersRequest,
  updateOfferRequest,
} from '../../../reducers/offers/actions';

import { ListSC } from './styles';
import { RootReducerState } from '../../../reducers';
import { OfferPreview } from '../OfferPreview';

type OfferMobileListProps = {
  openOfferModal: boolean;
  clickOpenOfferModal: (openOfferModal: boolean) => void;
};

export const OfferMobileList: FC<OfferMobileListProps> = props => {
  const { openOfferModal, clickOpenOfferModal } = props;
  const offersList = useSelector<RootReducerState, OfferModel[]>(state => state.offers.offersList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.offers.isFetching);
  const [selectedOffer, setSelectedOffer] = useState<OfferModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [openOfferDeleteModal, setOpenOfferDeleteModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOffersRequest());
  }, [dispatch]);

  const offersListDate: OffersListDateProps[] = useMemo(() => {
    return offersList.map(offer => {
      return {
        ...offer,
        companyName: offer.client?.companyName ?? '',
        sellerName: `${offer.owner?.name ?? ''} ${offer.owner?.surname ?? ''}`,
        productsInOrderResult: offer.productsInOffer.length,
      };
    });
  }, [offersList]);

  const handleCloseOfferModal = () => {
    setSelectedOffer(undefined);
    clickOpenOfferModal(false);
  };

  const handleConfirmOfferModal = () => {
    if (selectedOffer) {
      dispatch(
        deleteOrderRequest(selectedOffer._id, () => {
          setOpenOfferDeleteModal(false);
        }),
      );
    }
  };

  const handleCloseQuickViewModal = () => {
    setSelectedOffer(undefined);
    setOpenQuickViewModal(false);
  };

  const handleSubmit = (values: OfferFormValues) => {
    if (selectedOffer) {
      const offerValue = {
        _id: selectedOffer._id,
        clientId: selectedOffer.clientId,
        createdAt: selectedOffer.createdAt,
        updatedAt: selectedOffer.updatedAt,
        currency: values.currency,
        expiredAt: values.expiredAt,
        notes: values.notes,
        offerNr: values.offerNr,
        productsInOffer: values.productsInOffer,
      };
      dispatch(
        updateOfferRequest(
          {
            ...values,
            ...offerValue,
          },
          () => {
            dispatch(getOfferPDFRequest(selectedOffer._id));
            clickOpenOfferModal(false);
            setSelectedOffer(undefined);
          },
        ),
      );
    } else {
      dispatch(
        addOfferRequest({ ...values }, offerId => {
          dispatch(getOfferPDFRequest(offerId));
          clickOpenOfferModal(false);
        }),
      );
    }
  };

  return (
    <>
      <ListSC
        itemLayout='vertical'
        size='small'
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        loading={isFetching}
        dataSource={offersListDate}
        className='mobileOfferList'
        renderItem={offer => (
          <OfferMobileItem
            offer={offer}
            clickOpenOfferModal={clickOpenOfferModal}
            setSelectedOffer={setSelectedOffer}
            setOpenQuickViewModal={setOpenQuickViewModal}
            setOpenOfferDeleteModal={setOpenOfferDeleteModal}
          />
        )}
      />
      <DrawerAddEdit
        titleEdit='Edytuj ofertę'
        titleAdd='Dodaj ofertę'
        openModal={openOfferModal}
        handleCloseModal={handleCloseOfferModal}
        selectedItem={selectedOffer}
        width='100%'
      >
        <OfferForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseOfferModal}
          offer={selectedOffer}
        />
      </DrawerAddEdit>
      {selectedOffer && (
        <DrawerAddEdit
          titleEdit={`Podgląd oferty: ${selectedOffer._id}`}
          titleAdd={`Podgląd oferty: ${selectedOffer._id}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedOffer}
          width='100%'
        >
          <OfferPreview offerId={selectedOffer._id} />
        </DrawerAddEdit>
      )}
      <Modal
        title='Usuwanie oferty'
        open={openOfferDeleteModal}
        cancelText='Anuluj'
        okText='Tak'
        onCancel={() => setOpenOfferDeleteModal(false)}
        onOk={handleConfirmOfferModal}
      >
        Czy na pewno chcesz usunąć ofertę?
      </Modal>
    </>
  );
};
