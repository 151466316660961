import { OrderModel } from '../../../reducers/orders/model';
import React, { FC, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { OrderMobileItem } from './OrderMobileItem';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import { OrderPreview } from '../OrderPreview';
import { OrderForm, OrderFormValues } from '../../../forms/orders';
import { useDispatch } from 'react-redux';
import { addOrderRequest, deleteOrderRequest, updateOrderRequest } from '../../../reducers/orders/actions';
import { getOrderDate } from '../utils';
import { ListSC } from './styles';

type OrderMobileListProps = {
  ordersList: OrderModel[];
  isFetchingList: boolean;
  clickOpenOrderModal: (openOrderModal: boolean) => void;
  openOrderModal: boolean;
};

export const OrderMobileList: FC<OrderMobileListProps> = props => {
  const { ordersList, isFetchingList, clickOpenOrderModal, openOrderModal } = props;
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [openOrderDeleteModal, setOpenOrderDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const ordersListData = useMemo(() => getOrderDate(ordersList), [ordersList]);
  const handleCloseOrderModal = () => {
    setSelectedOrder(undefined);
    clickOpenOrderModal(false);
  };

  const handleSubmit = (values: OrderFormValues) => {
    if (selectedOrder) {
      const offerValues = {
        _id: selectedOrder._id,
        createdAt: selectedOrder.createdAt,
        deleted: selectedOrder.deleted,
        updatedAt: selectedOrder.updatedAt,
        orderNr: selectedOrder.orderNr,
        clientId: selectedOrder.clientId,
        status: selectedOrder.status,
        productsInOrder: selectedOrder.productsInOrder,
        paidAt: selectedOrder.paidAt,
        notes: selectedOrder.notes,
        clientType: selectedOrder.clientType,
        transport: selectedOrder.transport,
        transportAddress: selectedOrder.transportAddress,
        currency: selectedOrder.currency,
        invoice: selectedOrder.invoice,
        owner: selectedOrder.owner,
      };
      dispatch(
        updateOrderRequest(
          {
            ...offerValues,
            ...values,
          },
          () => {
            setSelectedOrder(undefined);
            clickOpenOrderModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addOrderRequest(
          {
            ...values,
          },
          () => {
            setSelectedOrder(undefined);
            clickOpenOrderModal(false);
          },
        ),
      );
    }
  };

  const handleCloseQuickViewModal = () => {
    setSelectedOrder(undefined);
    setOpenQuickViewModal(false);
  };

  const handleConfirmOrderModal = () => {
    if (selectedOrder) {
      dispatch(
        deleteOrderRequest(selectedOrder._id, () => {
          setOpenOrderDeleteModal(false);
        }),
      );
    }
  };

  return (
    <>
      <ListSC
        itemLayout='vertical'
        size='small'
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        loading={isFetchingList}
        dataSource={getOrderDate(ordersListData)}
        className='mobileOrderList'
        renderItem={order => (
          <OrderMobileItem
            order={order}
            clickOpenOrderModal={clickOpenOrderModal}
            setSelectedOrder={setSelectedOrder}
            setOpenQuickViewModal={setOpenQuickViewModal}
            setOpenOrderDeleteModal={setOpenOrderDeleteModal}
          />
        )}
      />
      <DrawerAddEdit
        titleEdit='Edytuj zamówienia'
        titleAdd='Dodaj zamówienie'
        openModal={openOrderModal}
        handleCloseModal={handleCloseOrderModal}
        selectedItem={selectedOrder}
        width='100%'
      >
        <OrderForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseOrderModal}
          order={selectedOrder}
        />
      </DrawerAddEdit>
      {selectedOrder && (
        <DrawerAddEdit
          titleEdit={`Podgląd zamówienia: ${selectedOrder._id}`}
          titleAdd={`Podgląd zamówienia: ${selectedOrder._id}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedOrder}
          width='100%'
        >
          <OrderPreview orderId={selectedOrder._id} />
        </DrawerAddEdit>
      )}
      <Modal
        title='Usuwanie zamówienia'
        open={openOrderDeleteModal}
        cancelText='Anuluj'
        okText='Tak'
        onCancel={() => setOpenOrderDeleteModal(false)}
        onOk={handleConfirmOrderModal}
      >
        Czy na pewno chcesz usunąć zamówienie?
      </Modal>
    </>
  );
};
