import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { PartInternalId, PartModel, PartStatusesModel } from './model';

export const getPartsRequest = () => action(CONST.GET_PARTS_REQUEST);
export const getPartsSuccess = (parts: PartModel[]) => action(CONST.GET_PARTS_SUCCESS, { parts });
export const getPartsError = (error: unknown) => action(CONST.GET_PARTS_ERROR, { error });

export const getAvailablePartsRequest = () => action(CONST.GET_AVAILABLE_PARTS_REQUEST);
export const getAvailablePartsSuccess = (parts: PartModel[]) => action(CONST.GET_AVAILABLE_PARTS_SUCCESS, { parts });
export const getAvailablePartsError = (error: unknown) => action(CONST.GET_AVAILABLE_PARTS_ERROR, { error });

export const addPartRequest = (
  part: Omit<PartModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'order'>,
  onSuccess?: () => void,
) => action(CONST.ADD_PART_REQUEST, { part, onSuccess });
export const addPartSuccess = (part: PartModel) => action(CONST.ADD_PART_SUCCESS, { part });
export const addPartError = (error: unknown) => action(CONST.ADD_PART_ERROR, { error });

export const updatePartRequest = (part: PartModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_PART_REQUEST, { part, onSuccess });
export const updatePartSuccess = (part: PartModel) => action(CONST.UPDATE_PART_SUCCESS, { part });
export const updatePartError = (error: unknown) => action(CONST.UPDATE_PART_ERROR, { error });

export const deletePartRequest = (partId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_PART_REQUEST, { partId, onSuccess });
export const deletePartSuccess = (partId: string) => action(CONST.DELETE_PART_SUCCESS, { partId });
export const deletePartError = (error: unknown) => action(CONST.DELETE_PART_ERROR, { error });

export const updatePartsStatusesRequest = (partsStatuses: PartStatusesModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_PARTS_STATUSES_REQUEST, { partsStatuses, onSuccess });
export const updatePartsStatusesSuccess = (parts: PartModel[]) =>
  action(CONST.UPDATE_PARTS_STATUSES_SUCCESS, { parts });
export const updatePartsStatusesError = (error: unknown) => action(CONST.UPDATE_PARTS_STATUSES_ERROR, { error });

export const getPartRequest = (partId: string) => action(CONST.GET_PART_REQUEST, { partId });
export const getPartSuccess = (part: PartModel) => action(CONST.GET_PART_SUCCESS, { part });
export const getPartError = (error: unknown) => action(CONST.GET_PART_ERROR, { error });

export const getPartInternalIdRequest = () => action(CONST.GET_PART_INTERNAL_ID_REQUEST);
export const getPartInternalIdSuccess = (partId: PartInternalId) =>
  action(CONST.GET_PART_INTERNAL_ID_SUCCESS, { partInternalId: partId });
export const getPartInternalIdError = (error: unknown) => action(CONST.GET_PART_INTERNAL_ID_ERROR, { error });

export const getPartsStockistRequest = (onSuccess?: () => void) =>
  action(CONST.GET_PARTS_STOCK_LIST_REQUEST, { onSuccess });

export const getPartsStockistSuccess = () => action(CONST.GET_PARTS_STOCK_LIST_SUCCESS);

export const getPartsStockistError = (error: unknown) => action(CONST.GET_PARTS_STOCK_LIST_ERROR, { error });
