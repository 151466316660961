import React, { FC } from 'react';
import { HeaderComponentMobile } from '../../component/header/HeaderComponentMobile';
import { HeaderComponent } from '../../component/header/HeaderComponent';
import { Button, Col, Row } from 'antd';
import { useViewport } from '../../utils/customHooks/useViewport';
import { PartsCategoriesAdditionalMenu } from './PartsCategoriesAdditionalMenu';

type PartsCategoriesHeaderProps = {
  onSetOpenPartCategoryModal: (openCategoryModal: boolean) => void;
};

export const PartsCategoriesHeader: FC<PartsCategoriesHeaderProps> = props => {
  const { onSetOpenPartCategoryModal } = props;
  const { isMobile } = useViewport();

  return isMobile ? (
    <HeaderComponentMobile
      title='Kategorie'
      onAddElementOpenModal={onSetOpenPartCategoryModal}
    >
      <PartsCategoriesAdditionalMenu />
    </HeaderComponentMobile>
  ) : (
    <HeaderComponent title='Kategorie'>
      <Row gutter={16}>
        <Col>
          <Button
            type='primary'
            onClick={() => onSetOpenPartCategoryModal(true)}
          >
            Dodaj kategorię
          </Button>
        </Col>
        <PartsCategoriesAdditionalMenu />
      </Row>
    </HeaderComponent>
  );
};
