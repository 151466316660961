import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, DollarOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { RootReducerState } from '../../reducers';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { AlignType, FixedType } from 'rc-table/lib/interface';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import {
  ForkliftEngine,
  ForkliftEngineNames,
  ForkliftModel,
  ProductStatus,
  ProductStatusListType,
  ProductStatusNames,
  ForkliftTable,
  ForkliftTableNames,
} from '../../reducers/forklift/model';
import {
  addForkliftRequest,
  deleteForkliftRequest,
  getForkliftInternalIdRequest,
  updateForkliftRequest,
} from '../../reducers/forklift/actions';
import { getStatusProductIcon } from '../../utils/statusProductIcon';
import { ForkliftForm, ForkliftFormValues } from '../../forms/forklift';
import { getStatusForkliftClass } from '../../utils/statusForkliftClass';
import { BrandModel } from '../../reducers/brands/model';
import { CategoryModel } from '../../reducers/categories/model';
import { ForkliftPreview } from './ForkliftPreview';
import { ForkliftDataModel, getData } from './utils';
import { getBrandsRequest } from '../../reducers/brands/actions';
import { getCategoriesRequest } from '../../reducers/categories/actions';
import { MastModel } from '../../reducers/masts/model';
import { getMastsRequest } from '../../reducers/masts/actions';
import { ColumnsType } from 'antd/es/table';
import { CostsManager } from '../costs/CostsManager';
import { OrderPreview } from '../orders/OrderPreview';
import { CurrencyType, OrderModel } from '../../reducers/orders/model';
import { getOrdersRequest } from '../../reducers/orders/actions';
import { formatPrice } from '../../utils/formatPrice';

import { TableSC } from './TableSC';

type ForkliftListProps = {
  openForkliftModal: boolean;
  clickOpenForkliftModal: (openForkliftModal: boolean) => void;
  forkliftsList: ForkliftModel[];
  groupEditStatus: string[];
  setGroupEditStatus: (groupEditStatus: string[]) => void;
  tableSettings: string[];
  selectedForkliftTab: ProductStatusListType;
};

export const ForkliftList: FC<ForkliftListProps> = props => {
  const {
    openForkliftModal,
    clickOpenForkliftModal,
    forkliftsList,
    groupEditStatus,
    setGroupEditStatus,
    tableSettings,
    selectedForkliftTab,
  } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.forklifts.isFetching);
  const brandsList = useSelector<RootReducerState, BrandModel[]>(state => state.brands.brandsList);
  const categoriesList = useSelector<RootReducerState, CategoryModel[]>(state => state.categories.categoriesList);
  const mastsList = useSelector<RootReducerState, MastModel[]>(state => state.masts.mastsList);
  const ordersList = useSelector<RootReducerState, OrderModel[]>(state => state.orders.ordersList);
  const dispatch = useDispatch();
  const { getColumnSearchProps, getColumnSearchRangeProps, getColumnFilterProps } = useFilterAntTable();
  const [selectedForklift, setSelectedForklift] = useState<ForkliftModel | undefined>(undefined);
  const [, setDeleteForkliftModal] = useState(false);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [openForkliftCostModal, setOpenForkliftCostModal] = useState(false);
  const [selectedForkliftCost, setSelectedForkliftCost] = useState<ForkliftModel | undefined>(undefined);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | undefined>(undefined);
  const [openQuickViewOrderModal, setOpenQuickViewOrderModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    dispatch(getBrandsRequest());
    dispatch(getCategoriesRequest());
    dispatch(getMastsRequest());
    dispatch(getOrdersRequest());
  }, [dispatch]);

  const handleEditForkliftModal = (forkliftId: string) => {
    const forklift = forkliftsList.find(item => item._id === forkliftId);
    setSelectedForklift(forklift);
    clickOpenForkliftModal(true);
  };

  const handleDeleteForkliftModal = (forkliftId: string) => {
    dispatch(
      deleteForkliftRequest(forkliftId, () => {
        setDeleteForkliftModal(false);
      }),
    );
  };

  const handleCloseForkliftModal = () => {
    setSelectedForklift(undefined);
    clickOpenForkliftModal(false);
  };

  const handleSubmit = (values: ForkliftFormValues, onAddSimilar: () => void) => {
    if (selectedForklift) {
      dispatch(
        updateForkliftRequest(
          {
            ...selectedForklift,
            ...values,
            mastId: values.mastId ? values.mastId : null,
          },
          () => {
            setSelectedForklift(undefined);
            clickOpenForkliftModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addForkliftRequest(
          {
            ...values,
            mastId: values.mastId ? values.mastId : null,
          },
          () => {
            if (values._addNew) {
              void messageApi.open({
                type: 'success',
                content: 'Wózek został dodany.',
              });
              dispatch(getForkliftInternalIdRequest());
              onAddSimilar();
              return;
            }
            setSelectedForklift(undefined);
            clickOpenForkliftModal(false);
          },
        ),
      );
    }
  };

  const handleQuickViewForkliftModal = (forkliftId: string) => {
    const forklift = forkliftsList.find(item => item._id === forkliftId);
    setSelectedForklift(forklift);
    setOpenQuickViewModal(true);
  };

  const handleCloseQuickViewModal = () => {
    setSelectedForklift(undefined);
    setOpenQuickViewModal(false);
  };

  const handleAddForkliftCostModal = (forkliftId: string) => {
    const forklift = forkliftsList.find(item => item._id === forkliftId);
    setSelectedForkliftCost(forklift);
    setOpenForkliftCostModal(true);
  };

  const handleCloseForkliftCostModal = () => {
    setSelectedForkliftCost(undefined);
    setOpenForkliftCostModal(false);
  };

  const handleQuickViewOrderModal = (orderId: string) => {
    const selectedOrder = ordersList.find(order => order._id === orderId);
    setSelectedOrder(selectedOrder);
    setOpenQuickViewOrderModal(true);
  };

  const handleCloseQuickViewOrderModal = () => {
    setSelectedOrder(undefined);
    setOpenQuickViewOrderModal(false);
  };

  const columns: ColumnsType<ForkliftDataModel> = [
    {
      title: 'Id',
      dataIndex: 'internalId',
      key: 'internalId',
      fixed: 'left' as FixedType,
      width: 100,
      ...getColumnSearchProps('internalId', 'Id'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.internalId.localeCompare(b.internalId),
      hidden: false,
    },
    {
      title: ForkliftTableNames[ForkliftTable.BRAND],
      dataIndex: 'brandName',
      key: 'brandName',
      fixed: 'left' as FixedType,
      width: 100,
      ...getColumnFilterProps(
        'brandId',
        brandsList.map(brand => ({
          text: brand.name,
          value: brand._id,
        })),
      ),
      render: (brandName?: string) => <>{brandName}</>,
      hidden: tableSettings.includes(ForkliftTable.BRAND),
    },
    {
      title: ForkliftTableNames[ForkliftTable.TYPE],
      dataIndex: ForkliftTable.TYPE,
      key: ForkliftTable.TYPE,
      fixed: 'left' as FixedType,
      width: 90,
      ...getColumnSearchProps(ForkliftTable.TYPE, 'typu'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.type.localeCompare(b.type),
      hidden: tableSettings.includes(ForkliftTable.TYPE),
    },
    {
      title: ForkliftTableNames[ForkliftTable.SERIAL_NUMBER],
      dataIndex: ForkliftTable.SERIAL_NUMBER,
      key: ForkliftTable.SERIAL_NUMBER,
      width: 130,
      fixed: 'left' as FixedType,
      ...getColumnSearchProps(ForkliftTable.SERIAL_NUMBER, 'nr seryjnego'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.serialNumber.localeCompare(b.serialNumber),
      hidden: tableSettings.includes(ForkliftTable.SERIAL_NUMBER),
    },
    {
      title: ForkliftTableNames[ForkliftTable.CATEGORY],
      dataIndex: ForkliftTable.CATEGORY,
      key: ForkliftTable.CATEGORY,
      width: 100,
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'categoryId',
        categoriesList.map(category => ({
          text: category.name,
          value: category._id,
        })),
      ),
      render: (category?: CategoryModel) => <>{category?.name}</>,
      hidden: tableSettings.includes(ForkliftTable.CATEGORY),
    },
    {
      title: ForkliftTableNames[ForkliftTable.LIFTING_CAPACITY],
      dataIndex: ForkliftTable.LIFTING_CAPACITY,
      key: ForkliftTable.LIFTING_CAPACITY,
      width: 100,
      ...getColumnSearchRangeProps(ForkliftTable.LIFTING_CAPACITY, 'Udźwig'),
      render: (liftingCapacity: number) => <>{liftingCapacity} kg</>,
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.liftingCapacity - b.liftingCapacity,
      hidden: tableSettings.includes(ForkliftTable.LIFTING_CAPACITY),
    },
    {
      title: ForkliftTableNames[ForkliftTable.STATUS],
      dataIndex: ForkliftTable.STATUS,
      key: ForkliftTable.STATUS,
      align: 'center' as AlignType,
      width: 95,
      ...getColumnFilterProps(
        ForkliftTable.STATUS,
        Object.values(ProductStatus).map(status => ({
          value: status,
          text: ProductStatusNames[status],
        })),
      ),
      render: (status: ProductStatus) => getStatusProductIcon(status),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.status.localeCompare(b.status),
      hidden: tableSettings.includes(ForkliftTable.STATUS),
    },
    {
      title: ForkliftTableNames[ForkliftTable.ENGINE],
      dataIndex: ForkliftTable.ENGINE,
      key: ForkliftTable.ENGINE,
      align: 'center' as AlignType,
      width: 120,
      ...getColumnFilterProps(
        ForkliftTable.ENGINE,
        Object.values(ForkliftEngine).map(engine => ({
          value: engine,
          text: ForkliftEngineNames[engine],
        })),
      ),
      render: (engine: ForkliftEngine) => ForkliftEngineNames[engine],
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.engine.localeCompare(b.engine),
      hidden: tableSettings.includes(ForkliftTable.ENGINE),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRODUCTION_YEAR],
      dataIndex: ForkliftTable.PRODUCTION_YEAR,
      key: ForkliftTable.PRODUCTION_YEAR,
      align: 'center' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps(ForkliftTable.PRODUCTION_YEAR, 'Rok produkcji'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.productionYear - b.productionYear,
      hidden: tableSettings.includes(ForkliftTable.PRODUCTION_YEAR),
    },
    {
      title: ForkliftTableNames[ForkliftTable.HOURS],
      dataIndex: ForkliftTable.HOURS,
      key: ForkliftTable.HOURS,
      align: 'right' as AlignType,
      width: 70,
      ...getColumnSearchRangeProps(ForkliftTable.HOURS, 'Liczba godzin'),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.hours - b.hours,
      hidden: tableSettings.includes(ForkliftTable.HOURS),
    },
    {
      title: ForkliftTableNames[ForkliftTable.MAST],
      dataIndex: 'mastName',
      key: 'mastName',
      align: 'right' as AlignType,
      width: 90,
      ...getColumnFilterProps(
        'mastId',
        mastsList.map(mast => ({
          text: mast.name,
          value: mast._id,
        })),
      ),
      render: (mastName?: string) => <>{mastName}</>,
      hidden: tableSettings.includes(ForkliftTable.MAST),
    },
    {
      title: ForkliftTableNames[ForkliftTable.MAST_FOLD_HEIGHT],
      dataIndex: ForkliftTable.MAST_FOLD_HEIGHT,
      key: ForkliftTable.MAST_FOLD_HEIGHT,
      width: 120,
      align: 'right' as AlignType,
      ...getColumnSearchRangeProps(ForkliftTable.MAST_FOLD_HEIGHT, 'Wysokość masztu'),
      sorter: (a: ForkliftDataModel, b: ForkliftDataModel) => (a.mastFoldedHeight ?? 0) - (b.mastFoldedHeight ?? 0),
      hidden: tableSettings.includes(ForkliftTable.MAST_FOLD_HEIGHT),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_MIN],
      dataIndex: ForkliftTable.PRICE_MIN,
      key: ForkliftTable.PRICE_MIN,
      align: 'right' as AlignType,
      width: 120,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_MIN, 'Cena min'),
      render: (priceMin: number) => formatPrice(priceMin, CurrencyType.PLN),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.priceMin - b.priceMin,
      hidden: tableSettings.includes(ForkliftTable.PRICE_MIN),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_SUGGESTED],
      dataIndex: ForkliftTable.PRICE_SUGGESTED,
      key: ForkliftTable.PRICE_SUGGESTED,
      align: 'right' as AlignType,
      width: 120,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_SUGGESTED, 'Cena wyjściowa'),
      render: (priceSuggested: number) => formatPrice(priceSuggested, CurrencyType.PLN),
      sorter: (a: ForkliftModel, b: ForkliftModel) => a.priceSuggested - b.priceSuggested,
      hidden: tableSettings.includes(ForkliftTable.PRICE_SUGGESTED),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_WHOLESALE_MIN],
      dataIndex: ForkliftTable.PRICE_WHOLESALE_MIN,
      key: ForkliftTable.PRICE_WHOLESALE_MIN,
      align: 'right' as AlignType,
      width: 120,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_WHOLESALE_MIN, 'Cena hurt min'),
      render: (priceWholesaleMin: number) => formatPrice(priceWholesaleMin, CurrencyType.EUR),
      sorter: (a: ForkliftModel, b: ForkliftModel) => (a.priceWholesaleMin ?? 0) - (b.priceWholesaleMin ?? 0),
      hidden: tableSettings.includes(ForkliftTable.PRICE_WHOLESALE_MIN),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_WHOLESALE_SUGGESTED],
      dataIndex: ForkliftTable.PRICE_WHOLESALE_SUGGESTED,
      key: ForkliftTable.PRICE_WHOLESALE_SUGGESTED,
      align: 'right' as AlignType,
      width: 130,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_WHOLESALE_SUGGESTED, 'Cena hurt max'),
      render: (priceWholesaleSuggested: number) => formatPrice(priceWholesaleSuggested, CurrencyType.EUR),
      sorter: (a: ForkliftModel, b: ForkliftModel) =>
        (a.priceWholesaleSuggested ?? 0) - (b.priceWholesaleSuggested ?? 0),
      hidden: tableSettings.includes(ForkliftTable.PRICE_WHOLESALE_SUGGESTED),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_WITH_PREPARATION_MIN],
      dataIndex: ForkliftTable.PRICE_WITH_PREPARATION_MIN,
      key: ForkliftTable.PRICE_WITH_PREPARATION_MIN,
      align: 'right' as AlignType,
      width: 155,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_WITH_PREPARATION_MIN, 'Cena min z przygotowaniem'),
      render: (priceWithPreparationMin: number) => formatPrice(priceWithPreparationMin, CurrencyType.PLN),
      sorter: (a: ForkliftModel, b: ForkliftModel) =>
        (a.priceWithPreparationMin ?? 0) - (b.priceWithPreparationMin ?? 0),
      hidden: tableSettings.includes(ForkliftTable.PRICE_WITH_PREPARATION_MIN),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_WITH_PREPARATION_SUGGESTED],
      dataIndex: ForkliftTable.PRICE_WITH_PREPARATION_SUGGESTED,
      key: ForkliftTable.PRICE_WITH_PREPARATION_SUGGESTED,
      align: 'right' as AlignType,
      width: 155,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_WITH_PREPARATION_SUGGESTED, 'Cena z przygotowaniem'),
      render: (priceWithPreparationSuggested: number) => formatPrice(priceWithPreparationSuggested, CurrencyType.PLN),
      sorter: (a: ForkliftModel, b: ForkliftModel) =>
        (a.priceWithPreparationSuggested ?? 0) - (b.priceWithPreparationSuggested ?? 0),
      hidden: tableSettings.includes(ForkliftTable.PRICE_WITH_PREPARATION_SUGGESTED),
    },
    {
      title: ForkliftTableNames[ForkliftTable.PRICE_INIT_WITH_COSTS],
      dataIndex: ForkliftTable.PRICE_INIT_WITH_COSTS,
      key: ForkliftTable.PRICE_INIT_WITH_COSTS,
      align: 'right' as AlignType,
      width: 120,
      ...getColumnSearchRangeProps(ForkliftTable.PRICE_INIT_WITH_COSTS, 'Cena zak + koszt'),
      render: (priceInitWithCosts: number) => formatPrice(priceInitWithCosts, CurrencyType.PLN),
      sorter: (a: ForkliftDataModel, b: ForkliftDataModel) => a.priceInitWithCosts - b.priceInitWithCosts,
      hidden: tableSettings.includes(ForkliftTable.PRICE_INIT_WITH_COSTS),
    },
    {
      title: ForkliftTableNames[ForkliftTable.ORDER],
      dataIndex: 'orderNr',
      key: 'orderNr',
      width: 150,
      ...getColumnSearchProps('orderNr', 'zamówienia'),
      render: (orderNr: string, forklift: ForkliftModel) =>
        forklift.order && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <a
            onClick={e => {
              e.preventDefault();
              return forklift.order && handleQuickViewOrderModal(forklift.order._id);
            }}
          >
            {orderNr}
          </a>
        ),
      hidden: selectedForkliftTab === ProductStatusListType.AVAILABLE || tableSettings.includes(ForkliftTable.ORDER),
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      fixed: 'right' as FixedType,
      width: 85,
      align: 'right' as AlignType,
      hidden: false,
      render: (_id: string, forklift: ForkliftModel) => (
        <>
          <Tooltip
            title='Podgląd'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Zobacz podgląd'
              onClick={() => handleQuickViewForkliftModal(_id)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj wózek'
              onClick={() => handleEditForkliftModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Dodaj koszty'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Dodaj koszty wózka'
              onClick={() => handleAddForkliftCostModal(_id)}
              icon={<DollarOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie wózka'
              description='Czy na pewno chcesz usunąć wózek?'
              onConfirm={() => handleDeleteForkliftModal(forklift._id)}
              onCancel={() => setDeleteForkliftModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń wózek'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ].filter(item => !item.hidden);

  return (
    <>
      {contextHolder}
      <TableSC
        rowClassName={record => getStatusForkliftClass(record)}
        columns={columns}
        dataSource={getData(forkliftsList)}
        loading={isFetching}
        rowKey={record => record._id || ''}
        size='small'
        sticky
        rowSelection={{
          selectedRowKeys: groupEditStatus,
          onChange: (newSelectedRowKeys: React.Key[]) => setGroupEditStatus(newSelectedRowKeys as string[]),
          columnWidth: 30,
        }}
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        scroll={{ x: 1900 }}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane wózka'
        titleAdd='Dodaj wózek'
        openModal={openForkliftModal}
        handleCloseModal={handleCloseForkliftModal}
        selectedItem={selectedForklift}
        width='80%'
      >
        <ForkliftForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseForkliftModal}
          forkliftId={selectedForklift?._id}
        />
      </DrawerAddEdit>
      {selectedForklift && (
        <DrawerAddEdit
          titleEdit={`Podgląd wózka: ${selectedForklift.internalId}`}
          titleAdd={`Podgląd wózka: ${selectedForklift.internalId}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedForklift}
          width='80%'
        >
          <ForkliftPreview forkliftId={selectedForklift._id} />
        </DrawerAddEdit>
      )}
      {selectedForkliftCost && (
        <DrawerAddEdit
          titleEdit={`Koszty wózka: ${selectedForkliftCost.internalId}`}
          titleAdd={`Koszty wózka: ${selectedForkliftCost.internalId}`}
          openModal={openForkliftCostModal}
          handleCloseModal={handleCloseForkliftCostModal}
          selectedItem={selectedForklift}
          width='80%'
        >
          <CostsManager forklift={selectedForkliftCost} />
        </DrawerAddEdit>
      )}
      {selectedOrder && (
        <DrawerAddEdit
          titleEdit={`Podgląd zamówienia: ${selectedOrder.orderNr}`}
          titleAdd={`Podgląd zamówienia: ${selectedOrder.orderNr}`}
          openModal={openQuickViewOrderModal}
          handleCloseModal={handleCloseQuickViewOrderModal}
          selectedItem={selectedOrder}
          width='60%'
        >
          <OrderPreview orderId={selectedOrder._id} />
        </DrawerAddEdit>
      )}
    </>
  );
};
