import styled from 'styled-components';
import { Divider, List } from 'antd';

import { OrderDataModel } from '../utils';

export const ListSC = styled(List<OrderDataModel>)`
  padding: 0 16px 16px 16px;

  &.mobileOrderList li.listItem .listItemMeta h4 {
    margin-block-end: 0;
  }
`;

export const DividerSC = styled(Divider)`
  margin: 12px 0 7px 0;
`;

export const ListItemSC = styled(List.Item)`
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;

  &.red {
    background: rgba(255, 77, 78, 0.3);
    &:hover {
      background: rgba(255, 70, 70, 0.4);
    }
  }
`;
