export enum Paths {
  BRANDS = '/brands',
  LOGIN_PAGE = '/login',
  HOME_PAGE = '/',
  RESET_PASSWORD = '/reset-password',
  SET_PASSWORD = '/set-password',
  USERS = '/users',
  CATEGORIES = '/categories',
  CLIENTS = '/clients',
  ORDERS = '/orders',
  SETTINGS = '/settings',
  PAINTING = '/painting',
  MASTS = '/masts',
  EQUIPMENT = '/equipment',
  EQUIPMENT_BRANDS = '/equipment-brands',
  EQUIPMENT_CATEGORIES = '/equipment-categories',
  GENERAL_SETTINGS = '/general-settings',
  REPORTS = '/reports',
  OFFERS = '/offers',
  PART = '/parts',
  PART_BRANDS = '/part-brands',
  PART_CATEGORIES = '/part-categories',
}
