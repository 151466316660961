export type AssetModel = {
  _id: string;
  name: string;
  originalName: string;
  mimeType: string;
  uri: string; // `${AssetBucket.DISH}/uuid.jpg`
  bucket: AssetBucket;
  size: number; // size in B
  ownerId: string;
  parentId: string | null; // Which item is the owner of the asset
  width: number | null;
  height: number | null;
  createdAt: string;
  updatedAt: string;
};

export enum AssetBucket {
  USER_AVATAR = 'user-avatar',
  FORKLIFT = 'forklift',
  EQUIPMENT = 'equipment',
  PART = 'part',
}
