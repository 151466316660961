import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  addPartError,
  addPartRequest,
  addPartSuccess,
  deletePartError,
  deletePartRequest,
  deletePartSuccess,
  getPartError,
  getPartInternalIdError,
  getPartInternalIdSuccess,
  getPartRequest,
  getPartsError,
  getPartsStockistError,
  getPartsStockistRequest,
  getPartsStockistSuccess,
  getPartsSuccess,
  getPartSuccess,
  updatePartError,
  updatePartRequest,
  updatePartsStatusesError,
  updatePartsStatusesRequest,
  updatePartsStatusesSuccess,
  updatePartSuccess,
} from './actions';
import * as CONST from './consts';
import { PartInternalId, PartModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { ActionType } from 'typesafe-actions';
import { getOrdersRequest } from '../orders/actions';
import { apiClientParts } from '../../services/apiClient/parts';

function* getParts(): Generator<CallEffect | PutEffect, void, ResponseModel<PartModel[]>> {
  try {
    const response = yield call(apiClientParts.getParts);

    yield put(getPartsSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get parts',
      description: getErrorMessage(err),
    });
    yield put(getPartsError(err));
  }
}

function* addPart(
  action: ActionType<typeof addPartRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartModel>> {
  try {
    const { part, onSuccess } = action.payload;
    const response = yield call(apiClientParts.addPart, part);

    yield put(addPartSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding part',
      description: getErrorMessage(err),
    });
    yield put(addPartError(err));
  }
}

function* updatePart(
  action: ActionType<typeof updatePartRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartModel>> {
  try {
    const { part, onSuccess } = action.payload;
    const response = yield call(apiClientParts.updatePart, part);

    yield put(updatePartSuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating part',
      description: getErrorMessage(err),
    });
    yield put(updatePartError(err));
  }
}

function* deleteForklift(action: ActionType<typeof deletePartRequest>): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { partId, onSuccess } = action.payload;
    yield call(apiClientParts.deletePart, partId);

    yield put(deletePartSuccess(partId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting part',
      description: getErrorMessage(err),
    });
    yield put(deletePartError(err));
  }
}

function* updatePartStatuses(
  action: ActionType<typeof updatePartsStatusesRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartModel[]>> {
  try {
    const { partsStatuses, onSuccess } = action.payload;
    const response = yield call(apiClientParts.updatePartStatus, partsStatuses);

    yield put(updatePartsStatusesSuccess(response.data));
    yield put(getOrdersRequest());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating parts statuses',
      description: getErrorMessage(err),
    });
    yield put(updatePartsStatusesError(err));
  }
}

function* getPart(
  action: ActionType<typeof getPartRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartModel>> {
  try {
    const { partId } = action.payload;
    const response = yield call(apiClientParts.getPart, partId);

    yield put(getPartSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get part',
      description: getErrorMessage(err),
    });
    yield put(getPartError(err));
  }
}

function* getPartId(): Generator<CallEffect | PutEffect, void, ResponseModel<PartInternalId>> {
  try {
    const response = yield call(apiClientParts.getPartInternalId);

    yield put(getPartInternalIdSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get part Id',
      description: getErrorMessage(err),
    });
    yield put(getPartInternalIdError(err));
  }
}

function* getPartsStockList(
  action: ActionType<typeof getPartsStockistRequest>,
): Generator<CallEffect | PutEffect, void, ArrayBuffer> {
  try {
    const { onSuccess } = action.payload;
    const response = yield call(apiClientParts.getStockList);
    const blobUrl = URL.createObjectURL(
      new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    );
    window.open(blobUrl, '_blank');
    yield put(getPartsStockistSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during generating stock list',
      description: getErrorMessage(err),
    });
    yield put(getPartsStockistError(err));
  }
}

export function* watchPartsSaga(): Generator {
  yield takeLatest(CONST.GET_PARTS_REQUEST, getParts);
  yield takeLatest(CONST.ADD_PART_REQUEST, addPart);
  yield takeLatest(CONST.UPDATE_PART_REQUEST, updatePart);
  yield takeLatest(CONST.DELETE_PART_REQUEST, deleteForklift);
  yield takeLatest(CONST.UPDATE_PARTS_STATUSES_REQUEST, updatePartStatuses);
  yield takeLatest(CONST.GET_PART_REQUEST, getPart);
  yield takeLatest(CONST.GET_PART_INTERNAL_ID_REQUEST, getPartId);
  yield takeLatest(CONST.GET_PARTS_STOCK_LIST_REQUEST, getPartsStockList);
}
