import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { PartCategoryModel } from './model';

export type PartsCategoriesAction = ActionType<typeof actions>;
export type PartsCategoriesState = {
  readonly isFetching: boolean;
  readonly categoriesList: PartCategoryModel[];
};

export const partsCategoriesReducer = combineReducers<PartsCategoriesState, PartsCategoriesAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_PART_CATEGORIES_REQUEST:
      case CONST.ADD_PART_CATEGORY_REQUEST:
      case CONST.UPDATE_PART_CATEGORY_REQUEST:
      case CONST.DELETE_PART_CATEGORY_REQUEST:
        return true;

      case CONST.GET_PART_CATEGORIES_SUCCESS:
      case CONST.GET_PART_CATEGORIES_ERROR:
      case CONST.ADD_PART_CATEGORY_SUCCESS:
      case CONST.ADD_PART_CATEGORY_ERROR:
      case CONST.UPDATE_PART_CATEGORY_SUCCESS:
      case CONST.UPDATE_PART_CATEGORY_ERROR:
      case CONST.DELETE_PART_CATEGORY_SUCCESS:
      case CONST.DELETE_PART_CATEGORY_ERROR:
        return false;

      default:
        return state;
    }
  },
  categoriesList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_PART_CATEGORIES_SUCCESS:
        return action.payload.categories;

      case CONST.ADD_PART_CATEGORY_SUCCESS:
        return [action.payload.category, ...state];

      case CONST.UPDATE_PART_CATEGORY_SUCCESS: {
        const categoryIdx = state.findIndex(item => item._id === action.payload.category._id);
        state.splice(categoryIdx, 1, action.payload.category);

        return [...state];
      }

      case CONST.DELETE_PART_CATEGORY_SUCCESS:
        return state.filter(category => category._id !== action.payload.categoryId);

      default:
        return state;
    }
  },
});
