import React, { FC } from 'react';
import { Col, Image, List, Row } from 'antd';

import { ProductStatusNames } from '../../reducers/forklift/model';
import { gutterInner } from '../../forms/forklift';
import { displayPrice } from '../../utils/displayPrice';
import { useGetSettings } from '../../services/apiClient/settings/settingsQueries';
import { GeneralSettingModel } from '../../reducers/settings/model';
import { AssetTextSC } from '../common/preview/AssetTextSC';
import { PreviewListSC } from '../common/preview/PreviewListSC';
import { RowGallerySC } from '../common/preview/RowGallerySC';
import { useGetPart } from '../../services/apiClient/parts/partQueries';

type PartProps = {
  partId: string;
};

export const PartPreview: FC<PartProps> = props => {
  const { partId } = props;

  const { data: part, isFetching } = useGetPart(partId);
  const { data: settingsList } = useGetSettings();

  let euroExchange: GeneralSettingModel | undefined;
  if (settingsList) {
    euroExchange = settingsList.find(item => item.key === 'EUR-TO-PLN');
  }

  return (
    <Row gutter={gutterInner}>
      <Col span={24}>
        <PreviewListSC
          size='small'
          loading={isFetching}
          className='previewList firstList'
        >
          {part?.name && (
            <List.Item className='quickViewListElement'>
              <List.Item.Meta
                className='listItemMeta'
                title='Nazwa:'
                description={part.name}
              />
            </List.Item>
          )}
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Marka:'
              description={part?.brand?.name}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Typ:'
              description={part?.type}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Kategoria:'
              description={part?.category?.name}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Status:'
              description={part && ProductStatusNames[part.status]}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Rok produkcji:'
              description={part?.productionYear}
            />
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Cena zakupu:'
              description={`${part?.price ? displayPrice(part.price) : 0} PLN`}
            />
            <div>{`(${displayPrice(
              part?.price && euroExchange?.value ? part.price / +euroExchange.value : 0,
            )} EUR)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              className='listItemMeta'
              title='Cena dealer:'
              description={`${displayPrice(Number(part?.priceWholesale))} EUR`}
            />
            <div>{`(${displayPrice(
              part?.priceWholesale && euroExchange?.value ? part.priceWholesale * +euroExchange.value : 0,
            )} PLN)`}</div>
          </List.Item>
          <List.Item className='quickViewListElement'>
            <List.Item.Meta
              title='Uwagi:'
              description={part?.notes}
              className='listItemMeta breakLine'
            />
          </List.Item>
        </PreviewListSC>
        {part?.assets && (
          <Row gutter={gutterInner}>
            <Col span={24}>
              <Row>
                <AssetTextSC>Zdjęcia:</AssetTextSC>
              </Row>
              <RowGallerySC>
                <Image.PreviewGroup>
                  {part.assets.map(image => (
                    <div
                      className='imageWrapper'
                      key={image._id}
                    >
                      <Image
                        className='imageGalleryElement'
                        src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${image.uri}`}
                      />
                    </div>
                  ))}
                </Image.PreviewGroup>
              </RowGallerySC>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
