import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';

import { initFormik } from './formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import {
  ForkliftEngine,
  ForkliftEngineNames,
  ForkliftInternalId,
  ProductStatus,
  ProductStatusNames,
} from '../../reducers/forklift/model';
import { BrandModel } from '../../reducers/brands/model';
import { CategoryModel } from '../../reducers/categories/model';
import { addBrandRequest, getBrandsRequest } from '../../reducers/brands/actions';
import { addCategoryRequest, getCategoriesRequest } from '../../reducers/categories/actions';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { CostModel } from '../../reducers/costs/model';
import { getProductCostRequest } from '../../reducers/costs/actions';
import { AssetBucket, AssetModel } from '../../reducers/assets/model';
import { UploadImage } from '../../component/uploadImage/UploadImage';
import { getForkliftInternalIdRequest } from '../../reducers/forklift/actions';
import { BrandForm, BrandFormValues } from '../brands';
import { MastModel } from '../../reducers/masts/model';
import { MastForm, MastFormValues } from '../mast';
import { addMastRequest, getMastsRequest } from '../../reducers/masts/actions';
import { CategoryForm, CategoryFormValues } from '../category';
import { CostsManager } from '../../component/costs/CostsManager';
import { CurrencyType, CurrencyTypeNames } from '../../reducers/orders/model';
import { Can } from '../../component/can/can';
import { AbilityAction, AbilitySubject } from '../../services/authService/ability';
import { useViewport } from '../../utils/customHooks/useViewport';
import { useGetForklift } from '../../services/apiClient/forklifts/forkliftQueries';

const { Item } = Form;
const { Text } = Typography;
const { TextArea } = Input;

export const gutterMain = 24;
export const gutterInner = 12;

export type ForkliftFormValues = {
  internalId: string;
  brandId: string;
  categoryId: string;
  engine: ForkliftEngine;
  status: ProductStatus;
  liftingCapacity: number;
  productionYear: number;
  hours: number;
  priceInit: number;
  priceMin: number;
  priceSuggested: number;
  type: string;
  serialNumber: string;
  mastId?: string;
  assets: AssetModel[];
  mastFoldedHeight?: number;
  liftingHeight: number;
  accessories?: string;
  notes?: string;
  priceWholesaleMin?: number;
  priceWholesaleSuggested?: number;
  mastFreeLifting?: number;
  priceWithPreparationMin?: number;
  priceWithPreparationSuggested?: number;
  boughtFrom?: string;
  _addNew?: boolean;
};

export type ForkliftFormProps = {
  forkliftId?: string;
  handleSubmit: (values: ForkliftFormValues, onAddSimilar: () => void) => void;
  handleCancelForm: () => void;
};

export const ForkliftForm: FC<ForkliftFormProps> = props => {
  const { forkliftId, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.forklifts.isFetching);
  const brandList = useSelector<RootReducerState, BrandModel[]>(state => state.brands.brandsList);
  const mastList = useSelector<RootReducerState, MastModel[]>(state => state.masts.mastsList);
  const categoriesList = useSelector<RootReducerState, CategoryModel[]>(state => state.categories.categoriesList);
  const productCostsList = useSelector<RootReducerState, CostModel[]>(state => state.costs.productCosts);
  const { data: forklift } = useGetForklift(forkliftId);
  const forkliftInternalId = useSelector<RootReducerState, ForkliftInternalId | null>(
    state => state.forklifts.forkliftInternalId,
  );
  const formik = useFormik(initFormik(props, forklift));

  const [openCostForkliftModal, setOpenCostForkliftModal] = useState(false);
  const [openBrandModal, setOpenBrandModal] = useState(false);
  const [openMastModal, setOpenMastModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const { isMobile } = useViewport();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandsRequest());
    dispatch(getMastsRequest());
    dispatch(getCategoriesRequest());
    if (forkliftId) {
      dispatch(getProductCostRequest(forkliftId));
    } else {
      dispatch(getForkliftInternalIdRequest());
    }
  }, [dispatch, forkliftId]);

  useEffect(() => {
    if (!forklift?.internalId && forkliftInternalId?.nextId) {
      void formik.setFieldValue('internalId', forkliftInternalId.nextId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forkliftInternalId]);

  const handleBrandSubmit = (values: BrandFormValues) => {
    dispatch(
      addBrandRequest(values, brand => {
        setOpenBrandModal(false);
        void formik.setFieldValue('brandId', brand?._id);
      }),
    );
  };

  const handleMastSubmit = (values: MastFormValues) => {
    dispatch(
      addMastRequest(values, mast => {
        setOpenMastModal(false);
        void formik.setFieldValue('mastId', mast?._id);
      }),
    );
  };

  const handleCategorySubmit = (values: CategoryFormValues) => {
    dispatch(
      addCategoryRequest(values, category => {
        setOpenCategoryModal(false);
        void formik.setFieldValue('categoryId', category?._id);
      }),
    );
  };

  const handleCreateSimilarForklift = () => {
    void formik.setFieldValue('_addNew', true).then(() => {
      void formik.submitForm();
    });
  };

  const handleCreateForklift = () => {
    void formik.setFieldValue('_addNew', false).then(() => {
      void formik.submitForm();
    });
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        <Row gutter={gutterMain}>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Id wewnętrzne'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.internalId && formik.touched.internalId ? 'error' : ''}
              help={formik.errors.internalId && formik.touched.internalId ? formik.errors.internalId : null}
            >
              <Input
                disabled={isFetching}
                name='internalId'
                value={formik.values.internalId}
                placeholder='Id wewnętrzne'
                onChange={formik.handleChange}
              />
            </Item>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={16}
                lg={17}
                xl={18}
                xxl={19}
              >
                <Item
                  label='Marka'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.brandId && formik.touched.brandId ? 'error' : ''}
                  help={formik.errors.brandId && formik.touched.brandId ? formik.errors.brandId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.brandId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('brandId', value)}
                    options={brandList.map(brand => ({
                      label: brand.name,
                      value: brand._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={8}
                lg={7}
                xl={6}
                xxl={5}
                className='right'
              >
                <Button onClick={() => setOpenBrandModal(true)}>Dodaj markę</Button>
              </Col>
            </Row>
            <Item
              label='Typ'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.type && formik.touched.type ? 'error' : ''}
              help={formik.errors.type && formik.touched.type ? formik.errors.type : null}
            >
              <Input
                name='type'
                value={formik.values.type}
                placeholder='Typ'
                onChange={formik.handleChange}
              />
            </Item>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={15}
                lg={16}
                xl={17}
                xxl={18}
              >
                <Item
                  label='Kategoria'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.categoryId && formik.touched.categoryId ? 'error' : ''}
                  help={formik.errors.categoryId && formik.touched.categoryId ? formik.errors.categoryId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.categoryId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('categoryId', value)}
                    options={categoriesList.map(categoryItem => ({
                      label: categoryItem.name,
                      value: categoryItem._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={9}
                lg={8}
                xl={7}
                xxl={6}
                className='right'
              >
                <Button onClick={() => setOpenCategoryModal(true)}>Dodaj kategorię</Button>
              </Col>
            </Row>
            <Item
              label='Silnik'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.engine && formik.touched.engine ? 'error' : ''}
              help={formik.errors.engine && formik.touched.engine ? formik.errors.engine : null}
            >
              <Select
                showSearch
                optionFilterProp='children'
                value={formik.values.engine}
                style={{ width: '100%' }}
                onChange={value => formik.setFieldValue('engine', value)}
                options={Object.values(ForkliftEngine).map(engine => ({
                  label: ForkliftEngineNames[engine],
                  value: engine,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
            <Item
              label='Status'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.status && formik.touched.status ? 'error' : ''}
              help={formik.errors.status && formik.touched.status ? formik.errors.status : null}
            >
              <Select
                showSearch
                optionFilterProp='children'
                value={formik.values.status}
                style={{ width: '100%' }}
                onChange={value => formik.setFieldValue('status', value)}
                options={Object.values(ProductStatus).map(status => ({
                  label: ProductStatusNames[status],
                  value: status,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
            <Item
              label='Udźwig'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.liftingCapacity && formik.touched.liftingCapacity ? 'error' : ''}
              help={
                formik.errors.liftingCapacity && formik.touched.liftingCapacity ? formik.errors.liftingCapacity : null
              }
            >
              <Input
                name='liftingCapacity'
                value={formik.values.liftingCapacity}
                placeholder='Udźwig'
                onChange={formik.handleChange}
                addonAfter='kg'
              />
            </Item>
            <Item
              label='Rok produkcji'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.productionYear && formik.touched.productionYear ? 'error' : ''}
              help={formik.errors.productionYear && formik.touched.productionYear ? formik.errors.productionYear : null}
            >
              <Input
                name='productionYear'
                value={formik.values.productionYear}
                placeholder='Rok produkcji'
                onChange={formik.handleChange}
              />
            </Item>
            <Item
              label='Motogodziny'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.hours && formik.touched.hours ? 'error' : ''}
              help={formik.errors.hours && formik.touched.hours ? formik.errors.hours : null}
            >
              <Input
                name='hours'
                value={formik.values.hours}
                placeholder='Motogodziny'
                addonAfter={'mth'}
                onChange={formik.handleChange}
              />
            </Item>

            <Item
              label='Cena zakupu'
              hasFeedback={true}
              validateStatus={formik.errors.priceInit && formik.touched.priceInit ? 'error' : ''}
              help={formik.errors.priceInit && formik.touched.priceInit ? formik.errors.priceInit : null}
            >
              <Input
                name='priceInit'
                value={formik.values.priceInit}
                placeholder='Cena zakupu'
                onChange={formik.handleChange}
                addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
              />
            </Item>
            <Row
              justify='space-between'
              align='top'
              gutter={gutterInner}
            >
              <Col
                xs={24}
                sm={12}
                md={24}
                xl={12}
              >
                <Item
                  label='Wyjściowa cena klient końcowy'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.priceSuggested && formik.touched.priceSuggested ? 'error' : ''}
                  help={
                    formik.errors.priceSuggested && formik.touched.priceSuggested ? formik.errors.priceSuggested : null
                  }
                >
                  <Input
                    name='priceSuggested'
                    value={formik.values.priceSuggested}
                    placeholder='Wyjściowa cena klient końcowy'
                    onChange={formik.handleChange}
                    addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
                  />
                </Item>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={24}
                xl={12}
              >
                <Item
                  label='Minimalna cena klient końcowy'
                  required={true}
                  hasFeedback={true}
                  validateStatus={formik.errors.priceMin && formik.touched.priceMin ? 'error' : ''}
                  help={formik.errors.priceMin && formik.touched.priceMin ? formik.errors.priceMin : null}
                >
                  <Input
                    name='priceMin'
                    value={formik.values.priceMin}
                    placeholder='Minimalna cena klient końcowy'
                    onChange={formik.handleChange}
                    addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
                  />
                  <Text type='secondary'>
                    Zakup + koszty: {+formik.values.priceInit + (forklift?.costsAmount ?? 0)}
                  </Text>
                </Item>
              </Col>
            </Row>
            <Row
              justify='space-between'
              align='top'
              gutter={gutterInner}
            >
              <Col
                xs={24}
                sm={12}
                md={24}
                xl={12}
              >
                <Item
                  label='Sugerowana cena dealer'
                  hasFeedback={true}
                  validateStatus={
                    formik.errors.priceWholesaleSuggested && formik.touched.priceWholesaleSuggested ? 'error' : ''
                  }
                  help={
                    formik.errors.priceWholesaleSuggested && formik.touched.priceWholesaleSuggested
                      ? formik.errors.priceWholesaleSuggested
                      : null
                  }
                >
                  <Input
                    name='priceWholesaleSuggested'
                    value={formik.values.priceWholesaleSuggested}
                    placeholder='Sugerowana cena dealer'
                    onChange={formik.handleChange}
                    addonAfter={CurrencyTypeNames[CurrencyType.EUR]}
                  />
                </Item>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={24}
                xl={12}
              >
                <Item
                  label='Minimalna cena dealer'
                  hasFeedback={true}
                  validateStatus={formik.errors.priceWholesaleMin && formik.touched.priceWholesaleMin ? 'error' : ''}
                  help={
                    formik.errors.priceWholesaleMin && formik.touched.priceWholesaleMin
                      ? formik.errors.priceWholesaleMin
                      : null
                  }
                >
                  <Input
                    name='priceWholesaleMin'
                    value={formik.values.priceWholesaleMin}
                    placeholder='Minimalna cena dealer'
                    onChange={formik.handleChange}
                    addonAfter={CurrencyTypeNames[CurrencyType.EUR]}
                  />
                </Item>
              </Col>
            </Row>
            <Row
              justify='space-between'
              align='top'
              gutter={gutterInner}
            >
              <Col
                xs={24}
                sm={12}
                md={24}
                xl={12}
              >
                <Item
                  label='Cena z przygotowaniem'
                  hasFeedback={true}
                  validateStatus={
                    formik.errors.priceWithPreparationSuggested && formik.touched.priceWithPreparationSuggested
                      ? 'error'
                      : ''
                  }
                  help={
                    formik.errors.priceWithPreparationSuggested && formik.touched.priceWithPreparationSuggested
                      ? formik.errors.priceWithPreparationSuggested
                      : null
                  }
                >
                  <Input
                    name='priceWithPreparationSuggested'
                    value={formik.values.priceWithPreparationSuggested}
                    placeholder='Cena z przygotowaniem'
                    onChange={formik.handleChange}
                    addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
                  />
                </Item>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={24}
                xl={12}
              >
                <Item
                  label='Minimalna cena z przygotowaniem'
                  hasFeedback={true}
                  validateStatus={
                    formik.errors.priceWithPreparationMin && formik.touched.priceWithPreparationMin ? 'error' : ''
                  }
                  help={
                    formik.errors.priceWithPreparationMin && formik.touched.priceWithPreparationMin
                      ? formik.errors.priceWithPreparationMin
                      : null
                  }
                >
                  <Input
                    name='priceWithPreparationMin'
                    value={formik.values.priceWithPreparationMin}
                    placeholder='Minimalna cena z przygotowaniem'
                    onChange={formik.handleChange}
                    addonAfter={CurrencyTypeNames[CurrencyType.PLN]}
                  />
                </Item>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            md={12}
          >
            <Item
              label='Numer seryjny'
              required={true}
              hasFeedback={true}
              validateStatus={formik.errors.serialNumber && formik.touched.serialNumber ? 'error' : ''}
              help={formik.errors.serialNumber && formik.touched.serialNumber ? formik.errors.serialNumber : null}
            >
              <Input
                name='serialNumber'
                value={formik.values.serialNumber}
                placeholder='Numer seryjny'
                onChange={formik.handleChange}
              />
            </Item>
            <Can
              do={AbilityAction.READ}
              on={AbilitySubject.FORKLIFT}
            >
              <Item
                label='Kupiony od'
                hasFeedback={true}
                validateStatus={formik.errors.boughtFrom && formik.touched.boughtFrom ? 'error' : ''}
                help={formik.errors.boughtFrom && formik.touched.boughtFrom ? formik.errors.boughtFrom : null}
              >
                <Input
                  name='boughtFrom'
                  value={formik.values.boughtFrom}
                  placeholder='Kupiony od'
                  onChange={formik.handleChange}
                />
              </Item>
            </Can>
            <Row
              justify='space-between'
              align='middle'
              gutter={gutterInner}
            >
              <Col
                xs={15}
                sm={18}
                md={16}
                lg={17}
                xl={18}
                xxl={19}
              >
                <Item
                  label='Maszt'
                  hasFeedback={true}
                  validateStatus={formik.errors.mastId && formik.touched.mastId ? 'error' : ''}
                  help={formik.errors.mastId && formik.touched.mastId ? formik.errors.mastId : null}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    value={formik.values.mastId}
                    style={{ width: '100%' }}
                    onChange={value => formik.setFieldValue('mastId', value)}
                    options={mastList.map(mast => ({
                      label: mast.name,
                      value: mast._id,
                    }))}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
              </Col>
              <Col
                xs={9}
                sm={6}
                md={8}
                lg={7}
                xl={6}
                xxl={5}
                className='right'
              >
                <Button onClick={() => setOpenMastModal(true)}>Dodaj maszt</Button>
              </Col>
            </Row>
            <Item
              label='Wolny skok masztu'
              hasFeedback={true}
              validateStatus={formik.errors.mastFreeLifting && formik.touched.mastFreeLifting ? 'error' : ''}
              help={
                formik.errors.mastFreeLifting && formik.touched.mastFreeLifting ? formik.errors.mastFreeLifting : null
              }
            >
              <Input
                name='mastFreeLifting'
                value={formik.values.mastFreeLifting}
                placeholder='Wolny skok masztu'
                onChange={formik.handleChange}
                addonAfter='mm'
              />
            </Item>
            <Item
              label='Wysokość podnoszenia'
              hasFeedback={true}
              validateStatus={formik.errors.mastFoldedHeight && formik.touched.mastFoldedHeight ? 'error' : ''}
              help={
                formik.errors.mastFoldedHeight && formik.touched.mastFoldedHeight
                  ? formik.errors.mastFoldedHeight
                  : null
              }
            >
              <Input
                name='mastFoldedHeight'
                value={formik.values.mastFoldedHeight}
                placeholder='Wysokość podnoszenia'
                onChange={formik.handleChange}
                addonAfter='mm'
              />
            </Item>
            <Item
              label='Wysokość masztu po złożeniu'
              hasFeedback={true}
              validateStatus={formik.errors.liftingHeight && formik.touched.liftingHeight ? 'error' : ''}
              help={formik.errors.liftingHeight && formik.touched.liftingHeight ? formik.errors.liftingHeight : null}
            >
              <Input
                name='liftingHeight'
                value={formik.values.liftingHeight}
                placeholder='Wysokość masztu po złożeniu'
                onChange={formik.handleChange}
                addonAfter='mm'
              />
            </Item>
            <Item
              label='Osprzęt'
              hasFeedback={true}
              validateStatus={formik.errors.accessories && formik.touched.accessories ? 'error' : ''}
              help={formik.errors.accessories && formik.touched.accessories ? formik.errors.accessories : null}
            >
              <TextArea
                name='accessories'
                showCount
                maxLength={1000}
                onChange={formik.handleChange}
                placeholder='Osprzęt'
                value={formik.values.accessories}
                rows={6}
              />
            </Item>
            <Item
              label='Uwagi'
              hasFeedback={true}
              validateStatus={formik.errors.notes && formik.touched.notes ? 'error' : ''}
              help={formik.errors.notes && formik.touched.notes ? formik.errors.notes : null}
            >
              <TextArea
                name='notes'
                showCount
                maxLength={1000}
                onChange={formik.handleChange}
                placeholder='Uwagi'
                value={formik.values.notes}
                rows={6}
              />
            </Item>
            <Item
              label='Zdjęcia'
              hasFeedback={true}
              validateStatus={formik.errors.assets && formik.touched.assets ? 'error' : ''}
              help={formik.errors.assets && formik.touched.assets ? formik.errors.assets : null}
            >
              <UploadImage
                assets={formik.values.assets}
                bucket={AssetBucket.FORKLIFT}
                multiple={true}
                onChange={assets => formik.setFieldValue('assets', assets)}
              />
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col
            xs={7}
            sm={12}
            md={14}
            lg={16}
          >
            <Button onClick={handleCancelForm}>Anuluj</Button>
          </Col>
          <Col
            xs={17}
            sm={12}
            md={10}
            lg={8}
            xl={7}
            xxl={5}
          >
            <Row justify='space-between'>
              {forklift && (
                <Col>
                  <Button onClick={() => setOpenCostForkliftModal(true)}>Koszty ({productCostsList.length})</Button>
                </Col>
              )}
              {!forklift && (
                <Col>
                  <Button
                    loading={isFetching}
                    onClick={handleCreateSimilarForklift}
                  >
                    Dodaj podobny
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isFetching}
                  onClick={handleCreateForklift}
                >
                  {forklift ? 'Zapisz' : 'Dodaj'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {forklift && (
        <DrawerAddEdit
          titleEdit='Edytuj koszty'
          titleAdd='Edytuj koszty'
          openModal={openCostForkliftModal}
          handleCloseModal={() => setOpenCostForkliftModal(false)}
          width={isMobile ? '100%' : '50%'}
        >
          <CostsManager forklift={forklift} />
        </DrawerAddEdit>
      )}
      <DrawerAddEdit
        titleEdit='Edytuj markę'
        titleAdd='Dodaj markę'
        openModal={openBrandModal}
        handleCloseModal={() => setOpenBrandModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <BrandForm
          handleSubmit={handleBrandSubmit}
          handleCancelForm={() => setOpenBrandModal(false)}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Edytuj maszt'
        titleAdd='Dodaj maszt'
        openModal={openMastModal}
        handleCloseModal={() => setOpenMastModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <MastForm
          handleSubmit={handleMastSubmit}
          handleCancelForm={() => setOpenMastModal(false)}
        />
      </DrawerAddEdit>
      <DrawerAddEdit
        titleEdit='Edytuj kategorię'
        titleAdd='Dodaj kategorię'
        openModal={openCategoryModal}
        handleCloseModal={() => setOpenCategoryModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <CategoryForm
          handleSubmit={handleCategorySubmit}
          handleCancelForm={() => setOpenCategoryModal(false)}
        />
      </DrawerAddEdit>
    </div>
  );
};
