import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Dropdown, Flex, List, Space, Tag, Typography } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { DividerSC } from '../../orders/mobile/styles';
import { formatPrice } from '../../../utils/formatPrice';
import { ListItemSC } from './styles';
import { OffersListDateProps } from '../OffersList';

const { Text } = Typography;

type OfferMobileItemProps = {
  offer: OffersListDateProps;
  clickOpenOfferModal: (openOfferModal: boolean) => void;
  setSelectedOffer: (selectedOffer: OffersListDateProps) => void;
  setOpenQuickViewModal: (openQuickViewModal: boolean) => void;
  setOpenOfferDeleteModal: (openOfferDeleteModal: boolean) => void;
};

export const OfferMobileItem: FC<OfferMobileItemProps> = props => {
  const { offer, clickOpenOfferModal, setSelectedOffer, setOpenQuickViewModal, setOpenOfferDeleteModal } = props;
  const [morePrice, showMorePrice] = useState(false);

  const handleDeleteOfferModal = (offer: OffersListDateProps) => {
    setSelectedOffer(offer);
    setOpenOfferDeleteModal(true);
  };

  const getItemsForActionMenu = (offer: OffersListDateProps) => [
    {
      label: 'Edytuj',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => {
        clickOpenOfferModal(true);
        setSelectedOffer(offer);
      },
    },
    {
      label: 'Podgląd',
      key: '2',
      icon: <EyeOutlined />,
      onClick: () => {
        setOpenQuickViewModal(true);
        setSelectedOffer(offer);
      },
    },
    {
      label: 'Usuń',
      key: '4',
      icon: <DeleteOutlined />,
      onClick: () => handleDeleteOfferModal(offer),
    },
  ];

  return (
    <ListItemSC className='listItem'>
      <List.Item.Meta
        className='listItemMeta'
        title={
          <Flex justify='space-between'>
            <div>{offer.offerNr}</div>
            <Dropdown menu={{ items: getItemsForActionMenu(offer) }}>
              <Button
                type='text'
                aria-label='Pokaż więcej opcji'
                icon={<EllipsisOutlined />}
              />
            </Dropdown>
          </Flex>
        }
        description={
          <Flex
            gap='4px 0'
            wrap
          >
            <Tag color='magenta'>Produkty: {offer.productsInOrderResult}</Tag>
            <Tag color='purple'>{dayjs(offer.expiredAt).format('DD-MM-YYYY')}</Tag>
          </Flex>
        }
      />
      <DividerSC />
      <>
        <Flex
          justify='space-between'
          align='center'
        >
          <Text>Wartość zamówienia: {formatPrice(offer.price, offer.currency)}</Text>
          <Button
            type='text'
            aria-label='Pokaż więcej'
            onClick={() => showMorePrice(!morePrice)}
            icon={morePrice ? <UpOutlined /> : <DownOutlined />}
          ></Button>
        </Flex>
        {morePrice && (
          <Space direction='vertical'>
            <Text>
              Klient: <Text strong>{offer.companyName}</Text>
            </Text>
            <Text>
              Sprzedawca: <Text strong>{offer.sellerName}</Text>
            </Text>
          </Space>
        )}
      </>
    </ListItemSC>
  );
};
