import React, { FC, useState } from 'react';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AlignType, FixedType } from 'rc-table/lib/interface';
import { PartModel } from '../../reducers/parts/model';
import { useFilterAntTable } from '../../utils/customHooks/useFilterAntTable';
import { useGetPartBrands } from '../../services/apiClient/partsBrands/partQueries';
import { useGetPartCategories } from '../../services/apiClient/partsCategories/partQueries';
import { PartCategoryModel } from '../../reducers/partsCategories/model';
import { formatPrice } from '../../utils/formatPrice';
import { CurrencyType, OrderModel } from '../../reducers/orders/model';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DrawerAddEdit } from '../drawerAditEdit/DrawerAddEdit';
import { PartForm, PartFormValues } from '../../forms/part';
import { useDispatch } from 'react-redux';
import { addPartRequest, deletePartRequest, updatePartRequest } from '../../reducers/parts/actions';
import { PartPreview } from './PartPreview';
import { ProductStatus, ProductStatusListType } from '../../reducers/forklift/model';
import { getStatusProductIcon } from '../../utils/statusProductIcon';
import { OrderPreview } from '../orders/OrderPreview';
import { useGetOrders } from '../../services/apiClient/orders/orderQueries';

type PartListProp = {
  partsList: PartModel[];
  isFetchingPartsList: boolean;
  openPartModal: boolean;
  clickOpenPartModal: (openPartModal: boolean) => void;
  groupEditStatus: string[];
  setGroupEditStatus: (groupEditStatus: string[]) => void;
  selectedPartTab: ProductStatusListType;
};

export const PartsList: FC<PartListProp> = props => {
  const {
    partsList,
    isFetchingPartsList,
    clickOpenPartModal,
    openPartModal,
    groupEditStatus,
    setGroupEditStatus,
    selectedPartTab,
  } = props;
  const { data: brandsList = [] } = useGetPartBrands();
  const { data: categoriesList = [] } = useGetPartCategories();
  const { data: ordersList = [] } = useGetOrders();

  const [selectedPart, setSelectedPart] = useState<PartModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel | undefined>(undefined);
  const [openQuickViewOrderModal, setOpenQuickViewOrderModal] = useState(false);
  const [, setDeletePartModal] = useState(false);
  const { getColumnSearchProps, getColumnFilterProps, getColumnSearchRangeProps } = useFilterAntTable();
  const dispatch = useDispatch();

  const extendedPartsList = partsList.map(part => ({
    ...part,
    brandName: part.brand?.name,
    orderNr: part.order?.orderNr ?? '',
  }));

  const handleQuickViewPartModal = (partId: string) => {
    const part = partsList.find(item => item._id === partId);
    setSelectedPart(part);
    setOpenQuickViewModal(true);
  };

  const handleEditPartModal = (partId: string) => {
    const part = partsList.find(item => item._id === partId);
    setSelectedPart(part);
    clickOpenPartModal(true);
  };

  const handleDeletePartModal = (partId: string) => {
    dispatch(
      deletePartRequest(partId, () => {
        setDeletePartModal(false);
      }),
    );
  };

  const handleClosePartModal = () => {
    setSelectedPart(undefined);
    clickOpenPartModal(false);
  };

  const handleSubmit = (values: PartFormValues) => {
    if (selectedPart) {
      dispatch(
        updatePartRequest(
          {
            ...selectedPart,
            ...values,
          },
          () => {
            setSelectedPart(undefined);
            clickOpenPartModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addPartRequest(
          {
            ...values,
          },
          () => {
            setSelectedPart(undefined);
            clickOpenPartModal(false);
          },
        ),
      );
    }
  };

  const handleCloseQuickViewModal = () => {
    setSelectedPart(undefined);
    setOpenQuickViewModal(false);
  };

  const handleQuickViewOrderModal = (orderId: string) => {
    const selectedOrder = ordersList.find(order => order._id === orderId);
    setSelectedOrder(selectedOrder);
    setOpenQuickViewOrderModal(true);
  };

  const handleCloseQuickViewOrderModal = () => {
    setSelectedOrder(undefined);
    setOpenQuickViewOrderModal(false);
  };

  const columns: ColumnsType<PartModel> = [
    {
      title: 'Id',
      dataIndex: 'internalId',
      key: 'internalId',
      fixed: 'left' as FixedType,
      width: 100,
      ...getColumnSearchProps('internalId', 'Id'),
      sorter: (a: PartModel, b: PartModel) => a.internalId.localeCompare(b.internalId),
      hidden: false,
    },
    {
      title: 'Marka',
      dataIndex: 'brandName',
      key: 'brandName',
      ...getColumnFilterProps(
        'brandId',
        brandsList.map(brand => ({
          text: brand.name,
          value: brand._id,
        })),
      ),
      render: (brandName?: string) => <>{brandName}</>,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type', 'typu'),
      sorter: (a: PartModel, b: PartModel) => a.type.localeCompare(b.type),
    },
    {
      title: 'Nazwa',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', 'nazwy'),
      sorter: (a: PartModel, b: PartModel) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Kategoria',
      dataIndex: 'category',
      key: 'category',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnFilterProps(
        'categoryId',
        categoriesList.map(category => ({
          text: category.name,
          value: category._id,
        })),
      ),
      render: (category?: PartCategoryModel) => <>{category?.name}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as AlignType,
      ...getColumnFilterProps(
        'status',
        Object.values(ProductStatus).map(status => ({
          value: status,
          text: ProductStatus[status],
        })),
      ),
      hidden: false,
      render: (status: ProductStatus) => getStatusProductIcon(status),
      sorter: (a: PartModel, b: PartModel) => a.status.localeCompare(b.status),
    },
    {
      title: 'Rok produkcji',
      dataIndex: 'productionYear',
      key: 'productionYear',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('productionYear', 'rok produkcji'),
      sorter: (a: PartModel, b: PartModel) => a.productionYear - b.productionYear,
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('price', 'ceny'),
      render: (price: number) => formatPrice(price, CurrencyType.PLN),
      sorter: (a: PartModel, b: PartModel) => a.price - b.price,
    },
    {
      title: 'Cena hurt',
      dataIndex: 'priceWholesale',
      key: 'priceWholesale',
      align: 'center' as AlignType,
      hidden: false,
      ...getColumnSearchRangeProps('priceWholesale', 'ceny'),
      render: (priceWholesale: number) => formatPrice(priceWholesale, CurrencyType.EUR),
      sorter: (a: PartModel, b: PartModel) => a.priceWholesale - b.priceWholesale,
    },
    {
      title: 'Zamówienia',
      dataIndex: 'orderNr',
      key: 'orderNr',
      width: 150,
      ...getColumnSearchProps('orderNr', 'zamówienia'),
      render: (orderNr: string, part: PartModel) =>
        part.order && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <a
            onClick={e => {
              e.preventDefault();
              return part.order && handleQuickViewOrderModal(part.order._id);
            }}
          >
            {orderNr}
          </a>
        ),
      hidden: selectedPartTab === ProductStatusListType.AVAILABLE,
    },
    {
      title: 'Akcje',
      dataIndex: '_id',
      key: '_id',
      fixed: 'right' as FixedType,
      align: 'right' as AlignType,
      hidden: false,
      render: (_id: string, part: PartModel) => (
        <>
          <Tooltip
            title='Podgląd'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Zobacz podgląd'
              onClick={() => handleQuickViewPartModal(_id)}
              icon={<EyeOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Edytuj'
            placement='bottom'
          >
            <Button
              type='text'
              aria-label='Edytuj część'
              onClick={() => handleEditPartModal(_id)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip
            title='Usuń'
            placement='bottom'
          >
            <Popconfirm
              title='Usuwanie części'
              description='Czy na pewno chcesz usunąć osprzęt?'
              onConfirm={() => handleDeletePartModal(part._id)}
              onCancel={() => setDeletePartModal(false)}
              okText='Tak'
              cancelText='Nie'
            >
              <Button
                type='text'
                aria-label='Usuń część'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={extendedPartsList}
        loading={isFetchingPartsList}
        rowKey={record => record._id || ''}
        style={{ padding: '16px' }}
        rowSelection={{
          selectedRowKeys: groupEditStatus,
          onChange: (newSelectedRowKeys: React.Key[]) => setGroupEditStatus(newSelectedRowKeys as string[]),
          columnWidth: 30,
        }}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane części'
        titleAdd='Dodaj część'
        openModal={openPartModal}
        handleCloseModal={handleClosePartModal}
        selectedItem={selectedPart}
        width='80%'
      >
        <PartForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleClosePartModal}
          partId={selectedPart?._id}
        />
      </DrawerAddEdit>
      {selectedPart && (
        <DrawerAddEdit
          titleEdit={`Podgląd części: ${selectedPart.internalId}`}
          titleAdd={`Podgląd części: ${selectedPart.internalId}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedPart}
          width='80%'
        >
          <PartPreview partId={selectedPart._id} />
        </DrawerAddEdit>
      )}
      {selectedOrder && (
        <DrawerAddEdit
          titleEdit={`Podgląd zamówienia: ${selectedOrder.orderNr}`}
          titleAdd={`Podgląd zamówienia: ${selectedOrder.orderNr}`}
          openModal={openQuickViewOrderModal}
          handleCloseModal={handleCloseQuickViewOrderModal}
          selectedItem={selectedOrder}
          width='60%'
        >
          <OrderPreview orderId={selectedOrder._id} />
        </DrawerAddEdit>
      )}
    </>
  );
};
