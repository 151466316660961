import { useQuery } from 'react-query';
import { apiClientForklifts } from './index';

const queryKeys = {
  getForklift: (id: string) => `forklifts/${id}`,
  getAvailableForklifts: 'forklifts/available',
  getForklifts: 'forklifts',
};

export const useGetForklift = (id?: string) => {
  return useQuery(
    queryKeys.getForklift(id as string),
    async () => {
      const response = await apiClientForklifts.getForklift(id as string);
      return response.data;
    },
    { enabled: !!id },
  );
};

export const useGetAvailableForklifts = () => {
  return useQuery(
    queryKeys.getAvailableForklifts,
    async () => {
      const response = await apiClientForklifts.getAvailableForklifts();
      return response.data;
    },
    { initialData: [] },
  );
};

export const useGetForklifts = () => {
  return useQuery(queryKeys.getForklifts, async () => {
    const response = await apiClientForklifts.getForklifts();
    return response.data;
  });
};
