import { call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { notification } from 'antd';
import { ActionType } from 'typesafe-actions';

import {
  addPartCategoryError,
  addPartCategoryRequest,
  addPartCategorySuccess,
  deletePartCategoryError,
  deletePartCategoryRequest,
  deletePartCategorySuccess,
  getPartsCategoriesError,
  getPartsCategoriesSuccess,
  updatePartCategoryError,
  updatePartCategoryRequest,
  updatePartCategorySuccess,
} from './actions';
import * as CONST from './consts';
import { PartCategoryModel } from './model';
import { ResponseModel } from '../model';
import { getErrorMessage } from '../../utils/error';
import { apiClientPartCategories } from '../../services/apiClient/partsCategories';

function* getPartsCategories(): Generator<CallEffect | PutEffect, void, ResponseModel<PartCategoryModel[]>> {
  try {
    const response = yield call(apiClientPartCategories.getPartsCategories);

    yield put(getPartsCategoriesSuccess(response.data));
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during get parts categories',
      description: getErrorMessage(err),
    });
    yield put(getPartsCategoriesError(err));
  }
}

function* addPartCategory(
  action: ActionType<typeof addPartCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartCategoryModel>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiClientPartCategories.addPartCategory, category);

    yield put(addPartCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during adding part category',
      description: getErrorMessage(err),
    });
    yield put(addPartCategoryError(err));
  }
}

function* updatePartCategory(
  action: ActionType<typeof updatePartCategoryRequest>,
): Generator<CallEffect | PutEffect, void, ResponseModel<PartCategoryModel>> {
  try {
    const { category, onSuccess } = action.payload;
    const response = yield call(apiClientPartCategories.updatePartCategory, category);

    yield put(updatePartCategorySuccess(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during updating part category',
      description: getErrorMessage(err),
    });
    yield put(updatePartCategoryError(err));
  }
}

function* deletePartCategory(
  action: ActionType<typeof deletePartCategoryRequest>,
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { categoryId, onSuccess } = action.payload;
    yield call(apiClientPartCategories.deletePartCategory, categoryId);

    yield put(deletePartCategorySuccess(categoryId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    notification.error({
      duration: 0,
      message: 'Error during deleting part category',
      description: getErrorMessage(err),
    });
    yield put(deletePartCategoryError(err));
  }
}

export function* watchPartCategoriesSaga(): Generator {
  yield takeLatest(CONST.GET_PART_CATEGORIES_REQUEST, getPartsCategories);
  yield takeLatest(CONST.ADD_PART_CATEGORY_REQUEST, addPartCategory);
  yield takeLatest(CONST.UPDATE_PART_CATEGORY_REQUEST, updatePartCategory);
  yield takeLatest(CONST.DELETE_PART_CATEGORY_REQUEST, deletePartCategory);
}
