import styled from 'styled-components';
import { Divider, List } from 'antd';

import { EquipmentModel } from '../../../reducers/equipment/model';

export const ListSC = styled(List<EquipmentModel>)`
  padding: 0 16px 16px 16px;

  &.mobileProductList li.listItem .listItemMeta h4 {
    margin-block-end: 0;
  }
`;

export const DividerSC = styled(Divider)`
  margin: 12px 0 7px 0;
`;

export const ListItemSC = styled(List.Item)`
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;

  .secondaryText {
    font-size: 12px;
    margin-left: 8px;
  }
`;
