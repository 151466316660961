import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { PartCategoryModel } from '../../../reducers/partsCategories/model';

class APIClientPartCategories extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/part-categories`;
  }

  public getPartsCategories = async (): Promise<ResponseModel<PartCategoryModel[]>> => {
    return this.authFetch<ResponseModel<PartCategoryModel[]>>(this.apiURL, { method: 'GET' });
  };

  public addPartCategory = async (
    data: Omit<PartCategoryModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  ): Promise<ResponseModel<PartCategoryModel>> => {
    return this.authFetch<ResponseModel<PartCategoryModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updatePartCategory = async (data: Partial<PartCategoryModel>): Promise<ResponseModel<PartCategoryModel>> => {
    return this.authFetch<ResponseModel<PartCategoryModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deletePartCategory = async (brandId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${brandId}`, { method: 'DELETE' });
  };
}

export const apiClientPartCategories = new APIClientPartCategories();
