import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBrush,
  faCamera,
  faHandshake,
  faTruck,
  faTruckLoading,
  faTruckArrowRight,
  faWarehouse,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { ProductStatus, ProductStatusNames } from '../reducers/forklift/model';
import { Tooltip } from 'antd';

export const getStatusProductIcon = (status: string) => {
  switch (status) {
    case ProductStatus.WORKSHOP:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.WORKSHOP]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faWrench}
          />
        </Tooltip>
      );
    case ProductStatus.PAINTSHOP:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.PAINTSHOP]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faBrush}
          />
        </Tooltip>
      );
    case ProductStatus.TRANSPORT_TO_ME:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.TRANSPORT_TO_ME]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faTruck}
          />
        </Tooltip>
      );
    case ProductStatus.TO_TRANSPORT_TO_CLIENT:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.TO_TRANSPORT_TO_CLIENT]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faTruckLoading}
          />
        </Tooltip>
      );
    case ProductStatus.TRANSPORT_TO_CLIENT:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.TRANSPORT_TO_CLIENT]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faTruckArrowRight}
          />
        </Tooltip>
      );
    case ProductStatus.MAGAZINE:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.MAGAZINE]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faWarehouse}
          />
        </Tooltip>
      );
    case ProductStatus.PHOTO:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.PHOTO]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faCamera}
          />
        </Tooltip>
      );
    case ProductStatus.GOT_BY_CLIENT:
      return (
        <Tooltip title={ProductStatusNames[ProductStatus.GOT_BY_CLIENT]}>
          <FontAwesomeIcon
            size='lg'
            className='blueColor'
            icon={faHandshake}
          />
        </Tooltip>
      );

    default:
      return <></>;
  }
};
