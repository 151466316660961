import { useQuery } from 'react-query';
import { apiClientAdvancePayments } from './index';

const queryKeys = {
  getOrderAdvancePayments: (orderId: string) => `/advance-payments/orders/${orderId}`,
};

export const useGetOrderAdvancePayments = (orderId: string) => {
  return useQuery(queryKeys.getOrderAdvancePayments(orderId), async () => {
    const response = await apiClientAdvancePayments.getOrderAdvancePayments(orderId);

    return response.data;
  });
};
