import styled from 'styled-components';

export const FloatingActionButtonSC = styled.div`
  position: fixed;
  bottom: 34px;
  right: 34px;
  text-align: right;
  z-index: 10;

  .moreButtons {
    align-items: center;
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 10px;
    }

    &.rightSide {
      align-items: end;
    }
  }

  .mainFloatingButton:hover {
    opacity: 1;
  }

  .hide {
    opacity: 0.7;
  }

  .show {
    opacity: 1;
  }
`;
