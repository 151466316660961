import styled from 'styled-components';
import { Table } from 'antd';
import { ForkliftDataModel } from './utils';

export const TableSC = styled(Table<ForkliftDataModel>)`
  padding: 0 16px 16px 16px;
  .blue {
    background: #deeef6;
    td {
      background: #deeef6;
    }
  }
  .yellow {
    background: #ffffd6;
    td {
      background: #ffffd6;
    }
  }
  .ant-table-row.blue > .ant-table-cell-row-hover {
    background: #d0e9f5;
  }
  .ant-table-row.yellow > .ant-table-cell-row-hover {
    background: #fbfbd0;
  }
`;
