import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Col, DatePicker, Form, Image, Input, Row, Select, Space, Tooltip } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { initFormik } from './formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { CurrencyType, CurrencyTypeNames, ProductType } from '../../reducers/offers/model';
import { ClientModel } from '../../reducers/clients/model';
import { addClientRequest, getClientsRequest } from '../../reducers/clients/actions';
import { ClientForm, ClientFormValues } from '../client';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { formatPrice } from '../../utils/formatPrice';
import { OfferModel, ProductInOffer } from '../../reducers/offers/model';
import { getOfferPDFRequest } from '../../reducers/offers/actions';
import { useGetAvailableEquipments } from '../../services/apiClient/equipments/equipmentQueries';
import { useGetAvailableParts } from '../../services/apiClient/parts/partQueries';
import { useGetAvailableForklifts } from '../../services/apiClient/forklifts/forkliftQueries';
import { AvailableProducts } from '../../reducers/orders/model';
import { AssetModel } from '../../reducers/assets/model';
import { useViewport } from '../../utils/customHooks/useViewport';
import { useGetOffer } from '../../services/apiClient/offers/offerQqueries';

import './style.scss';

const { Item } = Form;
const { TextArea } = Input;

export type OfferFormValues = {
  offerNr: string;
  clientId: string;
  productsInOffer: ProductInOffer[];
  notes?: string;
  currency: CurrencyType;
  expiredAt: string;
};

export type OfferFormProps = {
  offer?: Partial<OfferModel>;
  handleSubmit: (values: OfferFormValues) => void;
  handleCancelForm: () => void;
};

export const OfferForm: FC<OfferFormProps> = props => {
  const { offer, handleCancelForm } = props;
  const isFetching = useSelector<RootReducerState, boolean>(state => state.offers.isFetching);
  const clientsList = useSelector<RootReducerState, ClientModel[]>(state => state.clients.clientsList);
  const [openClientModal, setOpenClientModal] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik(initFormik(props));
  const { isMobile } = useViewport();

  const { data: availableForkliftsList = [] } = useGetAvailableForklifts();
  const { data: availableEquipmentsList = [] } = useGetAvailableEquipments();
  const { data: availablePartsList = [] } = useGetAvailableParts();
  const { data: editedOffer, isLoading: isLoadingOffer } = useGetOffer(offer?._id);

  useEffect(() => {
    dispatch(getClientsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!offer?._id) {
      const currency = formik.values.currency as CurrencyType | undefined;
      if (currency) {
        const productsInOffer = formik.values.productsInOffer.map(product => {
          if (product.type === ProductType.EQUIPMENT) {
            const equipment = availableEquipmentsList.find(item => item._id === product.productId);
            if (equipment) {
              return {
                ...product,
                price: currency === CurrencyType.PLN ? equipment.price : equipment.priceWholesale,
              };
            }
          } else if (product.type === ProductType.PART) {
            const part = availablePartsList.find(item => item._id === product.productId);
            if (part) {
              return {
                ...product,
                price: currency === CurrencyType.PLN ? part.price : part.priceWholesale,
              };
            }
          } else {
            const forklift = availableForkliftsList.find(item => item._id === product.productId);
            if (forklift) {
              return {
                ...product,
                price: currency === CurrencyType.PLN ? forklift.priceSuggested : forklift.priceWholesaleSuggested,
              };
            }
          }

          return product;
        });
        void formik.setFieldValue('productsInOffer', productsInOffer);
      }
    }
    // eslint-disable-next-line
  }, [formik.values.currency, availableForkliftsList, availableEquipmentsList, availablePartsList]);

  const availableProducts = useMemo(() => {
    return [
      ...availableForkliftsList,
      ...availableEquipmentsList,
      ...availablePartsList,
      ...(editedOffer?.products ?? []),
    ] as AvailableProducts[];
  }, [availableEquipmentsList, availableForkliftsList, availablePartsList, editedOffer]);

  const handleSubmitClient = (values: ClientFormValues) => {
    dispatch(
      addClientRequest(values, client => {
        setOpenClientModal(false);
        void formik.setFieldValue('clientId', client?._id);
      }),
    );
  };

  const onChangeExpiredAt = async (value: dayjs.Dayjs) => {
    await formik.setFieldValue('expiredAt', dayjs(value.toISOString()));
  };

  return (
    <div className='wrapperForm'>
      <Form layout='vertical'>
        {offer?.offerNr && (
          <Item
            label='Nr oferty'
            required={true}
            hasFeedback={true}
            validateStatus={formik.errors.offerNr && formik.touched.offerNr ? 'error' : ''}
            help={formik.errors.offerNr && formik.touched.offerNr ? formik.errors.offerNr : null}
          >
            <Input
              name='offerNr'
              value={formik.values.offerNr}
              placeholder='Nr oferty'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Item>
        )}
        <Row
          justify='space-between'
          align='middle'
        >
          <Col
            xs={18}
            md={19}
            lg={20}
            xl={21}
          >
            <Item
              label='Klient'
              hasFeedback={true}
              validateStatus={formik.errors.clientId && formik.touched.clientId ? 'error' : ''}
              help={formik.errors.clientId && formik.touched.clientId ? formik.errors.clientId : null}
            >
              <Select
                showSearch
                value={formik.values.clientId}
                onChange={value => formik.setFieldValue('clientId', value)}
                onBlur={() => formik.setFieldTouched('clientId')}
                options={clientsList.map(client => ({
                  label: client.companyName,
                  value: client._id,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Item>
          </Col>
          <Col
            xs={6}
            md={5}
            lg={4}
            xl={3}
            className='right'
          >
            <Button onClick={() => setOpenClientModal(true)}>Dodaj klienta</Button>
          </Col>
        </Row>
        <Item
          label='Uwagi'
          hasFeedback={true}
          validateStatus={formik.errors.notes && formik.touched.notes ? 'error' : ''}
          help={formik.errors.notes && formik.touched.notes ? formik.errors.notes : null}
        >
          <TextArea
            value={formik.values.notes}
            name='notes'
            showCount
            maxLength={1000}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Uwagi'
          />
        </Item>
        <Item
          label='Waluta'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.currency && formik.touched.currency ? 'error' : ''}
          help={formik.errors.currency && formik.touched.currency ? formik.errors.currency : null}
        >
          <Select
            value={formik.values.currency}
            onChange={value => formik.setFieldValue('currency', value)}
            onBlur={() => formik.setFieldTouched('currency')}
            options={Object.values(CurrencyType).map(currency => ({
              label: CurrencyTypeNames[currency],
              value: currency,
            }))}
          />
        </Item>
        <Item
          label='Data ważności oferty'
          required={true}
          hasFeedback={true}
          validateStatus={formik.errors.expiredAt && formik.touched.expiredAt ? 'error' : ''}
          help={formik.errors.expiredAt && formik.touched.expiredAt ? formik.errors.expiredAt : null}
        >
          <DatePicker
            defaultValue={dayjs().add(14, 'days')}
            value={formik.values.expiredAt ? dayjs(formik.values.expiredAt) : dayjs().add(14, 'days')}
            format='DD/MM/YYYY'
            onChange={value => onChangeExpiredAt(value)}
          />
        </Item>
        <Form.List
          name='productsInOffer'
          initialValue={formik.values.productsInOffer}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => {
                const productId = formik.values.productsInOffer[name]?.productId;

                let productAsset: AssetModel | undefined;
                if (formik.values.productsInOffer[name]?.type === ProductType.FORKLIFT) {
                  productAsset = availableForkliftsList.find(forkliftItem => forkliftItem._id === productId)?.assets[0];
                } else if (formik.values.productsInOffer[name]?.type === ProductType.EQUIPMENT) {
                  productAsset = availableEquipmentsList.find(equipmentItem => equipmentItem._id === productId)
                    ?.assets[0];
                } else if (formik.values.productsInOffer[name]?.type === ProductType.PART) {
                  productAsset = availablePartsList.find(partItem => partItem._id === productId)?.assets[0];
                }

                return (
                  <Row key={key}>
                    <Col span={24}>
                      <Row gutter={12}>
                        <Col span={2}>
                          {productAsset ? (
                            <Image
                              width={88}
                              src={`${process.env.REACT_APP_HOST_ASSETS_URL ?? ''}${productAsset.uri}`}
                            />
                          ) : (
                            <Image
                              preview={false}
                              src='../images/placeholder.png'
                              width={80}
                            />
                          )}
                        </Col>
                        <Col span={9}>
                          <Item
                            label={`Produkt ${name + 1}`}
                            name={[name, 'productId']}
                            required={true}
                            validateStatus={
                              // @ts-expect-error
                              formik.errors.productsInOffer?.[name].productId ? 'error' : ''
                            }
                            help={
                              // @ts-expect-error
                              formik.errors.productsInOffer?.[name].productId
                                ? // @ts-expect-error
                                  formik.errors.productsInOffer[name].productId
                                : null
                            }
                          >
                            <Select
                              showSearch
                              placeholder='Wybierz produkt'
                              optionFilterProp='children'
                              value={formik.values.productsInOffer[name]?.productId}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              onChange={value => {
                                const forklift = availableForkliftsList.find(element => element._id === value);
                                const equipment = availableEquipmentsList.find(element => element._id === value);
                                const part = availablePartsList.find(element => element._id === value);
                                if (forklift) {
                                  void formik.setFieldValue(
                                    `productsInOffer[${name}].price`,
                                    formik.values.currency === CurrencyType.PLN
                                      ? forklift.priceSuggested
                                      : forklift.priceWholesaleSuggested,
                                    false,
                                  );
                                  void formik.setFieldValue(
                                    `productsInOffer[${name}].type`,
                                    ProductType.FORKLIFT,
                                    false,
                                  );
                                }
                                if (part) {
                                  void formik.setFieldValue(
                                    `productsInOffer[${name}].price`,
                                    formik.values.currency === CurrencyType.PLN ? part.price : part.priceWholesale,
                                    false,
                                  );
                                  void formik.setFieldValue(`productsInOffer[${name}].type`, ProductType.PART, false);
                                }
                                if (equipment) {
                                  void formik.setFieldValue(
                                    `productsInOffer[${name}].price`,
                                    formik.values.currency === CurrencyType.PLN
                                      ? equipment.price
                                      : equipment.priceWholesale,
                                    false,
                                  );
                                  void formik.setFieldValue(
                                    `productsInOffer[${name}].type`,
                                    ProductType.EQUIPMENT,
                                    false,
                                  );
                                }
                                return formik.setFieldValue(`productsInOffer[${name}].productId`, value, false);
                              }}
                              options={availableProducts.map(product => ({
                                value: product._id,
                                label: `${product.internalId} - ${product.brand?.name ?? ''} ${
                                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                  product.serialNumber ? `- ${product.serialNumber}` : ''
                                }`,
                              }))}
                            />
                          </Item>
                        </Col>
                        <Col span={13}>
                          <Row gutter={8}>
                            <Col span={11}>
                              <Item
                                label='Cena końcowa netto'
                                required={true}
                                validateStatus={
                                  // @ts-expect-error
                                  formik.errors.productsInOffer?.[name].price ? 'error' : ''
                                }
                                help={
                                  // @ts-expect-error
                                  formik.errors.productsInOffer?.[name].price
                                    ? // @ts-expect-error
                                      formik.errors.productsInOffer[name].price
                                    : null
                                }
                              >
                                <Input
                                  addonAfter={CurrencyTypeNames[formik.values.currency]}
                                  type='number'
                                  placeholder='Cena końcowa netto'
                                  value={formik.values.productsInOffer[name]?.price}
                                  onChange={event =>
                                    formik.setFieldValue(`productsInOffer[${name}].price`, event.target.value)
                                  }
                                  onBlur={() => formik.setFieldTouched(`productsInOffer[${name}].price`)}
                                />
                              </Item>
                            </Col>
                            <Col span={13}>
                              <Row
                                align='middle'
                                gutter={8}
                              >
                                <Col span={23}>
                                  <Item
                                    label='Uwagi'
                                    hasFeedback={true}
                                    validateStatus={
                                      // @ts-expect-error
                                      formik.errors.productsInOffer?.[name].notes &&
                                      formik.touched.productsInOffer?.[name].notes
                                        ? 'error'
                                        : ''
                                    }
                                    help={
                                      // @ts-expect-error
                                      formik.errors.productsInOffer?.[name].notes &&
                                      formik.touched.productsInOffer?.[name].notes
                                        ? // @ts-expect-error
                                          formik.errors.productsInOffer[name].notes
                                        : null
                                    }
                                  >
                                    <TextArea
                                      name='notes'
                                      showCount
                                      maxLength={1000}
                                      onChange={event =>
                                        formik.setFieldValue(`productsInOffer[${name}].notes`, event.target.value)
                                      }
                                      placeholder='Uwagi'
                                      value={formik.values.productsInOffer[name]?.notes}
                                    />
                                  </Item>
                                </Col>
                                <Col span={1}>
                                  <DeleteOutlined
                                    onClick={() => {
                                      const products = [...formik.values.productsInOffer];
                                      products.splice(name, 1);
                                      void formik.setFieldValue('productsInOffer', products);
                                      remove(name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
              <Item
                className='addProduct'
                validateStatus={
                  typeof formik.errors.productsInOffer === 'string' && formik.errors.productsInOffer !== ''
                    ? 'error'
                    : ''
                }
                help={typeof formik.errors.productsInOffer === 'string' && formik.errors.productsInOffer}
              >
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Dodaj produkt
                </Button>
              </Item>
              <Row
                className='finalPrice'
                justify='end'
              >
                Wartość oferty netto:{' '}
                {formatPrice(
                  formik.values.productsInOffer.reduce((total, item) => {
                    return total + +item.price;
                  }, 0),
                  formik.values.currency,
                )}
              </Row>
            </>
          )}
        </Form.List>
        <Row justify='space-between'>
          <Space>
            <Button onClick={handleCancelForm}>Anuluj</Button>
          </Space>
          <Space>
            {offer?._id && (
              <Tooltip title={formik.dirty ? 'Oferta jest w trakcie edycji, zapisz aby kontynuować' : undefined}>
                <Button
                  disabled={formik.dirty}
                  onClick={() => dispatch(getOfferPDFRequest(offer._id ?? ''))}
                  loading={isFetching || isLoadingOffer}
                >
                  Generuj PDF
                </Button>
              </Tooltip>
            )}
            <Button
              type='primary'
              htmlType='submit'
              loading={isFetching || isLoadingOffer}
              onClick={formik.submitForm}
            >
              {offer ? 'Zapisz' : 'Utwórz'}
            </Button>
          </Space>
        </Row>
      </Form>
      <DrawerAddEdit
        titleEdit='Edytuj dane klienta'
        titleAdd='Dodaj klienta'
        openModal={openClientModal}
        handleCloseModal={() => setOpenClientModal(false)}
        width={isMobile ? '100%' : '50%'}
      >
        <ClientForm
          handleSubmit={handleSubmitClient}
          handleCancelForm={() => setOpenClientModal(false)}
        />
      </DrawerAddEdit>
    </div>
  );
};
