export const GET_PART_BRANDS_REQUEST = '@partsBrands/GET_PART_BRANDS_REQUEST';
export const GET_PART_BRANDS_SUCCESS = '@partsBrands/GET_PART_BRANDS_SUCCESS';
export const GET_PART_BRANDS_ERROR = '@partsBrands/GET_PART_BRANDS_ERROR';

export const ADD_PART_BRAND_REQUEST = '@partsBrands/ADD_PART_BRAND_REQUEST';
export const ADD_PART_BRAND_SUCCESS = '@partsBrands/ADD_PART_BRAND_SUCCESS';
export const ADD_PART_BRAND_ERROR = '@partsBrands/ADD_PART_BRAND_ERROR';

export const UPDATE_PART_BRAND_REQUEST = '@partsBrands/UPDATE_PART_BRAND_REQUEST';
export const UPDATE_PART_BRAND_SUCCESS = '@partsBrands/UPDATE_PART_BRAND_SUCCESS';
export const UPDATE_PART_BRAND_ERROR = '@partsBrands/UPDATE_PART_BRAND_ERROR';

export const DELETE_PART_BRAND_REQUEST = '@partsBrands/DELETE_PART_BRAND_REQUEST';
export const DELETE_PART_BRAND_SUCCESS = '@partsBrands/DELETE_PART_BRAND_SUCCESS';
export const DELETE_PART_BRAND_ERROR = '@partsBrands/DELETE_PART_BRAND_ERROR';
