import { BaseAPI } from '../base';
import { ResponseModel } from '../../../reducers/model';
import { PartModel, PartInternalId, PartStatusesModel } from '../../../reducers/parts/model';
import { prepareFilterQueryString } from '../../../utils/queryStringFiltering';
import { ProductStatusListType } from '../../../reducers/forklift/model';

class APIClientParts extends BaseAPI {
  private readonly apiURL: string;

  constructor() {
    super();

    this.apiURL = `${this.hostAPI}/parts`;
  }

  public getParts = async (): Promise<ResponseModel<PartModel[]>> => {
    return this.authFetch<ResponseModel<PartModel[]>>(this.apiURL, { method: 'GET' });
  };

  public getAvailableParts = async (): Promise<ResponseModel<PartModel[]>> => {
    return this.authFetch<ResponseModel<PartModel[]>>(
      `${this.apiURL}/${prepareFilterQueryString({ filters: {}, productStatusTab: ProductStatusListType.AVAILABLE })}`,
      { method: 'GET' },
    );
  };

  public addPart = async (
    data: Omit<PartModel, '_id' | 'createdAt' | 'deleted' | 'updatedAt' | 'order' | 'costs'>,
  ): Promise<ResponseModel<PartModel>> => {
    return this.authFetch<ResponseModel<PartModel>>(this.apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  public updatePart = async (data: Partial<PartModel>): Promise<ResponseModel<PartModel>> => {
    delete data.brand;
    delete data.category;
    delete data.order;
    return this.authFetch<ResponseModel<PartModel>>(this.apiURL, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public deletePart = async (partId: string): Promise<void> => {
    return this.authFetch(`${this.apiURL}/${partId}`, { method: 'DELETE' });
  };

  public updatePartStatus = async (data: PartStatusesModel): Promise<ResponseModel<PartModel[]>> => {
    return this.authFetch<ResponseModel<PartModel[]>>(`${this.apiURL}/statuses`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  public getPart = async (partId: string): Promise<ResponseModel<PartModel>> => {
    return this.authFetch<ResponseModel<PartModel>>(`${this.apiURL}/${partId}`, { method: 'GET' });
  };

  public getPartInternalId = async (): Promise<ResponseModel<PartInternalId>> => {
    return this.authFetch<ResponseModel<PartInternalId>>(`${this.apiURL}/internal-id`, { method: 'GET' });
  };

  public getStockList = async (): Promise<ArrayBuffer> => {
    return this.authFetchFileAsArrayBuffer(`${this.apiURL}/stock-list`, { method: 'GET' });
  };
}

export const apiClientParts = new APIClientParts();
