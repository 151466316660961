import React, { useState } from 'react';
import { LayoutMain } from '../../layout/main/Main';
import { PartsBrandsHeader } from './PartsBrandsHeader';
import { PartsBrandsList } from '../../component/partsBrands/PartsBrandsList';

export const PartsBrandsPage = () => {
  const [openPartBrandModal, setOpenPartBrandModal] = useState(false);

  return (
    <LayoutMain>
      <PartsBrandsHeader onSetOpenPartBrandModal={setOpenPartBrandModal} />
      <PartsBrandsList
        openPartBrandModal={openPartBrandModal}
        clickOpenPartBrandModal={openBrandModal => setOpenPartBrandModal(openBrandModal)}
      />
    </LayoutMain>
  );
};
