export const GET_PARTS_REQUEST = '@parts/GET_PARTS_REQUEST';
export const GET_PARTS_SUCCESS = '@parts/GET_PARTS_SUCCESS';
export const GET_PARTS_ERROR = '@parts/GET_PARTS_ERROR';

export const GET_AVAILABLE_PARTS_REQUEST = '@parts/GET_AVAILABLE_PARTS_PARTS_REQUEST';
export const GET_AVAILABLE_PARTS_SUCCESS = '@parts/GET_AVAILABLE_PARTS_PARTS_SUCCESS';
export const GET_AVAILABLE_PARTS_ERROR = '@parts/GET_AVAILABLE_PARTS_PARTS_ERROR';

export const ADD_PART_REQUEST = '@parts/ADD_PART_REQUEST';
export const ADD_PART_SUCCESS = '@parts/ADD_PART_SUCCESS';
export const ADD_PART_ERROR = '@parts/ADD_PART_ERROR';

export const UPDATE_PART_REQUEST = '@parts/UPDATE_PART_REQUEST';
export const UPDATE_PART_SUCCESS = '@parts/UPDATE_PART_SUCCESS';
export const UPDATE_PART_ERROR = '@parts/UPDATE_PART_ERROR';

export const DELETE_PART_REQUEST = '@parts/DELETE_PART_REQUEST';
export const DELETE_PART_SUCCESS = '@parts/DELETE_PART_SUCCESS';
export const DELETE_PART_ERROR = '@parts/DELETE_PART_ERROR';

export const UPDATE_PARTS_STATUSES_REQUEST = '@parts/UPDATE_PARTS_STATUSES_REQUEST';
export const UPDATE_PARTS_STATUSES_SUCCESS = '@parts/UPDATE_PARTS_STATUSES_SUCCESS';
export const UPDATE_PARTS_STATUSES_ERROR = '@parts/UPDATE_PARTS_STATUSES_ERROR';

export const GET_PART_REQUEST = '@parts/GET_PART_REQUEST';
export const GET_PART_SUCCESS = '@parts/GET_PART_SUCCESS';
export const GET_PART_ERROR = '@parts/GET_PART_ERROR';

export const GET_PART_INTERNAL_ID_REQUEST = '@parts/GET_PART_INTERNAL_ID_REQUEST';
export const GET_PART_INTERNAL_ID_SUCCESS = '@parts/GET_PART_INTERNAL_ID_SUCCESS';
export const GET_PART_INTERNAL_ID_ERROR = '@parts/GET_PART_INTERNAL_ID_ERROR';

export const GET_PARTS_STOCK_LIST_REQUEST = '@parts/GET_PARTS_STOCK_LIST_REQUEST';
export const GET_PARTS_STOCK_LIST_SUCCESS = '@parts/GET_PARTS_STOCK_LIST_SUCCESS';
export const GET_PARTS_STOCK_LIST_ERROR = '@parts/GET_PARTS_STOCK_LIST_ERROR';
