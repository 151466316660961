import { Tag } from 'antd';
import { ProductStatus, ProductStatusNames } from '../../../reducers/forklift/model';
import { FC } from 'react';

type StatusProductTagProp = {
  status: ProductStatus;
};

export const StatusProductTag: FC<StatusProductTagProp> = ({ status }) => {
  const tagColor = (status: ProductStatus) => {
    switch (status) {
      case ProductStatus.TRANSPORT_TO_ME:
        return 'red';
      case ProductStatus.TO_TRANSPORT_TO_CLIENT:
        return 'green';
      case ProductStatus.TRANSPORT_TO_CLIENT:
        return 'green';
      case ProductStatus.GOT_BY_CLIENT:
        return 'green';
      default:
        return 'gold';
    }
  };

  return <Tag color={tagColor(status)}>{ProductStatusNames[status]}</Tag>;
};
