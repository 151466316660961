import { action } from 'typesafe-actions';

import * as CONST from './consts';
import { PartCategoryModel } from './model';

export const getPartsCategoriesRequest = () => action(CONST.GET_PART_CATEGORIES_REQUEST);
export const getPartsCategoriesSuccess = (categories: PartCategoryModel[]) =>
  action(CONST.GET_PART_CATEGORIES_SUCCESS, { categories });
export const getPartsCategoriesError = (error: unknown) => action(CONST.GET_PART_CATEGORIES_ERROR, { error });

export const addPartCategoryRequest = (
  category: Omit<PartCategoryModel, '_id' | 'createdAt' | 'updatedAt' | 'deleted'>,
  onSuccess?: (category?: PartCategoryModel) => void,
) => action(CONST.ADD_PART_CATEGORY_REQUEST, { category, onSuccess });
export const addPartCategorySuccess = (category: PartCategoryModel) =>
  action(CONST.ADD_PART_CATEGORY_SUCCESS, { category });
export const addPartCategoryError = (error: unknown) => action(CONST.ADD_PART_CATEGORY_ERROR, { error });

export const updatePartCategoryRequest = (category: PartCategoryModel, onSuccess?: () => void) =>
  action(CONST.UPDATE_PART_CATEGORY_REQUEST, { category, onSuccess });
export const updatePartCategorySuccess = (category: PartCategoryModel) =>
  action(CONST.UPDATE_PART_CATEGORY_SUCCESS, { category });
export const updatePartCategoryError = (error: unknown) => action(CONST.UPDATE_PART_CATEGORY_ERROR, { error });

export const deletePartCategoryRequest = (categoryId: string, onSuccess?: () => void) =>
  action(CONST.DELETE_PART_CATEGORY_REQUEST, { categoryId, onSuccess });
export const deletePartCategorySuccess = (categoryId: string) =>
  action(CONST.DELETE_PART_CATEGORY_SUCCESS, { categoryId });
export const deletePartCategoryError = (error: unknown) => action(CONST.DELETE_PART_CATEGORY_ERROR, { error });
