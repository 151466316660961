import React, { useEffect, useMemo, useState } from 'react';

import { LayoutMain } from '../../layout/main/Main';
import { PartsList } from '../../component/parts/PartsList';
import { PartsHeader } from './PartsHeader';
import { useViewport } from '../../utils/customHooks/useViewport';
import { PartMobileList } from '../../component/parts/mobile/PartMobileList';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../reducers';
import { PartModel } from '../../reducers/parts/model';
import { getPartsRequest } from '../../reducers/parts/actions';
import { PartsTabs } from './PartsTabs';
import { isProductStatus } from '../../utils/productsStatus';
import { ProductStatusListType } from '../../reducers/forklift/model';
import { FloatingActionButton } from '../../layout/buttons/FloatingActionButton';
import { OrderForm, OrderFormValues } from '../../forms/orders';
import { ProductType } from '../../reducers/orders/model';
import { DrawerAddEdit } from '../../component/drawerAditEdit/DrawerAddEdit';
import { addOrderRequest } from '../../reducers/orders/actions';

export const PartsPage = () => {
  const partsList = useSelector<RootReducerState, PartModel[]>(state => state.parts.partsList);
  const isFetching = useSelector<RootReducerState, boolean>(state => state.parts.isFetching);
  const [status, setStatus] = useState<ProductStatusListType>(ProductStatusListType.AVAILABLE);
  const [groupEditParts, setGroupEditParts] = useState<string[]>([]);
  const [openPartModal, setOpenPartModal] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [searchPart, setSearchPart] = useState('');
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartsRequest());
  }, [dispatch]);

  const { partsSold, partsReserved, partsAvailable } = useMemo(() => {
    const partsSoldAmount = partsList.filter(part => isProductStatus(part, ProductStatusListType.SOLD));
    const partsReserved = partsList.filter(part => isProductStatus(part, ProductStatusListType.IN_ORDER_RESERVED));
    const partsAvailable = partsList.filter(part => isProductStatus(part, ProductStatusListType.AVAILABLE));

    return {
      partsSold: partsSoldAmount,
      partsReserved: partsReserved,
      partsAvailable: partsAvailable,
    };
  }, [partsList]);

  const filteredPartList = useMemo(() => {
    let filteredPartList: PartModel[] = [];

    switch (status) {
      case ProductStatusListType.ALL:
        filteredPartList = partsList;
        break;
      case ProductStatusListType.AVAILABLE:
        filteredPartList = partsAvailable;
        break;
      case ProductStatusListType.IN_ORDER_RESERVED:
        filteredPartList = partsReserved;
        break;
      case ProductStatusListType.SOLD:
        filteredPartList = partsSold;
        break;
    }
    if (searchPart) {
      return filteredPartList.filter(
        part =>
          part.internalId.toLocaleLowerCase().includes(searchPart.toLocaleLowerCase()) ||
          part.name?.toLocaleLowerCase().includes(searchPart.toLocaleLowerCase()),
      );
    }

    return filteredPartList;
  }, [status, partsList, partsAvailable, partsReserved, partsSold, searchPart]);

  const selectedParts = partsList.filter(part => groupEditParts.includes(part._id));

  const handleSubmit = (values: OrderFormValues) => {
    dispatch(
      addOrderRequest(
        {
          ...values,
        },
        () => {
          setOpenOrderModal(false);
        },
      ),
    );
  };

  return (
    <LayoutMain>
      <PartsHeader
        onSetOpenPartModal={openPartModal => setOpenPartModal(openPartModal)}
        selectedParts={selectedParts}
        groupEditParts={groupEditParts}
        setOpenOrderModal={setOpenOrderModal}
      />
      <PartsTabs
        partsAmount={partsList.length}
        partsSoldAmount={partsSold.length}
        partsAvailableAmount={partsAvailable.length}
        partsReservedAmount={partsReserved.length}
        onChange={status => {
          setStatus(status);
          setGroupEditParts([]);
        }}
      />
      {isMobile && (
        <PartMobileList
          partsList={filteredPartList}
          isFetchingPartsList={isFetching}
          openPartModal={openPartModal}
          clickOpenPartModal={openPartModal => setOpenPartModal(openPartModal)}
        />
      )}
      {!isMobile && (
        <PartsList
          partsList={filteredPartList}
          isFetchingPartsList={isFetching}
          openPartModal={openPartModal}
          clickOpenPartModal={openPartModal => setOpenPartModal(openPartModal)}
          groupEditStatus={groupEditParts}
          setGroupEditStatus={selectedKeys => setGroupEditParts(selectedKeys)}
          selectedPartTab={status}
        />
      )}
      <DrawerAddEdit
        titleEdit='Edytuj dane zamówienia'
        titleAdd='Dodaj zamówienie'
        width={'80%'}
        openModal={openOrderModal}
        handleCloseModal={() => setOpenOrderModal(false)}
      >
        <OrderForm
          handleSubmit={handleSubmit}
          order={{
            productsInOrder: selectedParts.map(part => ({
              productId: part._id,
              price: part.price,
              notes: '',
              type: ProductType.PART,
              technicalReview: false,
              guarantee: false,
              udt: false,
              rectifier: false,
            })),
          }}
          handleCancelForm={() => setOpenOrderModal(false)}
        />
      </DrawerAddEdit>
      <FloatingActionButton onSearch={searchText => setSearchPart(searchText)} />
    </LayoutMain>
  );
};
