import { useQuery } from 'react-query';
import { apiClientPartCategories } from './index';

const queryKeys = {
  getPartCategories: 'part-categories',
};

export const useGetPartCategories = () => {
  return useQuery(queryKeys.getPartCategories, async () => {
    const response = await apiClientPartCategories.getPartsCategories();
    return response.data;
  });
};
