import { FormikConfig } from 'formik';
import * as yup from 'yup';

import { PartCategoryFormProps, PartCategoryFormValues } from '.';
import { PartCategoryModel } from '../../reducers/partsCategories/model';

export const initFormik = (
  props: PartCategoryFormProps,
  partCategory: PartCategoryModel | undefined,
): FormikConfig<PartCategoryFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: partCategory?.name ?? '',
  },
  validationSchema: () =>
    yup.object({
      name: yup.string().required('Nazwa jest wymagana'),
    }),
  onSubmit: values => {
    props.handleSubmit(values);
  },
});
