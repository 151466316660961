import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as CONST from './consts';
import { PartInternalId, PartModel } from './model';

export type PartsAction = ActionType<typeof actions>;
export type PartsState = {
  readonly isFetching: boolean;
  readonly isFetchingStockList: boolean;
  readonly partsList: PartModel[];
  readonly availablePartsList: PartModel[];
  readonly partInternalId: PartInternalId | null;
};

export const partsReducer = combineReducers<PartsState, PartsAction>({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_PARTS_REQUEST:
      case CONST.GET_AVAILABLE_PARTS_REQUEST:
      case CONST.ADD_PART_REQUEST:
      case CONST.UPDATE_PART_REQUEST:
      case CONST.DELETE_PART_REQUEST:
      case CONST.UPDATE_PARTS_STATUSES_REQUEST:
      case CONST.GET_PART_REQUEST:
      case CONST.GET_PART_INTERNAL_ID_REQUEST:
        return true;

      case CONST.GET_PARTS_SUCCESS:
      case CONST.GET_PARTS_ERROR:
      case CONST.GET_AVAILABLE_PARTS_SUCCESS:
      case CONST.GET_AVAILABLE_PARTS_ERROR:
      case CONST.ADD_PART_SUCCESS:
      case CONST.ADD_PART_ERROR:
      case CONST.UPDATE_PART_SUCCESS:
      case CONST.UPDATE_PART_ERROR:
      case CONST.DELETE_PART_SUCCESS:
      case CONST.DELETE_PART_ERROR:
      case CONST.UPDATE_PARTS_STATUSES_SUCCESS:
      case CONST.UPDATE_PARTS_STATUSES_ERROR:
      case CONST.GET_PART_SUCCESS:
      case CONST.GET_PART_ERROR:
      case CONST.GET_PART_INTERNAL_ID_SUCCESS:
      case CONST.GET_PART_INTERNAL_ID_ERROR:
        return false;

      default:
        return state;
    }
  },
  isFetchingStockList: (state = false, action) => {
    switch (action.type) {
      case CONST.GET_PARTS_STOCK_LIST_REQUEST:
        return true;
      case CONST.GET_PARTS_STOCK_LIST_SUCCESS:
      case CONST.GET_PARTS_STOCK_LIST_ERROR:
        return false;
      default:
        return state;
    }
  },
  partsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_PARTS_SUCCESS:
        return action.payload.parts;

      case CONST.ADD_PART_SUCCESS:
        return [action.payload.part, ...state];

      case CONST.GET_PART_SUCCESS:
      case CONST.UPDATE_PART_SUCCESS: {
        const partIdx = state.findIndex(item => item._id === action.payload.part._id);
        state.splice(partIdx, 1, action.payload.part);

        return [...state];
      }

      case CONST.UPDATE_PARTS_STATUSES_SUCCESS: {
        action.payload.parts.forEach(part => {
          const partIdx = state.findIndex(item => item._id === part._id);
          state.splice(partIdx, 1, part);
        });

        return [...state];
      }

      case CONST.DELETE_PART_SUCCESS:
        return state.filter(part => part._id !== action.payload.partId);

      default:
        return state;
    }
  },
  availablePartsList: (state = [], action) => {
    switch (action.type) {
      case CONST.GET_AVAILABLE_PARTS_SUCCESS:
        return action.payload.parts;

      default:
        return state;
    }
  },
  partInternalId: (state = null, action) => {
    switch (action.type) {
      case CONST.GET_PART_INTERNAL_ID_REQUEST:
        return null;

      case CONST.GET_PART_INTERNAL_ID_SUCCESS:
        return action.payload.partInternalId;

      default:
        return state;
    }
  },
});
