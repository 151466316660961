import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import { PartPreview } from '../PartPreview';
import { PartMobileItem } from './PartMobileItem';
import { PartModel } from '../../../reducers/parts/model';
import { PartForm, PartFormValues } from '../../../forms/part';
import { addPartRequest, deletePartRequest, getPartsRequest, updatePartRequest } from '../../../reducers/parts/actions';

import { ListSC } from './styles';

type PartMobileListProps = {
  partsList: PartModel[];
  isFetchingPartsList: boolean;
  openPartModal: boolean;
  clickOpenPartModal: (openPartModal: boolean) => void;
};

export const PartMobileList: FC<PartMobileListProps> = props => {
  const { partsList, openPartModal, clickOpenPartModal, isFetchingPartsList } = props;
  const [selectedPart, setSelectedPart] = useState<PartModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [openPartDeleteModal, setOpenPartDeleteModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartsRequest());
  }, [dispatch]);

  const handleClosePartModal = () => {
    setSelectedPart(undefined);
    clickOpenPartModal(false);
  };

  const handleConfirmPartModal = () => {
    if (selectedPart) {
      dispatch(
        deletePartRequest(selectedPart._id, () => {
          setOpenPartDeleteModal(false);
        }),
      );
    }
  };

  const handleSubmit = (values: PartFormValues) => {
    if (selectedPart) {
      dispatch(
        updatePartRequest(
          {
            ...selectedPart,
            ...values,
          },
          () => {
            setSelectedPart(undefined);
            clickOpenPartModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addPartRequest({
          ...values,
        }),
      );
    }
  };

  const handleCloseQuickViewModal = () => {
    setSelectedPart(undefined);
    setOpenQuickViewModal(false);
  };

  return (
    <>
      <ListSC
        itemLayout='vertical'
        size='small'
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        loading={isFetchingPartsList}
        dataSource={partsList}
        className='mobileProductList'
        renderItem={part => (
          <PartMobileItem
            part={part}
            clickOpenPartModal={clickOpenPartModal}
            setSelectedPart={setSelectedPart}
            setOpenQuickViewModal={setOpenQuickViewModal}
            setOpenPartDeleteModal={setOpenPartDeleteModal}
          />
        )}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane części'
        titleAdd='Dodaj część'
        openModal={openPartModal}
        handleCloseModal={handleClosePartModal}
        selectedItem={selectedPart}
        width='100%'
      >
        <PartForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleClosePartModal}
          partId={selectedPart?._id}
        />
      </DrawerAddEdit>
      {selectedPart && (
        <DrawerAddEdit
          titleEdit={`Podgląd części: ${selectedPart.internalId}`}
          titleAdd={`Podgląd części: ${selectedPart.internalId}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedPart}
          width='100%'
        >
          <PartPreview partId={selectedPart._id} />
        </DrawerAddEdit>
      )}
      <Modal
        title='Usuwanie części'
        open={openPartDeleteModal}
        cancelText='Anuluj'
        okText='Tak'
        onCancel={() => setOpenPartDeleteModal(false)}
        onOk={handleConfirmPartModal}
      >
        Czy na pewno chcesz usunąć część?
      </Modal>
    </>
  );
};
