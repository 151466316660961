import React, { FC, useState } from 'react';
import { EquipmentModel } from '../../../reducers/equipment/model';
import { Modal } from 'antd';
import { EquipmentMobileItem } from './EquipmentMobileItem';
import { deleteForkliftRequest } from '../../../reducers/forklift/actions';
import { useDispatch } from 'react-redux';
import { DrawerAddEdit } from '../../drawerAditEdit/DrawerAddEdit';
import { EquipmentPreview } from '../EquipmentPreview';
import { EquipmentForm, EquipmentFormValues } from '../../../forms/equipment';
import { addEquipmentRequest, updateEquipmentRequest } from '../../../reducers/equipment/actions';
import { ListSC } from './styles';

type EquipmentMobileListProps = {
  equipmentList: EquipmentModel[];
  isFetchingEquipmentList: boolean;
  openEquipmentModal: boolean;
  clickOpenEquipmentModal: (openEquipmentModal: boolean) => void;
};

export const EquipmentMobileList: FC<EquipmentMobileListProps> = props => {
  const { equipmentList, isFetchingEquipmentList, clickOpenEquipmentModal, openEquipmentModal } = props;
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentModel | undefined>(undefined);
  const [openQuickViewModal, setOpenQuickViewModal] = useState(false);
  const [openEquipmentDeleteModal, setOpenEquipmentDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const handleCloseEquipmentModal = () => {
    setSelectedEquipment(undefined);
    clickOpenEquipmentModal(false);
  };

  const handleCloseQuickViewModal = () => {
    setSelectedEquipment(undefined);
    setOpenQuickViewModal(false);
  };

  const handleConfirmEquipmentModal = () => {
    if (selectedEquipment) {
      dispatch(
        deleteForkliftRequest(selectedEquipment._id, () => {
          setOpenEquipmentDeleteModal(false);
        }),
      );
    }
  };

  const handleSubmit = (values: EquipmentFormValues) => {
    if (selectedEquipment) {
      dispatch(
        updateEquipmentRequest(
          {
            ...selectedEquipment,
            ...values,
          },
          () => {
            setSelectedEquipment(undefined);
            clickOpenEquipmentModal(false);
          },
        ),
      );
    } else {
      dispatch(
        addEquipmentRequest(
          {
            ...values,
          },
          () => {
            setSelectedEquipment(undefined);
            clickOpenEquipmentModal(false);
          },
        ),
      );
    }
  };

  return (
    <>
      <ListSC
        itemLayout='vertical'
        size='small'
        pagination={{ pageSize: 50, pageSizeOptions: [50] }}
        loading={isFetchingEquipmentList}
        dataSource={equipmentList}
        className='mobileProductList'
        renderItem={equipment => (
          <EquipmentMobileItem
            equipment={equipment}
            clickOpenEquipmentModal={clickOpenEquipmentModal}
            setSelectedEquipment={setSelectedEquipment}
            setOpenQuickViewModal={setOpenQuickViewModal}
            setOpenEquipmentDeleteModal={setOpenEquipmentDeleteModal}
          />
        )}
      />
      <DrawerAddEdit
        titleEdit='Edytuj dane osprzętu'
        titleAdd='Dodaj osprzęt'
        openModal={openEquipmentModal}
        handleCloseModal={handleCloseEquipmentModal}
        selectedItem={selectedEquipment}
        width='100%'
      >
        <EquipmentForm
          handleSubmit={handleSubmit}
          handleCancelForm={handleCloseEquipmentModal}
          equipmentId={selectedEquipment?._id}
        />
      </DrawerAddEdit>
      {selectedEquipment && (
        <DrawerAddEdit
          titleEdit={`Podgląd osprzętu: ${selectedEquipment.internalId}`}
          titleAdd={`Podgląd osprzętu: ${selectedEquipment.internalId}`}
          openModal={openQuickViewModal}
          handleCloseModal={handleCloseQuickViewModal}
          selectedItem={selectedEquipment}
          width='100%'
        >
          <EquipmentPreview equipmentId={selectedEquipment._id} />
        </DrawerAddEdit>
      )}
      <Modal
        title='Usuwanie osprzętu'
        open={openEquipmentDeleteModal}
        cancelText='Anuluj'
        okText='Tak'
        onCancel={() => setOpenEquipmentDeleteModal(false)}
        onOk={handleConfirmEquipmentModal}
      >
        Czy na pewno chcesz usunąć osprzęt?
      </Modal>
    </>
  );
};
